import { Icon } from '@limepayments/cosmic';

interface Props {
  onClickDelete: () => void;
  onClickEdit: () => void;
}

function TableActionsDropdowns({ onClickDelete, onClickEdit }: Props) {
  return (
    <div className="lp-flex lp-flex-column">
      <div className="t-action-dropdown-body" data-testid="settings-paybylink-actions-dropdown">
        <button className="lp-flex lp-align-center lp-w-full" onClick={onClickEdit}>
          <Icon name="Edit" className="mr-6" />
          Edit Field
        </button>
        <button className="lp-flex lp-align-center lp-w-full" onClick={onClickDelete}>
          <Icon name="Cross" className="mr-6" />
          Delete
        </button>
      </div>
    </div>
  );
}

export default TableActionsDropdowns;
