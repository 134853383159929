import { Address } from 'api/fragments';

type MerchantBusinessProfile = {
  timeZone: string;
  businessName: string;
  businessType: 'company' | 'individual' | 'soletrader' | 'partnership' | 'trust';
  companyType: 'Private' | 'Public';
  website: string;
  supportSite: string;
  industry: string;
  taxCountry: string;
  taxId: string;
  contactPerson: {
    title: string;
    givenName: string;
    familyName: string;
  };
  contactEmailAddress: string;
  supportEmailAddress: string;
  phoneNumber: string;
  address: Address;
  brandName: string;
  cardStatementName: string;
  smsSenderName: string;
};

type MerchantBranding = {
  iconUri?: string;
  logoUri?: string;
  logoAltText?: string;
  primaryColor?: string;
};

export type CustomField = {
  key: string;
  label: string;
  required: boolean;
  visibleForCustomer: boolean;
};

export type BillingDetails = {
  billingNotificationEmails?: Array<string>;
  billingNotificationsEnabled: boolean;
  billingAddress?: Address;
  billingPaymentSourceId?: string;
};

export type BaseMerchant = {
  merchantId: string;
  billingDetails: BillingDetails;
  businessProfile: MerchantBusinessProfile;
  status: 'Pending' | 'Active' | 'Suspended';
};

export type StandardMerchant = BaseMerchant & {
  type: 'StandardMerchant';
  branding: MerchantBranding;
  orderCustomFields?: CustomField[];
};

export type StandardMerchantResponse = {
  StandardMerchant: BaseMerchant & {
    branding: MerchantBranding;
    orderCustomFields?: CustomField[];
  };
};

export type ServiceProviderMerchant = BaseMerchant & {
  type: 'ServiceProviderMerchant';
};

export type ServiceProviderMerchantResponse = {
  ServiceProviderMerchant: BaseMerchant;
};

export type MerchantResponse = StandardMerchantResponse | ServiceProviderMerchantResponse;

export type AnyMerchant = StandardMerchant | ServiceProviderMerchant;

// type handleAnyMerchantType

export const handleAnyMerchant = (merchantResponse: MerchantResponse): AnyMerchant =>
  'StandardMerchant' in merchantResponse
    ? { ...merchantResponse.StandardMerchant, type: 'StandardMerchant' }
    : { ...merchantResponse.ServiceProviderMerchant, type: 'ServiceProviderMerchant' };

export type CustomerType = 'consumer' | 'organisation';
export type SettlementType = 'Net' | 'Gross';

export type MerchantGeneralSettingsResponse = {
  settlementType: SettlementType;
  supportedCustomerTypes: Array<CustomerType>;
  createdAt: string;
  updatedAt: string;
};

export type UpdateMerchantRequest =
  | UpdateMerchantBusinessProfileRequest
  | UpdateMerchantBrandingRequest
  | UpdateOrderCustomFieldsRequest
  | UpdateMerchantBillingSettingsRequest
  | SetMerchantBillingPaymentSource;

export type UpdateMerchantBusinessProfileRequest = {
  UpdateMerchantBusinessProfile: {
    timeZone: string;
    businessName: string;
    businessType: string;
    companyType?: string;
    website: string;
    supportSite: string;
    industry: string;
    tradingCountry?: string;
    taxId: string;
    contactPerson: {
      title: string;
      givenName: string;
      familyName: string;
    };
    contactEmailAddress: string;
    supportEmailAddress: string;
    phoneNumber: string;
    address: Address;
    brandName: string;
    cardStatementName: string;
    smsSenderName: string;
  };
};

export type UpdateMerchantBrandingRequest = {
  UpdateMerchantBranding: {
    branding: {
      iconUri?: string;
      logoUri?: string;
      logoAltText?: string;
      primaryColor?: string;
    };
  };
};

export type UpdateMerchantBillingSettingsRequest = {
  UpdateMerchantBillingSettings: {
    billingNotificationEmails?: Array<string>;
    billingNotificationsEnabled?: boolean;
    billingAddress: Address;
  };
};

export type SetMerchantBillingPaymentSource = {
  SetMerchantBillingPaymentSource: {
    paymentSourceId: string;
  };
};

export type UpdateOrderCustomFieldsRequest = {
  UpdateOrderCustomFields: {
    fields: CustomField[];
  };
};
