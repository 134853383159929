import { Fragment } from 'react';

import { Button, Heading, Icon, Illustration, Text } from '@limepayments/cosmic';

import { useCreateOrderContext } from './context';

function OrderCreationSuccess() {
  const { modalToggler, paymentRequestedEmail } = useCreateOrderContext();

  return (
    <div className="modal-main p-24">
      <div className="modal-header">
        <div className="content-main bb-1">
          <Heading alignment="center" tagName="h2" variant="xs" className="mt-12 mb-24 lp-justify-center lp-flex">
            Payment request has been created
          </Heading>
        </div>
      </div>
      <div className="modal-body h90vh p-28 sm-p-0 sm-mt-24">
        <Fragment>
          <div className="mx-auto illustration-115 mt-0 mb-24">
            <Illustration name="Email" size="medium" />
          </div>
          <Text alignment="center" tagName="p" variant="body-2" className="lp-neutral-600">
            Request for payment has been sent to
            <br /> <strong data-testid="create-neworder-modal-paymentrequestedemail">{paymentRequestedEmail}.</strong>
          </Text>
        </Fragment>
      </div>
      <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
        <Fragment>
          <Button
            onClick={() => modalToggler(false)}
            size="medium"
            variant="primary"
            className="no-min-width"
            testId="create-neworder-modal-close-button"
          >
            <Icon name="Cross" className="ml-0 mr-12" />
            Close
          </Button>
        </Fragment>
      </div>
    </div>
  );
}

export default OrderCreationSuccess;
