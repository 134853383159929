import { addressToString } from 'api/fragments';
import { AnyMerchant } from 'api/merchant/dashboard/merchants.types';
import { AccountInterface } from 'api/merchant/dashboard/settings/payouts.types';
import { GetMerchantBalancesResponse } from 'api/merchant/dashboard/settlement.types';
import { PlatformMerchantObj } from 'api/platform/merchant.types';
import * as s from 'assets/styles/Platforms.styles';
import { DateTime } from 'luxon';
import ErrorComponent from 'pages/common/error/ErrorComponent';
import { Fragment, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { toCurrency } from 'utils/currency';
import { capitalizeFirstLowercaseRest } from 'utils/stringUtils';

import { Button, Heading, Icon, Spinner, Tag, Text } from '@limepayments/cosmic';

import { MerchantStatusObj } from '../constants';

export interface Props {
  onBackButtonClick: () => void;
  merchantDetailErrorMsg: string;
  showMerchantDetailLoader: boolean;
  merchantDetailObj: PlatformMerchantObj | null; // TODO: This property (fetched from Limepay API) can be removed when createdAt, stateUpdateAt and payoutSchedule data are fetched from April API
  merchantDetailsObj: AnyMerchant | null;
  merchantBalancesObj: GetMerchantBalancesResponse | null;
  payoutBankAccounts: AccountInterface;
}

function MerchantDetails({
  onBackButtonClick,
  merchantDetailErrorMsg,
  showMerchantDetailLoader,
  merchantDetailObj,
  merchantDetailsObj,
  merchantBalancesObj,
  payoutBankAccounts,
}: Props) {
  const backDivRef = useRef<HTMLDivElement>(null);

  const anyMerchantObj = merchantDetailsObj;

  useEffect(() => {
    if (!showMerchantDetailLoader && merchantDetailsObj && backDivRef.current) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [showMerchantDetailLoader, merchantDetailsObj]);

  return (
    <div className="content-wrap-inner lp-full">
      <Helmet>
        <title>Merchant Detail</title>
      </Helmet>
      <div className="header-back mb-28" ref={backDivRef}>
        <Button onClick={onBackButtonClick} variant="ghost" size={'small'} testId="merchant-detail-back-button">
          <Icon name="ArrowLeft" className="mr-8" />
          Back
        </Button>
      </div>
      {!showMerchantDetailLoader && merchantDetailErrorMsg.length ? (
        <ErrorComponent bodyText={merchantDetailErrorMsg} />
      ) : null}

      {showMerchantDetailLoader && (
        <div className="spinner-wrapper">
          <Spinner variant="simple" isVisible label="Loading..." />
        </div>
      )}

      {!showMerchantDetailLoader &&
        !merchantDetailErrorMsg.length &&
        merchantDetailObj &&
        merchantDetailsObj &&
        anyMerchantObj && (
          <Fragment>
            <div className="header-details">
              <div className="header-details-inner">
                <Heading
                  alignment="left"
                  tagName="h2"
                  variant="sm"
                  className="mb-0 mr-16"
                  testId="merchant-detail-brand-name"
                >
                  {anyMerchantObj.businessProfile.brandName
                    ? anyMerchantObj.businessProfile.brandName
                    : anyMerchantObj.businessProfile.businessName}
                </Heading>
                <Tag
                  label={anyMerchantObj.status || ''}
                  variant={`${
                    anyMerchantObj.status === `Active`
                      ? 'success'
                      : anyMerchantObj.status === `Pending`
                      ? `positive`
                      : `negative`
                  }`}
                  size="small"
                />
              </div>
              <div className="lp-flex sm-flex-col sm-w-full">
                {/* TODO: do it later */}
                {/* <div className="stacked-data">
                <Text tagName="p" variant="caption" className="text-dark-50 m-0">
                  Volume - Last 7 days
                </Text>
                <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0" isEmphasised>
                  $43,500.00
                </Text>
              </div> */}
                <div className="stacked-data" data-testid="merchant-detail-heading-date-div">
                  <Text
                    tagName="p"
                    variant="caption"
                    className="text-dark-50 m-0"
                    testId="merchant-detail-heading-date-heading"
                  >
                    {anyMerchantObj.status === MerchantStatusObj.Pending
                      ? 'Created'
                      : 'Date '.concat(anyMerchantObj.status ? anyMerchantObj.status.toLowerCase() : '')}
                  </Text>
                  <Text
                    tagName="p"
                    variant="body-3"
                    className="text-dark m-0 mt-0"
                    testId="merchant-detail-heading-date-value"
                    isEmphasised
                  >
                    {DateTime.fromISO(
                      anyMerchantObj.status === MerchantStatusObj.Pending
                        ? merchantDetailObj.createdAt
                        : merchantDetailObj.stateUpdatedAt,
                    ).toFormat('dd LLL yyyy')}
                  </Text>
                </div>
              </div>
            </div>

            <s.MerchantsData>
              <Heading alignment="left" tagName="h2" variant="xs" className="mb-32">
                Identity
              </Heading>
              <Heading alignment="left" tagName="h2" variant="xxs" className="mb-16">
                Account details
              </Heading>
              <div className="lp-half lp-flex two-column-block">
                <ul className="two-column">
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Merchant trading name
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {anyMerchantObj.businessProfile.brandName
                        ? anyMerchantObj.businessProfile.brandName
                        : anyMerchantObj.businessProfile.businessName}
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Merchant ID
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {anyMerchantObj.merchantId}
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Email
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {anyMerchantObj.businessProfile.contactEmailAddress}
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Payout schedule
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {capitalizeFirstLowercaseRest(merchantDetailObj.payoutSchedule)}
                    </Text>
                  </li>
                </ul>
              </div>
            </s.MerchantsData>

            <s.MerchantsData>
              <Heading alignment="left" tagName="h2" variant="xxs" className="mb-16">
                Business details
              </Heading>
              <div className="lp-half lp-flex two-column-block">
                <ul className="two-column">
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Legal entity name
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {anyMerchantObj.businessProfile.businessName}
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Business registration number
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {anyMerchantObj.businessProfile.taxId}
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Tax country
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {anyMerchantObj.businessProfile.taxCountry === `NZ` ? `New Zealand` : `Australia`}
                    </Text>
                  </li>
                  <li className="" data-testid="merchant-detail-business-type-listing">
                    <Text
                      tagName="p"
                      variant="body-3"
                      className="text-dark-50"
                      testId="merchant-detail-business-type-heading"
                    >
                      Business type
                    </Text>
                    <Text
                      tagName="p"
                      variant="body-3"
                      className="text-dark m-0 mt-0"
                      testId="merchant-detail-business-type-value"
                    >
                      {capitalizeFirstLowercaseRest(anyMerchantObj.businessProfile.businessType || '')}
                    </Text>
                  </li>
                </ul>

                <ul className="two-column">
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Industry
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {anyMerchantObj.businessProfile.industry}
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Card statement name
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {anyMerchantObj.businessProfile.cardStatementName}
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      SMS sender name
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {anyMerchantObj.businessProfile.smsSenderName}
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Business website
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {anyMerchantObj.businessProfile.website ? anyMerchantObj.businessProfile.website : 'None'}
                    </Text>
                  </li>
                </ul>
              </div>
            </s.MerchantsData>

            <s.MerchantsData>
              <Heading alignment="left" tagName="h2" variant="xxs" className="mb-16">
                Contact details
              </Heading>
              <div className="lp-half lp-flex two-column-block">
                <ul className="two-column">
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Name
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {anyMerchantObj.businessProfile.contactPerson.givenName}{' '}
                      {anyMerchantObj.businessProfile.contactPerson.familyName}
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Job title
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {anyMerchantObj.businessProfile.contactPerson.title}
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Contact email address
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {anyMerchantObj.businessProfile.contactEmailAddress}
                    </Text>
                  </li>
                </ul>

                <ul className="two-column">
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Contact phone number
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {anyMerchantObj.businessProfile.phoneNumber}
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Business address
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {addressToString(anyMerchantObj.businessProfile.address)}
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Country
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {anyMerchantObj.businessProfile.address.country === 'NZ' ? `New Zealand` : `Australia`}
                    </Text>
                  </li>
                </ul>
              </div>
            </s.MerchantsData>

            <s.MerchantsData>
              <Heading alignment="left" tagName="h2" variant="xs" className="mb-32">
                Balances
              </Heading>
              <div className="lp-half lp-flex two-column-block">
                <ul className="two-column">
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Available to pay out
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {!merchantBalancesObj || merchantBalancesObj.available === 0
                        ? '-'
                        : toCurrency(merchantBalancesObj.available, merchantBalancesObj.currency)}
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Pending amount
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {!merchantBalancesObj || merchantBalancesObj.pending === 0
                        ? '-'
                        : toCurrency(merchantBalancesObj.pending, merchantBalancesObj.currency)}
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      In transit amount
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {!merchantBalancesObj || merchantBalancesObj.inTransit === 0
                        ? '-'
                        : toCurrency(merchantBalancesObj.inTransit, merchantBalancesObj.currency)}
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Total
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {merchantBalancesObj &&
                      (merchantBalancesObj.available !== 0 ||
                        merchantBalancesObj.pending !== 0 ||
                        merchantBalancesObj.inTransit !== 0)
                        ? toCurrency(
                            merchantBalancesObj.available + merchantBalancesObj.pending + merchantBalancesObj.inTransit,
                            merchantBalancesObj.currency,
                          )
                        : '-'}
                    </Text>
                  </li>
                </ul>
                <ul className="two-column">
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Bank account
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {`*******${payoutBankAccounts.accountNumberLast4}`}
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Bank name
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {payoutBankAccounts.bankName}
                    </Text>
                  </li>
                </ul>
              </div>
            </s.MerchantsData>
          </Fragment>
        )}
    </div>
  );
}

export default MerchantDetails;
