import { AmountFilter, DateFilter, FilterDropdown, PayoutStatusFilter } from 'pages/common/filters';
import { PayoutFilter } from 'pages/common/filters/PayoutFilter';
import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/merchant/hooks';
import { setPayoutFilterObj } from 'redux/merchant/slice/payoutSlice';

import { Button, Checkbox, CheckboxGroup, Heading, Icon, Text } from '@limepayments/cosmic';

import { getInitialErrorState, getInitialFilterState } from '../initialStates';
import { IPayoutFilterObj, IPayoutFilterObjError, hasFilterError } from '../types';

interface Props {
  modalToggler(): void;
  handlePageChange(page: number): void;
}

function FilterComponent({ modalToggler, handlePageChange }: Props) {
  const { config, payoutFilterObj } = useAppSelector((state) => ({
    config: state.config,
    payoutFilterObj: state.payout.payoutFilterObj,
  }));

  const [filterObj, setFilterObj] = useState<IPayoutFilterObj>(payoutFilterObj);
  const [filterObjError, setFilterObjError] = useState<IPayoutFilterObjError>(getInitialErrorState());

  const dispatch = useAppDispatch();

  const filterChangeHandler = useCallback(
    (filterObj: Partial<IPayoutFilterObj>) =>
      setFilterObj((prevState) => ({
        ...prevState,
        ...filterObj,
      })),
    [],
  );

  const validate = useCallback(() => {
    const filterObjError = getInitialErrorState();
    const isRequiredError = 'This field is required';

    if (filterObj.amountChecked && !filterObj.amount) {
      filterObjError.amountError = isRequiredError;
    }
    if (filterObj.payoutIdChecked && !filterObj.payoutId) {
      filterObjError.payoutIdError = isRequiredError;
    }
    if (filterObj.createdOnChecked && !filterObj.createdOn) {
      filterObjError.createdOnError = isRequiredError;
    }
    if (filterObj.availableOnChecked && !filterObj.availableOn) {
      filterObjError.availableOnError = isRequiredError;
    }
    if (filterObj.statusChecked && !filterObj.status) {
      filterObjError.statusError = isRequiredError;
    }

    setFilterObjError(filterObjError);
    return filterObjError;
  }, [filterObj]);

  const filterSaveHandler = useCallback(async () => {
    if (hasFilterError(validate())) return;

    dispatch(setPayoutFilterObj({ ...filterObj }));
    handlePageChange(1);
    modalToggler();
  }, [dispatch, filterObj, validate, modalToggler, handlePageChange]);

  const clearFunction = useCallback(() => {
    setFilterObj(getInitialFilterState());
  }, []);

  return (
    <FilterDropdown className="w-328">
      <div className="lp-flex lp-flex-column">
        <div className="filter-header">
          <Heading alignment="left" tagName="h2" variant="xs" className="mb-0">
            Filters
          </Heading>
          <Button
            type="button"
            onClick={() => modalToggler()}
            size="small"
            variant="ghost"
            className="button-close m-0 p-0"
          >
            <Icon name="Cross" className="m-0 p-0" />
          </Button>
        </div>
        <div className="filter-body">
          <CheckboxGroup>
            <div className="checkbox-items" data-testid="payout-filter-amount">
              <Checkbox
                id="amount"
                label="Amount"
                variant="body-2"
                checked={filterObj.amountChecked}
                testId="payout-filter-amount-check"
                onChange={() =>
                  filterChangeHandler({
                    amountChecked: !filterObj.amountChecked,
                    amount: null,
                  })
                }
              />
              {filterObj.amountChecked && (
                <div className="checkbox-items-details">
                  <AmountFilter
                    value={filterObj.amount}
                    onChange={(amount) => filterChangeHandler({ amount: amount })}
                  />
                  {filterObjError.amountError && (
                    <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                      {filterObjError.amountError}
                    </Text>
                  )}
                </div>
              )}
            </div>
            <div className="checkbox-items" data-testid="payout-filter-payout-id">
              <Checkbox
                id="payoutid"
                label="Payout ID"
                variant="body-2"
                checked={filterObj.payoutIdChecked}
                testId="payout-filter-payout-id-check"
                onChange={() =>
                  filterChangeHandler({
                    payoutIdChecked: !filterObj.payoutIdChecked,
                    payoutId: null,
                  })
                }
              />
              {filterObj.payoutIdChecked && (
                <div className="checkbox-items-details">
                  <PayoutFilter
                    merchantIds={config.merchantId}
                    value={filterObj.payoutId}
                    onChange={(payoutId) => filterChangeHandler({ payoutId })}
                  />
                  {filterObjError.payoutIdError && (
                    <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                      {filterObjError.payoutIdError}
                    </Text>
                  )}
                </div>
              )}
            </div>
            <div className="checkbox-items" data-testid="payout-filter-created-on">
              <Checkbox
                id="createdon"
                label="Created on"
                variant="body-2"
                checked={filterObj.createdOnChecked}
                testId="payout-filter-created-on-check"
                onChange={() =>
                  filterChangeHandler({
                    createdOnChecked: !filterObj.createdOnChecked,
                    createdOn: null,
                  })
                }
              />
              {filterObj.createdOnChecked && (
                <div className="checkbox-items-details">
                  <DateFilter
                    value={filterObj.createdOn}
                    onChange={(createdOn) => filterChangeHandler({ createdOn })}
                  />
                  {filterObjError.createdOnError && (
                    <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                      {filterObjError.createdOnError}
                    </Text>
                  )}
                </div>
              )}
            </div>
            <div className="checkbox-items" data-testid="payout-filter-available-on">
              <Checkbox
                id="availableon"
                label="Available on"
                variant="body-2"
                checked={filterObj.availableOnChecked}
                testId="payout-filter-available-on-check"
                onChange={() =>
                  filterChangeHandler({
                    availableOnChecked: !filterObj.availableOnChecked,
                    availableOn: null,
                  })
                }
              />
              {filterObj.availableOnChecked && (
                <div className="checkbox-items-details">
                  <DateFilter
                    value={filterObj.availableOn}
                    onChange={(availableOn) => filterChangeHandler({ availableOn })}
                  />
                  {filterObjError.availableOnError && (
                    <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                      {filterObjError.availableOnError}
                    </Text>
                  )}
                </div>
              )}
            </div>
            <div className="checkbox-items" data-testid="payout-filter-status">
              <Checkbox
                id="status"
                label="Status"
                variant="body-2"
                checked={filterObj.statusChecked}
                testId="payout-filter-status-check"
                onChange={() =>
                  filterChangeHandler({
                    statusChecked: !filterObj.statusChecked,
                    status: null,
                  })
                }
              />
              {filterObj.statusChecked && (
                <div className="checkbox-items-details">
                  <PayoutStatusFilter
                    value={filterObj.status ?? []}
                    onChange={(value) => filterChangeHandler({ status: value.length ? value : null })}
                  />
                  {filterObjError.statusError && (
                    <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                      {filterObjError.statusError}
                    </Text>
                  )}
                </div>
              )}
            </div>
          </CheckboxGroup>
        </div>

        <div className="filter-footer">
          <Button
            className="lp-w-full"
            onClick={() => clearFunction()}
            size="medium"
            variant="reversed"
            testId="payout-filter-clear-button"
          >
            Clear
          </Button>
          <Button
            className="lp-w-full"
            onClick={() => filterSaveHandler()}
            size="medium"
            variant="primary"
            testId="payout-filter-save-button"
          >
            Save
          </Button>
        </div>
      </div>
    </FilterDropdown>
  );
}

export default FilterComponent;
