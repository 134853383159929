import { Button, Heading, Icon, Text } from '@limepayments/cosmic';

interface Props {
  setCreateNewOrderModalOpen: (isOpen: boolean) => void;
}

function UnpaidRequestComponent({ setCreateNewOrderModalOpen }: Props) {
  return (
    <div className="transaction-empty">
      <div className="transaction-empty-inner">
        <Icon name="Wallet" />
        <Heading
          alignment="center"
          tagName="h2"
          variant="xs"
          className="mt-12 mb-8"
          testId="virtual-terminal-filter-no-data-title"
        >
          Unpaid payment requests
        </Heading>
        <Text
          alignment="center"
          tagName="p"
          variant="body-2"
          className="text-dark-50 mt-0 mb-16"
          testId="virtual-terminal-filter-no-data-sub-title"
        >
          There are currently no outstanding payment requests.
        </Text>
        <Button type="button" size="medium" variant="primary" onClick={() => setCreateNewOrderModalOpen(true)}>
          Create new order
          <Icon name="Plus" className="ml-8" />
        </Button>
      </div>
    </div>
  );
}

export default UnpaidRequestComponent;
