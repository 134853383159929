import { createPaymentSourceVerification, modifyPaymentSourceVerification } from 'api/merchant/dashboard/payments';
import {
  PaymentSourceVerificationAPIResponseType,
  ResetRequestType,
  UpdateOutcomeRequestType,
} from 'api/merchant/dashboard/payments.types';
import * as s from 'assets/styles/Platforms.styles';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/merchant/hooks';
import { toCurrency } from 'utils/currency';
import getErrorMessage from 'utils/getErrorMessage';

import { Button, Heading, Illustration, Modal, Spinner, Stack, Text } from '@limepayments/cosmic';

interface Props {
  transactionId: string | null;
  isOpen: boolean;
  modalToggler: (isOpen: boolean) => void;
  paymentSourceVerificationObj: PaymentSourceVerificationAPIResponseType | null;
  setPaymentSourceVerificationObj: (
    paymentSourceVerificationObj: PaymentSourceVerificationAPIResponseType | null,
  ) => void;
  customerName?: string;
  customerEmailAddress?: string;
  customerPhoneNumber?: string;
}

function ManualVerificationModal({
  transactionId,
  isOpen,
  modalToggler,
  paymentSourceVerificationObj,
  setPaymentSourceVerificationObj,
  customerName,
  customerEmailAddress,
  customerPhoneNumber,
}: Props) {
  const { apiBaseUri } = useAppSelector((state) => ({
    apiBaseUri: state.config.apiBaseUri,
  }));

  const [activeSection, setActiveSection] = useState<number>(0);
  const [modalUpdating, setModalUpdating] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const newPaymentSourceVerification = useCallback(async () => {
    if (transactionId) {
      setModalUpdating(true);
      const obj = await createPaymentSourceVerification(apiBaseUri, {
        ForTransaction: {
          transactionId,
        },
      }).catch((error) => {
        setErrorMessage(getErrorMessage(error));
        setActiveSection(2);
        return paymentSourceVerificationObj;
      });
      setPaymentSourceVerificationObj(obj);
      setModalUpdating(false);
    }
  }, [apiBaseUri, paymentSourceVerificationObj, setPaymentSourceVerificationObj, transactionId]);

  const updatePaymentSourceVerification = useCallback(
    async (sourceVerificationId: string, updateData: ResetRequestType | UpdateOutcomeRequestType) => {
      try {
        setModalUpdating(true);
        const paymentSourceVerificationObj = await modifyPaymentSourceVerification(
          apiBaseUri,
          sourceVerificationId,
          updateData,
        );
        setPaymentSourceVerificationObj(paymentSourceVerificationObj);
        modalToggler(false);
      } catch (e) {}
      setModalUpdating(false);
    },
    [apiBaseUri, modalToggler, setPaymentSourceVerificationObj],
  );

  const resetPaymentSourceVerification = useCallback(() => {
    if (paymentSourceVerificationObj) {
      updatePaymentSourceVerification(paymentSourceVerificationObj.sourceVerificationId, { Reset: {} });
    }
  }, [paymentSourceVerificationObj, updatePaymentSourceVerification]);

  const failedPaymentSourceVerification = useCallback(() => {
    if (paymentSourceVerificationObj) {
      updatePaymentSourceVerification(paymentSourceVerificationObj.sourceVerificationId, {
        UpdateOutcome: {
          outcome: 'failed',
        },
      });
    }
  }, [paymentSourceVerificationObj, updatePaymentSourceVerification]);

  const successfulPaymentSourceVerification = useCallback(() => {
    if (paymentSourceVerificationObj) {
      updatePaymentSourceVerification(paymentSourceVerificationObj.sourceVerificationId, {
        UpdateOutcome: {
          outcome: 'passed',
        },
      });
    }
  }, [paymentSourceVerificationObj, updatePaymentSourceVerification]);

  useEffect(() => {
    if (isOpen) {
      if (!paymentSourceVerificationObj) {
        newPaymentSourceVerification();
      }
    } else {
      if (!paymentSourceVerificationObj || paymentSourceVerificationObj.state === 'unverified') {
        setActiveSection(0);
      } else {
        setActiveSection(1);
      }
    }
  }, [isOpen, newPaymentSourceVerification, paymentSourceVerificationObj, paymentSourceVerificationObj?.state]);

  return (
    <Modal isOpen={isOpen} className="modal-medium update-pass-modal">
      <s.Wrapper className="">
        <div className="modal-main p-24">
          <div className="modal-header">
            <div className="content-main bb-1">
              {activeSection === 0 && (
                <Heading
                  alignment="center"
                  tagName="h2"
                  variant="xxs"
                  className="mt-12 mb-24 lp-justify-center lp-flex"
                >
                  Manual Verification of Card User
                </Heading>
              )}

              {activeSection === 1 && (
                <Heading
                  alignment="center"
                  tagName="h2"
                  variant="xxs"
                  className="mt-12 mb-24 lp-justify-center lp-flex"
                >
                  Reset status to “unverified”
                </Heading>
              )}

              {activeSection === 2 && (
                <Heading
                  alignment="center"
                  tagName="h2"
                  variant="xxs"
                  className="mt-12 mb-24 lp-justify-center lp-flex"
                >
                  Unable to create a pre-authorised charge
                </Heading>
              )}
            </div>
          </div>

          {modalUpdating ? (
            <div className="modal-body-loader">
              <Stack spacing="md" className="mb-16">
                <Spinner isVisible variant="simple" />
              </Stack>
              <Text alignment="center" tagName="p" variant="body-3" className="text-dark-50 mb-0">
                Loading...
              </Text>
            </div>
          ) : (
            <>
              {/* Begin Step 01 Content */}
              {activeSection === 0 && (
                <Fragment>
                  <div className="modal-content sm-p-0 sm-pt-24">
                    <div className="lp-w-full mb-16">
                      <Text tagName="p" variant="body-3" className="text-dark-50 m-0 mt-0">
                        Verify amount
                      </Text>
                      <Heading alignment="left" tagName="h1" variant="lg">
                        {!paymentSourceVerificationObj
                          ? '-'
                          : toCurrency(
                              paymentSourceVerificationObj.chargedAmount.amount,
                              paymentSourceVerificationObj.chargedAmount.currency,
                            )}
                      </Heading>
                    </div>
                    <div className="lp-w-full mb-16">
                      <Text alignment="left" tagName="p" variant="body-3" className="text-dark-50 mb-0">
                        <strong>Do not disclose this number to your customer.</strong> Your customer should be able to
                        verify to you the exact amount displayed above as it is displayed on their payment source’s
                        statement.
                      </Text>
                    </div>
                    {(customerName || customerEmailAddress || customerPhoneNumber) && (
                      <div className="lp-w-full mb-16">
                        <Text tagName="p" variant="caption" className="text-dark-50 m-0 mt-0">
                          Customer details
                        </Text>
                        {customerName && (
                          <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                            {customerName}
                          </Text>
                        )}
                        {customerEmailAddress && (
                          <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                            {customerEmailAddress}
                          </Text>
                        )}
                        {customerPhoneNumber && (
                          <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                            {customerPhoneNumber}
                          </Text>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
                    <div className="lp-flex sm:flex-col sm:justify-end sm:align-end sm:lp-align-end">
                      <div className="lp-flex lp-align-center sm:mb-12">
                        <Button
                          onClick={() => modalToggler(false)}
                          disabled={modalUpdating}
                          type="button"
                          size="medium"
                          variant="ghost"
                          className="no-min-width"
                        >
                          Close
                        </Button>
                        <Button
                          disabled={modalUpdating}
                          onClick={() => failedPaymentSourceVerification()}
                          type="button"
                          size="medium"
                          variant="destructive"
                          className="no-min-width"
                          testId="manual-verification-modal-cannot-verify-btn"
                        >
                          Cannot verify
                        </Button>
                      </div>
                      <Button
                        type="button"
                        size="medium"
                        variant="primary"
                        className="no-min-width"
                        testId="manual-verification-modal-verify-btn"
                        onClick={() => successfulPaymentSourceVerification()}
                        disabled={modalUpdating}
                      >
                        Verify card user
                      </Button>
                    </div>
                  </div>
                </Fragment>
              )}
              {/* //Ends Step 01 Content */}

              {activeSection === 1 && (
                <form autoComplete="off">
                  <div className="modal-content sm-p-0 sm-mt-24">
                    <div className="">
                      <div className="lp-w-full lp-flex mb-24">
                        <div className="lp-w-full lp-flex lp-flex-column lp-align-center lp-justify-center">
                          <div className="illustration-90">
                            <Illustration name="Warning" size="medium" />
                          </div>
                          <div className="w-75">
                            {/* <Text alignment="center" tagName="p" variant="body-3" className="text-dark-50 mb-0">
                        We were unable to create a pre-authorised charge on this card user’s payment source due to [
                        <strong>insufficient funds</strong>]. Please contact the customer to resolve this before trying
                        to verify this card user again.
                      </Text> */}
                            <Text alignment="center" tagName="p" variant="body-3" className="text-dark-50 mb-0">
                              Are you sure you wish to reset the verification status of this card user to "
                              <strong>unverified</strong>"?
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
                    <Button
                      onClick={() => modalToggler(false)}
                      disabled={modalUpdating}
                      type="button"
                      size="medium"
                      variant="ghost"
                      className="no-min-width"
                    >
                      Cancel
                    </Button>

                    <Button
                      type="button"
                      size="medium"
                      variant="destructive"
                      className="no-min-width"
                      testId="manual-verification-modal-reset-btn"
                      onClick={() => resetPaymentSourceVerification()}
                      disabled={modalUpdating}
                    >
                      Yes, reset status
                    </Button>
                  </div>
                </form>
              )}

              {activeSection === 2 && (
                <form autoComplete="off">
                  <div className="modal-content sm-p-0 sm-mt-24">
                    <div className="">
                      <div className="lp-w-full lp-flex mb-24">
                        <div className="lp-w-full lp-flex lp-flex-column lp-align-center lp-justify-center">
                          <div className="illustration-90">
                            <Illustration name="Warning" size="medium" />
                          </div>
                          <div className="w-75">
                            <Text alignment="center" tagName="p" variant="body-3" className="text-dark-50 mb-0">
                              We were unable to create a pre-authorised charge on this card user’s payment source due to
                              [<strong>{errorMessage}</strong>]. Please contact the customer to resolve this before
                              trying to verify this card user again.
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
                    <Button
                      type="button"
                      size="medium"
                      variant="primary"
                      className="no-min-width"
                      onClick={() => modalToggler(false)}
                      disabled={modalUpdating}
                    >
                      Ok
                    </Button>
                  </div>
                </form>
              )}
            </>
          )}
        </div>
      </s.Wrapper>
    </Modal>
  );
}

export default ManualVerificationModal;
