import { Formik } from 'formik';
import { useCallback } from 'react';

import { Button, Text, TextField } from '@limepayments/cosmic';

import { useCreateOrderContext } from './context';
import { ItemForm } from './types';
import { itemFormValidationSchema as validationSchema } from './validation.schema';

interface Props {
  toggle: (isNewItemOpen: boolean) => void;
}

function AddNewItem({ toggle }: Props) {
  const { addItem, tradingCurrency } = useCreateOrderContext();

  const handleCancel = useCallback(() => {
    toggle(false);
  }, [toggle]);

  const onSubmitClick = useCallback(
    (values: ItemForm) => {
      addItem({ name: values.name, amount: Number(values.amount), quantity: Number(values.quantity) });
      toggle(false);
    },
    [addItem, toggle],
  );

  return (
    <div className="add-new-item">
      <Formik<ItemForm>
        enableReinitialize={true}
        initialValues={{ name: '', amount: 0, quantity: 0 }}
        onSubmit={(values, formObj: { resetForm: () => void }) => {
          onSubmitClick(values);
        }}
        validationSchema={validationSchema}
      >
        {({ values, handleSubmit, errors, touched, handleChange, handleBlur }) => (
          <form autoComplete="off" onSubmit={handleSubmit} className="content-wrap-form">
            <Text tagName="p" variant="body-2" className="lp-neutral-600 mt-0 mb-16" isEmphasised>
              New item
            </Text>
            <div className="two-col mb-16">
              <div className="lp-w-half white-bg">
                <TextField
                  id="name"
                  name="name"
                  fullWidth
                  containerId="standard"
                  label="Item name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.name && touched.name ? errors.name : ''}
                  testId="create-neworder-modal-newitemname-input"
                />
              </div>
              <div className="lp-w-half white-bg">
                <TextField
                  id="amount"
                  name="amount"
                  fullWidth
                  containerId="standard"
                  label={`Item cost (${tradingCurrency})`}
                  startAdornment="Filled-Dollar"
                  value={values.amount ? values.amount.toString() : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.amount && touched.amount ? errors.amount : ''}
                  testId="create-neworder-modal-newiteamount-input"
                />
              </div>
            </div>
            <div className="two-col">
              <div className="lp-w-half white-bg">
                <TextField
                  id="quantity"
                  name="quantity"
                  fullWidth
                  containerId="standard"
                  label="Quantity"
                  value={values.quantity ? values.quantity.toString() : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.quantity && touched.quantity ? errors.quantity : ''}
                  testId="create-neworder-modal-newitemquantity-input"
                />
              </div>
              <div className="lp-w-half">
                <div className="lp-flex h-58px">
                  <Button
                    type="button"
                    className="no-min-width w-70 mt-12"
                    size="medium"
                    variant="ghost"
                    onClick={handleCancel}
                    testId="create-neworder-modal-newitem-cancel-button"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    className="no-min-width w-70 mt-12"
                    size="medium"
                    variant="primary"
                    testId="create-neworder-modal-newitem-submit-button"
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default AddNewItem;
