import { getMerchant, getMerchantGeneralSettings, updateMerchant } from 'api/merchant/dashboard/merchants';
import { getStandardMerchantInitValues } from 'api/merchant/dashboard/merchants.init.values';
import { AnyMerchant, MerchantGeneralSettingsResponse } from 'api/merchant/dashboard/merchants.types';
import { FormikHelpers } from 'formik';
import MerchantDashboardLayout from 'layouts/merchant-dashboard/MerchantDashboardLayout';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/merchant/hooks';
import getErrorMessage from 'utils/getErrorMessage';

import { AddressModalForm } from '../partials/address-modal/types';
import { NotificationEmailsModalForm } from '../partials/notification-emails-modal/types';
import BillingSettingView from './partials/BillingSettingView';
import { toUpdateMerchantBillingSettingsRequest } from './partials/types';

function BillingSettingPage() {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [updateEmailNotificationsErrorMessage, setUpdateEmailNotificationsErrorMessage] = useState<string>('');
  const [updateAddressErrorMessage, setUpdateAddressErrorMessage] = useState<string>('');
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [isBillingDetailsFetchLoading, setIsBillingDetailsFetchLoading] = useState<boolean>(true);
  const [isNotificationEmailsModalOpen, setIsNotificationEmailsModalOpen] = useState<boolean>(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState<boolean>(false);

  const [merchantResponse, setMerchantResponse] = useState<AnyMerchant>(() => getStandardMerchantInitValues());
  const [generalSettings, setGeneralSettings] = useState<MerchantGeneralSettingsResponse>({
    settlementType: 'Net',
    supportedCustomerTypes: [],
    createdAt: '',
    updatedAt: '',
  });

  const { merchantId } = useAppSelector((state) => ({
    merchantId: state.config.merchantId,
  }));

  const notificationEmailsSubmitHandler = useCallback(
    async (values: NotificationEmailsModalForm, formObj: FormikHelpers<NotificationEmailsModalForm>) => {
      try {
        setUpdateEmailNotificationsErrorMessage('');

        const merchantBillingSettings = toUpdateMerchantBillingSettingsRequest(values, merchantResponse);

        const response = await updateMerchant(merchantId, merchantBillingSettings);
        setMerchantResponse(response);

        toast.success('Billing settings updated successfully');
        formObj.resetForm();

        setIsNotificationEmailsModalOpen(!isNotificationEmailsModalOpen);
      } catch (error) {
        setUpdateEmailNotificationsErrorMessage(getErrorMessage(error));
      }
    },
    [merchantResponse, merchantId, isNotificationEmailsModalOpen],
  );

  const addressSubmitHandler = useCallback(
    async (values: AddressModalForm, formObj: FormikHelpers<AddressModalForm>) => {
      try {
        setUpdateAddressErrorMessage('');

        const merchantBillingSettings = toUpdateMerchantBillingSettingsRequest(values, merchantResponse);

        const response = await updateMerchant(merchantId, merchantBillingSettings);
        setMerchantResponse(response);

        toast.success('Billing settings updated successfully');
        formObj.resetForm();

        setIsAddressModalOpen(!isAddressModalOpen);
      } catch (error) {
        setUpdateAddressErrorMessage(getErrorMessage(error));
      }
    },
    [merchantResponse, merchantId, isAddressModalOpen],
  );

  const fetchMerchant = useCallback(async () => {
    try {
      setIsBillingDetailsFetchLoading(true);

      const [mResponse, mGeneralSetings] = await Promise.all([
        getMerchant(merchantId),
        getMerchantGeneralSettings(merchantId),
      ]);

      setMerchantResponse(mResponse);
      setGeneralSettings(mGeneralSetings);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setIsBillingDetailsFetchLoading(false);
    }
  }, [merchantId]);

  useEffect(() => {
    if (!merchantId) {
      return;
    }

    setErrorMessage('');
    fetchMerchant();
  }, [merchantId, fetchMerchant]);

  useEffect(() => {
    if (!isBillingDetailsFetchLoading) {
      setIsPageLoading(false);
    }
  }, [isBillingDetailsFetchLoading, setIsPageLoading]);

  return (
    <MerchantDashboardLayout activeTab="settings" title="Billing Settings">
      <BillingSettingView
        merchantDetails={merchantResponse}
        generalSettings={generalSettings}
        errorMessage={errorMessage}
        updateEmailNotificationsErrorMessage={updateEmailNotificationsErrorMessage}
        updateAddressErrorMessage={updateAddressErrorMessage}
        isPageLoading={isPageLoading}
        isBillingDetailsFetchLoading={isBillingDetailsFetchLoading}
        isNotificationEmailsModalOpen={isNotificationEmailsModalOpen}
        isAddressModalOpen={isAddressModalOpen}
        setIsNotificationEmailsModalOpen={setIsNotificationEmailsModalOpen}
        notificationEmailsSubmitHandler={notificationEmailsSubmitHandler}
        setIsAddressModalOpen={setIsAddressModalOpen}
        addressSubmitHandler={addressSubmitHandler}
      />
    </MerchantDashboardLayout>
  );
}

export default BillingSettingPage;
