import { ordersQueryDocument } from 'api/graphql';
import { OrderStatus, OrderType } from 'api/graphql/generated/graphql';
import { getMerchant } from 'api/merchant/dashboard/merchants';
import { CustomField } from 'api/merchant/dashboard/merchants.types';
import { createOrder, deleteOrder, getInvoiceToken, orderResendComms } from 'api/merchant/dashboard/orders';
import { CreateOrderRequest } from 'api/merchant/dashboard/orders.types';
import { UNEXPECTED_ERROR } from 'config/constants';
import MerchantDashboardLayout from 'layouts/merchant-dashboard/MerchantDashboardLayout';
import ErrorComponent from 'pages/common/error/ErrorComponent';
import { toAmountFilterInput, toInstantFilterInput } from 'pages/common/filters';
import { LISTING_RECORDS_PER_PAGE } from 'pages/platform/constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { useAppSelector } from 'redux/merchant/hooks';
import { useQuery } from 'urql';
import { CountryCurrency } from 'utils/currency';
import getErrorMessage from 'utils/getErrorMessage';

import { Spinner } from '@limepayments/cosmic';

import { getInitialFilterState, getInitialListingPageState } from './initialStates';
import UnpaidRequestsPage from './partials/UnpaidRequestsPage';
import { OrderActionsProps } from './partials/order-actions/context';
import { IListingPagePropsObj, IVirtualFilterObj, OrdersQueryEdges } from './types';

function VirtualTreminalPage() {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [listingPageProps, setListingPageProps] = useState<IListingPagePropsObj>(getInitialListingPageState());
  const [filterObj, setFilterObj] = useState<IVirtualFilterObj>(getInitialFilterState());
  const [filterPopupOpen, setFilterPopupOpen] = useState<boolean>(false);
  const [orderCustomFields, setOrderCustomFields] = useState<CustomField[]>([]);

  const { merchantId, merchantName, apiBaseUri, orderBaseUri, merchantTradingCountry } = useAppSelector((state) => ({
    merchantId: state.config.merchantId,
    merchantName: state.config.merchantName,
    apiBaseUri: state.config.apiBaseUri,
    orderBaseUri: state.config.orderBaseUri,
    merchantTradingCountry: state.config.merchantTradingCountry,
  }));

  const { first, last, before, after } = useMemo(() => listingPageProps, [listingPageProps]);

  const limit = useMemo(() => first ?? last ?? LISTING_RECORDS_PER_PAGE, [first, last]);

  const filters = useMemo(() => {
    const { amount, orderId, description, requestedOn, status } = filterObj;

    const orderIds = [orderId?.value ?? '', description?.value ?? ''].filter((value) => !!value);

    return {
      amount: amount ? toAmountFilterInput(amount) : null,
      orderIds: orderIds.length ? orderIds : null,
      // TODO: customer filter
      // customerIds: customer?.value,
      createdAt: requestedOn ? toInstantFilterInput(requestedOn) : null,
      status: status?.length ? status : [OrderStatus.Created, OrderStatus.Cancelled],
    };
  }, [filterObj]);

  const [{ fetching, data, error: ordersError }, reexecuteQuery] = useQuery({
    query: ordersQueryDocument,
    variables: {
      last,
      before,
      first,
      after,
      orderType: OrderType.PayByLink,
      ...filters,
    },
  });

  const error = ordersError?.message ?? errorMessage;

  const handleSort = useCallback(
    (selectedColumn: TableColumn<OrdersQueryEdges[number]>, sortDirection: SortOrder) => {},
    [],
  );

  const toggleFilter = useCallback(() => {
    setFilterPopupOpen((wasOpened) => !wasOpened);
  }, []);

  const handlePageChange = useCallback(
    (page: number) => {
      if (!data?.orders) return;

      const isFirst = page === 1;
      const isPrev = !isFirst && page === listingPageProps.page - 1;
      const isNext = page === listingPageProps.page + 1;
      const isLast = !isFirst && !isNext && page === Math.ceil(data.orders.totalCount / limit);

      setListingPageProps({
        ...listingPageProps,
        page,
        last: isLast || isPrev ? limit : null,
        before: !isLast && isPrev ? data.orders.pageInfo.startCursor ?? null : null,
        first: isFirst || isNext ? limit : null,
        after: !isFirst && isNext ? data.orders.pageInfo.endCursor ?? null : null,
      });
    },
    [data?.orders, limit, listingPageProps],
  );

  const handlePerRowsChange = useCallback(
    async (newPerPage: number, page: number) => {
      setListingPageProps({
        ...listingPageProps,
        page,
        last: listingPageProps.last !== null ? newPerPage : null,
        first: listingPageProps.first !== null ? newPerPage : null,
      });
    },
    [listingPageProps],
  );

  const fetchCustomFields = useCallback(async () => {
    try {
      setIsPageLoading(true);

      const response = await getMerchant(merchantId);
      if ('orderCustomFields' in response) {
        setOrderCustomFields(response.orderCustomFields ?? []);
      } else {
        setErrorMessage('Merchant error');
      }
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setIsPageLoading(false);
    }
  }, [merchantId]);

  const handleOrderCreation = useCallback(
    async (data: CreateOrderRequest) => {
      try {
        const resp = await createOrder(data);
        reexecuteQuery();
        return resp;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    [reexecuteQuery],
  );

  const cancelOrder = useCallback(
    async (orderId: string): Promise<void> => {
      await deleteOrder(orderId);
      reexecuteQuery();
    },
    [reexecuteQuery],
  );

  const fetchOrderProcessUrl = useCallback(
    async (orderId: string): Promise<string> => {
      return await getInvoiceToken(apiBaseUri, merchantId, orderId).then((resp) => {
        if (resp.invoiceToken) {
          return `${orderBaseUri}/${merchantName}/invoice/${orderId}?token=${resp.invoiceToken}`;
        }
        return Promise.reject(UNEXPECTED_ERROR);
      });
    },
    [apiBaseUri, merchantId, merchantName, orderBaseUri],
  );

  const sendOrderComms = useCallback<OrderActionsProps['sendOrderComms']>(
    async (orderId, sendSms): Promise<void> => {
      return await orderResendComms(
        apiBaseUri,
        merchantId,
        orderId,
        `sendEmail=true&sendSms=${sendSms ? 'true' : 'false'}`,
      );
    },
    [apiBaseUri, merchantId],
  );

  useEffect(() => {
    setErrorMessage('');
    fetchCustomFields();
  }, [fetchCustomFields]);

  return (
    <MerchantDashboardLayout activeTab="virtual-terminal" title="Virtual Terminal">
      {!isPageLoading && !!error && <ErrorComponent bodyText={error} />}

      {isPageLoading && (
        <div className="spinner-wrapper">
          <Spinner variant="simple" isVisible label="Loading..." />
        </div>
      )}

      {!isPageLoading && (
        <UnpaidRequestsPage
          virtualTerminalList={data?.orders?.edges ?? []}
          fetchListLoader={fetching}
          totalRows={data?.orders?.totalCount ?? 0}
          handlePerRowsChange={handlePerRowsChange}
          handlePageChange={handlePageChange}
          limit={limit}
          filterPopupOpen={filterPopupOpen}
          toggleFilter={toggleFilter}
          filterValuesObj={filterObj}
          setFilterValuesObj={setFilterObj}
          activePage={listingPageProps.page}
          handleSort={handleSort}
          tradingCurrency={CountryCurrency[merchantTradingCountry]}
          tradingCountry={merchantTradingCountry}
          handleOrderCreation={handleOrderCreation}
          orderCustomFields={orderCustomFields}
          cancelOrder={cancelOrder}
          fetchOrderProcessUrl={fetchOrderProcessUrl}
          sendOrderComms={sendOrderComms}
        />
      )}
    </MerchantDashboardLayout>
  );
}

export default VirtualTreminalPage;
