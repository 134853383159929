import { CustomField } from 'api/merchant/dashboard/merchants.types';
import { CreateOrderRequest, CreateOrderResponse } from 'api/merchant/dashboard/orders.types';
import * as s from 'assets/styles/Platforms.styles';
import { UNEXPECTED_ERROR } from 'config/constants';
import { ComponentProps, useCallback, useMemo } from 'react';
import { useEffect, useState } from 'react';
import { CountryType } from 'redux/merchant/slice/configSlice';
import { Currency } from 'utils/currency';
import getErrorMessage from 'utils/getErrorMessage';

import { Icon, Modal } from '@limepayments/cosmic';

import ConfirmOrder from './create-order-modal-partials/ConfirmOrder';
import ManageOrderItems from './create-order-modal-partials/ManageOrderItems';
import NewOrderDetails from './create-order-modal-partials/NewOrderDetails';
import OrderCreationSuccess from './create-order-modal-partials/OrderCreationSuccess';
import { CreateOrderContext } from './create-order-modal-partials/context';
import { toCreateOrderRequest } from './create-order-modal-partials/types';
import { ModalSteps } from './create-order-modal-partials/types';

export const ModalCloseButton = (props: ComponentProps<'button'>) => (
  <button
    type="button"
    style={{
      position: 'absolute',
      top: 'var(--lp-space-xs)',
      right: 'var(--lp-space-xs)',
      outline: 'none',
    }}
    data-testid="modal-close-button"
    {...props}
  >
    <Icon name="Cross" label="Close" />
  </button>
);

interface Props {
  isOpen: boolean;
  modalToggler: (isOpen: boolean) => void;
  tradingCurrency: Currency;
  tradingCountry: CountryType;
  handleOrderCreation: (data: CreateOrderRequest) => Promise<CreateOrderResponse>;
  orderCustomFields: Array<CustomField>;
}

export interface OrderItem {
  name: string;
  amount: number;
  quantity: number;
  editMode?: boolean;
}

export interface OrderDetails {
  orderNumber: string;
  amount: number;
  gstAmount: number;
  currency: string;
  description?: string;
  customerEmail: string;
  customerPhone: string;
  customerName: string;
  customFields: Array<{ key: string; value: string }>;
}

function CreateNewOrderModal({
  isOpen,
  modalToggler,
  tradingCurrency,
  tradingCountry,
  handleOrderCreation,
  orderCustomFields,
}: Props) {
  const initOrderDetails = useMemo(
    () => ({
      orderNumber: '',
      amount: 0,
      gstAmount: 0,
      currency: '',
      description: '',
      customerEmail: '',
      customerPhone: '',
      customerName: '',
      customFields: orderCustomFields.map((value) => ({ key: value.key, value: '' })),
    }),
    [orderCustomFields],
  );

  const [activeStep, setActiveStep] = useState<string>(ModalSteps.ManageOrderItems);
  const [itemList, setItemList] = useState<OrderItem[]>([]);
  const [orderDetails, setOrderDetails] = useState<OrderDetails>(initOrderDetails);
  const [isModalLoading, setIsModalLoading] = useState<boolean>(false);
  const [isSendingToCustomer, setIsSendingToCustomer] = useState<boolean>(false);
  const [isProcessingNow, setIsProcessingNow] = useState<boolean>(false);
  const [paymentRequestedEmail, setPaymentRequestedEmail] = useState<string>();
  const [displayTax, setDisplayTax] = useState<boolean>(false);
  const [request3ds, setRequest3ds] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const addItem = useCallback(
    (item: OrderItem) => {
      setItemList([...itemList, item]);
    },
    [itemList],
  );

  const removeItem = useCallback(
    (index: number) => {
      setItemList(itemList.filter((key, index2) => index2 !== index));
    },
    [itemList],
  );

  const updateItem = useCallback(
    (index: number, editedItem: OrderItem) => {
      setItemList(itemList.map((item, index2) => (index === index2 ? editedItem : item)));
    },
    [itemList],
  );

  const editItem = useCallback(
    (index: number) => {
      setItemList(
        itemList.map((item, index2) => (index === index2 ? { ...item, editMode: true } : { ...item, editMode: false })),
      );
    },
    [itemList],
  );

  const resetEditMode = useCallback(() => {
    setItemList(itemList.map((item) => ({ ...item, editMode: false })));
  }, [itemList]);

  const itemsInEditMode = useMemo(
    () => itemList.reduce((count, item) => (item.editMode ? count + 1 : count), 0),
    [itemList],
  );

  const handleOrderSubmit = useCallback(
    async (itemList: OrderItem[], orderDetails: OrderDetails, sendEmail: boolean) => {
      setIsProcessingNow(!sendEmail);
      setIsSendingToCustomer(sendEmail);
      setIsModalLoading(true);
      setErrorMessage('');
      handleOrderCreation(toCreateOrderRequest(itemList, orderDetails, sendEmail, displayTax, request3ds))
        .then((resp) => {
          setIsProcessingNow(false);
          setIsSendingToCustomer(false);
          setIsModalLoading(false);

          if ('PayByLinkOrder' in resp) {
            if (sendEmail) {
              setPaymentRequestedEmail(orderDetails.customerEmail);
              setActiveStep(ModalSteps.OrderCreationSuccess);
            } else {
              if (resp.PayByLinkOrder.magicLink) {
                window.open(resp.PayByLinkOrder.magicLink, '_blank');
              }
              modalToggler(false);
            }
          } else {
            setErrorMessage(UNEXPECTED_ERROR);
          }
        })
        .catch((error: Error) => {
          setErrorMessage(getErrorMessage(error));
        })
        .then(() => {
          setIsModalLoading(false);
          setIsSendingToCustomer(false);
          setIsProcessingNow(false);
        });
    },
    [displayTax, handleOrderCreation, modalToggler, request3ds],
  );

  useEffect(() => {
    if (isOpen) {
      if (activeStep !== ModalSteps.ConfirmOrder && errorMessage.length) {
        setErrorMessage('');
      }
      if (activeStep !== ModalSteps.ManageOrderItems && itemsInEditMode > 0) {
        resetEditMode();
      }
    } else {
      setActiveStep(ModalSteps.ManageOrderItems);
      setOrderDetails(initOrderDetails);
      if (itemList.length) {
        setItemList([]);
      }
      setDisplayTax(false);
      setRequest3ds(false);
      setErrorMessage('');
      setIsModalLoading(false);
      setIsSendingToCustomer(false);
      setIsProcessingNow(false);
    }
  }, [activeStep, errorMessage, initOrderDetails, isOpen, itemsInEditMode, itemList.length, resetEditMode]);

  const contextValues = useMemo(
    () => ({
      itemList,
      addItem,
      updateItem,
      removeItem,
      editItem,
      resetEditMode,
      tradingCurrency,
      tradingCountry,
      orderDetails,
      setActiveStep,
      modalToggler,
      setOrderDetails,
      isModalLoading,
      isSendingToCustomer,
      isProcessingNow,
      paymentRequestedEmail,
      orderCustomFields,
      displayTax,
      setDisplayTax,
      request3ds,
      setRequest3ds,
      errorMessage,
    }),
    [
      addItem,
      displayTax,
      editItem,
      errorMessage,
      isModalLoading,
      isProcessingNow,
      isSendingToCustomer,
      itemList,
      modalToggler,
      orderCustomFields,
      orderDetails,
      paymentRequestedEmail,
      removeItem,
      request3ds,
      resetEditMode,
      tradingCountry,
      tradingCurrency,
      updateItem,
    ],
  );

  return (
    <Modal isOpen={isOpen} className="modal-medium update-pass-modal" testId="create-neworder-modal">
      <ModalCloseButton onClick={() => modalToggler(false)} />
      <CreateOrderContext.Provider value={contextValues}>
        <s.Wrapper className="">
          {activeStep === ModalSteps.ManageOrderItems && <ManageOrderItems />}
          {activeStep === ModalSteps.NewOrderDetails && <NewOrderDetails />}
          {activeStep === ModalSteps.ConfirmOrder && <ConfirmOrder handleOrderSubmit={handleOrderSubmit} />}
          {activeStep === ModalSteps.OrderCreationSuccess && <OrderCreationSuccess />}
        </s.Wrapper>
      </CreateOrderContext.Provider>
    </Modal>
  );
}

export default CreateNewOrderModal;
