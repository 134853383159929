import { PaginatedResponse, handlePaginatedResponse, handleResponse } from 'api/utils';
import { getAprilApiHost } from 'utils/env';
import getFetchOptions from 'utils/getFetchOptions';

import {
  CreateOrderRequest,
  CreateOrderResponse,
  InvoiceTokenResponse,
  OrderAPIResponseType,
  PayOrderRequest,
  PayOrderResponse,
  ThreeDSAuthorisationRequired,
} from './orders.types';

export const getMerchantOrderList = async (queryString: string): Promise<PaginatedResponse<OrderAPIResponseType[]>> => {
  const options = await getFetchOptions();
  return await fetch(`${await getAprilApiHost()}/orders?${queryString}`, options).then(handlePaginatedResponse);
};

export const getMerchantOrderDetail = async (orderId: string, bearerToken?: string): Promise<OrderAPIResponseType> => {
  const options = await getFetchOptions('GET', { bearerToken });
  return await fetch(`${await getAprilApiHost()}/orders/${orderId}`, options).then(handleResponse);
};

export const payMerchantOrder = async (
  orderId: string,
  payload: PayOrderRequest,
  bearerToken?: string,
): Promise<PayOrderResponse> => {
  const options = await getFetchOptions('POST', { payload, bearerToken });
  return await fetch(`${await getAprilApiHost()}/orders/${orderId}/pay`, options).then((res) =>
    handleResponse(res, ({ message, detail }) => detail || message),
  );
};

export const resolveThreeDSAuthorisationRequired = (threeDSAuthorisationRequired: ThreeDSAuthorisationRequired) =>
  new Promise((resolve, reject) =>
    (window as any).AprilCheckout.handlePaymentActionRequired(threeDSAuthorisationRequired, resolve, reject),
  );

export const createOrder = async (payload: CreateOrderRequest, bearerToken?: string): Promise<CreateOrderResponse> => {
  const options = await getFetchOptions('POST', { payload, bearerToken });
  return await fetch(`${await getAprilApiHost()}/orders`, options).then((res) =>
    handleResponse(res, ({ message, detail }) => detail || message),
  );
};

export const orderResendComms = async (
  host: string,
  merchantId: string,
  orderId: string,
  queryString: string,
): Promise<void> => {
  const options = await getFetchOptions('POST');
  return await fetch(
    `${host}/merchants/${merchantId}/orders/invoices/${orderId}/resend-comms?${queryString}`,
    options,
  ).then(handleResponse);
};

export const deleteOrder = async (orderId: string, bearerToken?: string): Promise<OrderAPIResponseType> => {
  const options = await getFetchOptions('DELETE');
  return await fetch(`${await getAprilApiHost()}/orders/${orderId}`, options).then((res) =>
    handleResponse(res, ({ message, detail }) => detail || message),
  );
};

export const getInvoiceToken = async (
  host: string,
  merchantId: string,
  orderId: string,
): Promise<InvoiceTokenResponse> => {
  const options = await getFetchOptions('GET');
  return await fetch(`${host}/merchants/${merchantId}/orders/invoices/${orderId}/token`, options).then(handleResponse);
};
