import { deletePaymentSourceDomains, updatePaymentSourceDomains } from 'api/merchant/dashboard/settings/payment';
import { CheckoutApplePayAPIResponse } from 'api/merchant/dashboard/settings/payment.types';
import * as s from 'assets/styles/Platforms.styles';
import { ErrorMessage, Formik, FormikProps } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/merchant/hooks';
import getErrorMessage from 'utils/getErrorMessage';

import { Button, Heading, Icon, Link, Message, Modal, Switch, Text, TextField } from '@limepayments/cosmic';

import { paymentSourceModalValidationSchema as validationSchema } from './validation.schema';

interface Props {
  isOpen: boolean;
  modalToggler: (isOpen: boolean) => void;
  paymentSourceObj: CheckoutApplePayAPIResponse;
  refreshDataHandler: () => void;
}

function PaymentSourceModal({ isOpen, modalToggler, paymentSourceObj, refreshDataHandler }: Props) {
  const errorContainerRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<FormikProps<CheckoutApplePayAPIResponse>>(null);
  const [editRequestLoading, setEditRequestLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [initialValues, setInitialValues] = useState<CheckoutApplePayAPIResponse>({
    ...paymentSourceObj,
    domain: '',
    applePayEnabled: paymentSourceObj.domainNames.length > 0,
  });
  const [deletedDomainsList, setDeletedDomainsList] = useState<Array<string>>([]);

  const { apiBaseUri, merchantId } = useAppSelector((state) => ({
    apiBaseUri: state.config.apiBaseUri,
    merchantId: state.config.merchantId,
  }));

  const submitHandler = async (values: CheckoutApplePayAPIResponse, formObj: { resetForm: () => void }) => {
    try {
      const { domain, ...postData } = values;

      setEditRequestLoading(true);
      setErrorMsg('');

      if (deletedDomainsList.length > 0 || (!values.applePayEnabled && values.domainNames.length > 0)) {
        await deletePaymentSourceDomains(
          apiBaseUri,
          merchantId,
          deletedDomainsList.length > 0 ? deletedDomainsList : values.domainNames,
        );
      }

      if (!values.applePayEnabled && values.domainNames.length > 0) {
        postData.domainNames = [];
      }

      if (postData.domainNames.length > 0) {
        await updatePaymentSourceDomains(apiBaseUri, merchantId, postData.domainNames);
      }

      toast.success('Payment sources updated successfully');
      setDeletedDomainsList([]);

      formObj.resetForm();
      modalToggler(false);
      refreshDataHandler();
    } catch (error) {
      setErrorMsg(getErrorMessage(error));
    } finally {
      setEditRequestLoading(false);
    }
  };

  useEffect(() => {
    if (errorMsg.length > 0 && errorContainerRef && errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView();
    }
  }, [errorMsg]);

  useEffect(() => {
    if (isOpen) {
      setInitialValues({
        ...paymentSourceObj,
        domain: '',
        applePayEnabled: paymentSourceObj.domainNames.length > 0,
      });
      setErrorMsg('');
      setDeletedDomainsList([]);
    }
  }, [isOpen, paymentSourceObj]);

  return (
    <Modal isOpen={isOpen} className="modal-medium update-pass-modal" testId="payment-sources-modal">
      <s.Wrapper className="">
        <div className="modal-main p-24">
          <div className="modal-header">
            <div className="content-main bb-1">
              <Heading alignment="center" tagName="h2" variant="xs" className="mt-12 mb-16 lp-justify-center lp-flex">
                Payment sources
              </Heading>
            </div>
          </div>
          <Formik<CheckoutApplePayAPIResponse>
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values, formObj: { resetForm: () => void }) => {
              submitHandler(values, formObj);
            }}
            validationSchema={validationSchema}
            innerRef={formRef}
          >
            {({ values, handleSubmit, errors, touched, setFieldValue, handleChange, handleBlur }) => (
              <form autoComplete="off" onSubmit={handleSubmit} className="content-wrap-form">
                <div className="modal-content p-28 sm-p-0 sm-mt-24">
                  {errorMsg.length > 0 && (
                    <div className="mt-24 mb-24" ref={errorContainerRef}>
                      <Message
                        type="inline"
                        children={<span className="text-wrap">{errorMsg}</span>}
                        variant={'error'}
                      />
                    </div>
                  )}
                  {/*
                  TODO: Credit/Debit card option to be imeplemented when APIs are available

                  <div className="lp-w-full pb-24 bb-1 mb-24">
                    <div className="lp-w-full mb-8">
                      <Text variant="body-2" className="text-dark-50 mb-0" isEmphasised>
                        Credit / debit card
                      </Text>
                    </div>
                    <div className="lp-w-full mb-24">
                      <div className="lp-flex payment-switch">
                        <Switch
                          checked
                          id="someid"
                          label="Enabled"
                          className="text-neutral-600"
                          onChange={function noRefCheck() {}}
                          size="small"
                        />
                      </div>
                    </div>
                  </div>
                  */}
                  <div className="lp-w-full mb-24">
                    <div>
                      <Text variant="body-2" className="text-dark-50 mb-0" isEmphasised>
                        Apple Pay™
                      </Text>
                      <Text variant="caption" className="text-dark-50 mb-12">
                        Compatible devices only (e.g. Safari web browsers, iOS devices).
                      </Text>
                      <Text variant="caption" className="text-dark-50 mb-12">
                        To utilise Apple Pay™ at the checkout you must register your domains and host a domain
                        verification file.
                      </Text>
                      <Text variant="caption" className="text-dark-50 mb-12">
                        For more information, please read our{' '}
                        <Link
                          size="small"
                          target="_blank"
                          className="fw-medium"
                          href="https://docs.meetapril.io/developer-portal/apple-pay/"
                        >
                          developer documentation
                        </Link>
                        .
                      </Text>
                    </div>
                    <div
                      className={`lp-flex payment-switch${
                        errors.applePayEnabled && touched.applePayEnabled ? ` has-error` : ``
                      }`}
                    >
                      <Switch
                        className="text-neutral-600"
                        id="applePayEnabled"
                        label={values.applePayEnabled ? `Enabled` : `Disabled`}
                        size="small"
                        checked={values.applePayEnabled ? true : false}
                        onChange={(value) => {
                          setFieldValue('applePayEnabled', value);
                          if (!value) setFieldValue('domain', '');
                        }}
                        testId="payment-sources-modal-appalepay-switch"
                      />
                      <ErrorMessage name="applePayEnabled" component="span" className="Mui-error" />
                    </div>
                  </div>

                  {values.applePayEnabled && (
                    <div className="lp-w-full mb-16">
                      <Text variant="body-2" className="text-dark-50 mb-16" isEmphasised>
                        Enable on the following domains and sub-domains:
                      </Text>

                      <div className="lp-flex lp-tags lp-flex-wrap" data-testid="payment-sources-modal-domains">
                        {values.domainNames.map((link, key) => (
                          <div className="lp-flex lp-align-center tag" key={key}>
                            <Text variant="body-3" isEmphasised className="text-dark mt-0">
                              {link}
                            </Text>
                            <button
                              type="button"
                              className="lp-flex lp-align-center lp-justify-center tag-remove"
                              onClick={() => {
                                const linkArray = values.domainNames.filter((obj) => {
                                  return obj !== link;
                                });

                                setFieldValue('domainNames', linkArray);

                                const updatedDeletedDomainsList = [...deletedDomainsList];
                                updatedDeletedDomainsList.push(link);
                                setDeletedDomainsList(updatedDeletedDomainsList);
                              }}
                            >
                              <Icon name="Cross" />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {values.applePayEnabled && (
                    <div className="lp-w-full lp-flex lp-align-end lp-justify-between mb-24">
                      <div className="lp-w-full w-200">
                        <TextField
                          fullWidth
                          id="domain"
                          name="domain"
                          label="Domain"
                          containerId="standard"
                          inputMode="text"
                          type="domain"
                          value={values.domain}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            errors.domain && touched.domain
                              ? errors.domain
                              : errors.domainNames && touched.domainNames
                              ? (errors.domainNames as string)
                              : ``
                          }
                          testId="payment-sources-modal-enterdomain-input"
                        />
                      </div>
                      <div className="lp-w-120 btm-4px ml-auto">
                        <Button
                          type="button"
                          onClick={() => {
                            if (values.domain) {
                              const domainNames = values.domainNames;
                              domainNames.push(values.domain);
                              setFieldValue('domainNames', domainNames);
                              setFieldValue('domain', '');
                            }
                          }}
                          size="medium"
                          variant="reversed"
                          className="no-min-width lp-w-full"
                          disabled={!values.domain || (errors.domain && touched.domain) ? true : false}
                          testId="payment-sources-modal-adddomain-button"
                        >
                          <Icon name="Plus" />
                          Add
                        </Button>
                      </div>
                    </div>
                  )}

                  {/*
                  TODO: GooglePay, Buy now Pay later, Direct debit, PayTo options to be imeplemented when APIs are available
                  
                  <div className="lp-w-full pb-24 bb-1 mb-24 mt-32"></div>

                  <div className="lp-w-full mb-0">
                    <div>
                      <Text variant="body-2" className="text-dark-50 mb-0" isEmphasised>
                        Google Pay™
                      </Text>
                      <Text variant="caption" className="text-dark-50 mb-12">
                        Compatible devices only (e.g. Google Chrome web browser, Android devices)
                      </Text>
                    </div>
                    <div className="lp-flex payment-switch">
                      <Switch
                        checked
                        id="someid"
                        label="Enabled"
                        className="text-neutral-600"
                        onChange={function noRefCheck() {}}
                        size="small"
                      />
                    </div>
                  </div>

                  <div className="lp-w-full pb-24 bb-1 mb-24 mt-32"></div>

                  <div className="lp-w-full mb-0">
                    <div>
                      <Text variant="body-2" className="text-dark-50 mb-0" isEmphasised>
                        Buy now, Pay later
                      </Text>
                    </div>
                    <div className="lp-flex payment-switch">
                      <Switch
                        checked
                        id="someid"
                        label="Enabled"
                        className="text-neutral-600"
                        onChange={function noRefCheck() {}}
                        size="small"
                      />
                    </div>
                  </div>

                  <div className="lp-w-full pb-24 bb-1 mb-24 mt-32"></div>

                  <div className="lp-w-full mb-0">
                    <div>
                      <Text variant="body-2" className="text-dark-50 mb-0" isEmphasised>
                        Direct debit
                      </Text>
                    </div>
                    <div className="lp-flex payment-switch">
                      <Switch
                        checked
                        id="someid"
                        label="Enabled"
                        className="text-neutral-600"
                        onChange={function noRefCheck() {}}
                        size="small"
                      />
                    </div>
                  </div>

                  <div className="lp-w-full pb-24 bb-1 mb-24 mt-32"></div>

                  <div className="lp-w-full mb-0">
                    <div>
                      <Text variant="body-2" className="text-dark-50 mb-0" isEmphasised>
                        PayTo
                      </Text>
                    </div>
                    <div className="lp-flex payment-switch">
                      <Switch
                        checked
                        id="someid"
                        label="Enabled"
                        className="text-neutral-600"
                        onChange={function noRefCheck() {}}
                        size="small"
                      />
                    </div>
                  </div>
                  */}
                </div>

                <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
                  <Button
                    size="medium"
                    variant="ghost"
                    className="no-min-width w-70"
                    type="button"
                    onClick={() => modalToggler(false)}
                    disabled={editRequestLoading}
                    testId="payment-sources-modal-cancel-button"
                  >
                    Cancel
                  </Button>
                  <Button
                    size="medium"
                    variant="primary"
                    className="no-min-width"
                    type="submit"
                    disabled={editRequestLoading}
                    isLoading={editRequestLoading}
                    testId="payment-sources-modal-submit-button"
                  >
                    Update
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </s.Wrapper>
    </Modal>
  );
}

export default PaymentSourceModal;
