import { Address, addressToString } from 'api/fragments';
import { AnyMerchant, UpdateMerchantBusinessProfileRequest } from 'api/merchant/dashboard/merchants.types';

export interface BusinessProfileModalForm {
  contactPerson: {
    jobTitle: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
  };
  businessAddress: Address;
  isManualEnterAddress?: boolean;
  businessAddressManual?: string;
}

type toUpdateMerchantBusinessProfileRequestType = (
  businessProfileModalFormData: BusinessProfileModalForm,
  anyMerchant: AnyMerchant,
) => UpdateMerchantBusinessProfileRequest;
type toBusinessProfileModalFormType = (merchant: AnyMerchant) => BusinessProfileModalForm;

export const toBusinessProfileModalForm: toBusinessProfileModalFormType = ({ businessProfile }) => {
  return {
    contactPerson: {
      jobTitle: businessProfile.contactPerson.title,
      firstName: businessProfile.contactPerson.givenName,
      lastName: businessProfile.contactPerson.familyName,
      phoneNumber: businessProfile.phoneNumber,
      email: businessProfile.contactEmailAddress,
    },
    businessAddress: businessProfile.address,
    businessAddressManual: addressToString(businessProfile.address),
  };
};

export const toUpdateMerchantBusinessProfileRequest: toUpdateMerchantBusinessProfileRequestType = (
  { contactPerson, businessAddress },
  { businessProfile },
) => ({
  UpdateMerchantBusinessProfile: {
    timeZone: businessProfile.timeZone,
    businessName: businessProfile.businessName,
    businessType: businessProfile.businessType,
    companyType: businessProfile.companyType,
    website: businessProfile.website,
    supportSite: businessProfile.supportSite,
    industry: businessProfile.industry,
    taxId: businessProfile.taxId,
    contactPerson: {
      title: contactPerson.jobTitle,
      givenName: contactPerson.firstName,
      familyName: contactPerson.lastName,
    },
    contactEmailAddress: contactPerson.email,
    phoneNumber: contactPerson.phoneNumber,
    address: {
      unitNumber: businessAddress.unitNumber,
      streetNumber: businessAddress.streetNumber,
      streetName: businessAddress.streetName,
      streetType: businessAddress.streetType,
      suburb: businessAddress.suburb,
      city: businessAddress.country === 'NZ' ? businessAddress.city : undefined,
      state: businessAddress.country !== 'NZ' ? businessAddress.state : undefined,
      postalCode: businessAddress.postalCode,
      country: businessAddress.country,
    },
    supportEmailAddress: businessProfile.supportEmailAddress,
    brandName: businessProfile.brandName,
    cardStatementName: businessProfile.cardStatementName,
    smsSenderName: businessProfile.smsSenderName,
  },
});
