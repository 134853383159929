import numeral from 'numeral';
import { useRef } from 'react';
import { formatCurrency } from 'utils/currency';

import { Button, Heading, Icon } from '@limepayments/cosmic';

import EditItem from './EditItem';
import OrderListItem from './OrderItem';
import { useCreateOrderContext } from './context';
import { ModalSteps } from './types';

interface Props {
  allowActions: boolean;
  showEditButton?: boolean;
  showTotal?: boolean;
  displayTaxAmount?: boolean;
}

function OrderItemsTable({ allowActions, showEditButton, showTotal, displayTaxAmount }: Props) {
  const { itemList, setActiveStep, tradingCurrency, orderDetails } = useCreateOrderContext();
  const customContainerRef = useRef(null);

  return (
    <div className="lp-w-full">
      <div className="lp-flex lp-align-center">
        <Heading alignment="left" tagName="h1" variant="xxs" className="text-dark-50 mb-0">
          Item breakdown
        </Heading>
        {showEditButton && (
          <div className="ml-auto">
            <Button
              type="button"
              size="medium"
              className="no-min-width"
              variant="ghost"
              onClick={() => setActiveStep(ModalSteps.ManageOrderItems)}
            >
              Edit
              <Icon name="Edit" className="ml-8 mr-0" />
            </Button>
          </div>
        )}
      </div>
      <div className="item-breakdown">
        <table className="lp-w-full table-summary">
          <thead>
            <tr>
              <th>Item</th>
              <th>Count</th>
              <th>Total</th>
              <th></th>
            </tr>
          </thead>
          <tbody ref={customContainerRef}>
            {itemList.map((item, index) =>
              allowActions && item.editMode ? (
                <EditItem key={index} itemIndex={index} />
              ) : (
                <OrderListItem
                  key={index}
                  itemIndex={index}
                  allowActions={allowActions}
                  parentRef={customContainerRef}
                ></OrderListItem>
              ),
            )}
            {displayTaxAmount && (
              <tr>
                <td colSpan={2}>GST</td>
                <td data-testid="create-neworder-modal-taxamount">
                  {formatCurrency(orderDetails.gstAmount, tradingCurrency)}
                </td>
                <td></td>
              </tr>
            )}
          </tbody>
          {showTotal && (
            <tfoot>
              <tr>
                <th style={displayTaxAmount ? { borderTop: 0 } : {}} colSpan={2}>
                  Total (incl. GST)
                </th>
                <th style={displayTaxAmount ? { borderTop: 0 } : {}} data-testid="create-neworder-modal-orderamount">
                  {itemList &&
                    formatCurrency(
                      itemList.reduce(
                        (total, item) => numeral(item.amount).multiply(item.quantity).add(total).value() ?? 0,
                        0,
                      ),
                      tradingCurrency,
                    )}
                </th>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
    </div>
  );
}

export default OrderItemsTable;
