import { LISTING_RECORDS_PER_PAGE } from 'pages/platform/constants';

import { IListingPagePropsObj, IVirtualFilterObj, IVirtualFilterObjError } from './types';

export const getInitialFilterState = (): IVirtualFilterObj => {
  return {
    amountChecked: false,
    amount: null,
    orderIdChecked: false,
    orderId: null,
    customerChecked: false,
    customer: null,
    descriptionChecked: false,
    description: null,
    requestedOnChecked: false,
    requestedOn: null,
    statusChecked: false,
    status: null,
  };
};

export const getInitialErrorState = (): IVirtualFilterObjError => {
  return {
    amountError: '',
    orderIdError: '',
    customerError: '',
    descriptionError: '',
    requestedOnError: '',
    statusError: '',
  };
};

export const getInitialListingPageState = (): IListingPagePropsObj => {
  return {
    page: 1,
    last: null,
    before: null,
    first: LISTING_RECORDS_PER_PAGE,
    after: null,
  };
};
