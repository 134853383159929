import { CustomField } from 'api/merchant/dashboard/merchants.types';
import { CreateOrderRequest, CreateOrderResponse } from 'api/merchant/dashboard/orders.types';
import * as s from 'assets/styles/Platforms.styles';
import { useMemo, useState } from 'react';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { CountryType } from 'redux/merchant/slice/configSlice';
import { Currency } from 'utils/currency';

import { Button, FlexContainer, Heading, Icon, Text } from '@limepayments/cosmic';

import { IVirtualFilterObj, OrdersQueryEdges, getFilterCount } from '../types';
import CreateNewOrderModal from './CreateNewOrderModal';
// Import Static UIs
import FilterComponent from './FilterComponent';
import UnpaidRequestsDataTable from './UnpaidRequestsDataTable';
import { OrderActionsProps } from './order-actions/context';

export interface Props {
  virtualTerminalList: OrdersQueryEdges;
  fetchListLoader: boolean;
  totalRows: number;
  handlePerRowsChange: (newPerPage: number, page: number) => void;
  handlePageChange: (page: number) => void;
  limit: number;
  filterPopupOpen: boolean;
  toggleFilter: () => void;
  filterValuesObj: IVirtualFilterObj;
  setFilterValuesObj(filterObj: IVirtualFilterObj): void;
  activePage: number;
  handleSort: (selectedColumn: TableColumn<OrdersQueryEdges[number]>, sortDirection: SortOrder) => void;
  isFilterRequest?: boolean;
  isErrorRequest?: boolean;
  createOrderModalOpen?: boolean;
  tradingCurrency: Currency;
  tradingCountry: CountryType;
  handleOrderCreation: (data: CreateOrderRequest) => Promise<CreateOrderResponse>;
  orderCustomFields: Array<CustomField>;
  cancelOrder: (orderId: string) => Promise<void>;
  fetchOrderProcessUrl: (orderId: string) => Promise<string>;
  sendOrderComms: OrderActionsProps['sendOrderComms'];
}

function UnpaidRequestsPage({
  virtualTerminalList,
  totalRows,
  limit,
  fetchListLoader,
  handlePageChange,
  handlePerRowsChange,
  filterPopupOpen,
  toggleFilter,
  filterValuesObj,
  setFilterValuesObj,
  activePage,
  handleSort,
  isFilterRequest,
  isErrorRequest,
  createOrderModalOpen,
  tradingCurrency,
  tradingCountry,
  handleOrderCreation,
  orderCustomFields,
  cancelOrder,
  fetchOrderProcessUrl,
  sendOrderComms,
}: Props) {
  const [createNewOrderModalOpen, setCreateNewOrderModalOpen] = useState<boolean>(createOrderModalOpen ?? false);

  const filterCount = useMemo(() => getFilterCount(filterValuesObj), [filterValuesObj]);

  return (
    <div className="content-wrap-inner lp-full m-0">
      <s.WellWrap className="">
        <div className="lp-w-full lp-flex lp-justify-between sm-lp-flex-col mb-0 lp-align-end">
          <div className="lp-flex lp-flex-col">
            <Heading alignment="left" tagName="h2" variant="md" className="page-title mb-0">
              Virtual terminal
            </Heading>
            <Text alignment="left" tagName="p" variant="body-2">
              Create an order on behalf of your customer.
            </Text>
          </div>
          <FlexContainer classNames="lp-flex lp-flex-end sm:lp-full sm:mt-16">
            <div className="lp-flex order-2 position-relative">
              <Button
                className="lp-w-full"
                size="medium"
                variant="primary"
                onClick={() => setCreateNewOrderModalOpen(!createNewOrderModalOpen)}
                testId="virtual-terminal-neworder-button"
              >
                Create new order
                <Icon name="Plus" className="ml-16" />
              </Button>
            </div>
          </FlexContainer>
        </div>
      </s.WellWrap>

      <div className="lp-w-full lp-flex lp-justify-between sm-lp-flex-col mb-0">
        <div className="lp-flex lp-align-center">
          <Heading alignment="left" tagName="h2" variant="xs">
            Unpaid requests
          </Heading>
          <span
            className="icon-16px ml-16px"
            role="button"
            data-balloon="Order details will move to the Payments tab after a full or partial payment has been made."
            data-balloon-pos="up"
            data-balloon-mobile-pos="up"
            data-balloon-length="large"
            data-balloon-mobile-length="medium"
          >
            <Icon name="Info" className="" />
          </span>
        </div>
        <div className="merchant-controls sm-mt-16 pr-0">
          <FlexContainer classNames="lp-flex m-0">
            <div className="lp-flex order-2 position-relative m-0">
              <Button
                className="lp-w-full"
                size="small"
                variant="reversed"
                onClick={() => toggleFilter()}
                disabled={virtualTerminalList.length < 1 && filterCount === 0}
                testId="virtual-terminal-filter-button"
              >
                <Icon name="Filter" className="ml-0 mr-16" />
                Filter ({filterCount})
              </Button>

              {/* Filter Dropdown */}
              {filterPopupOpen && (
                <FilterComponent
                  filterValuesObj={filterValuesObj}
                  setFilterValuesObj={setFilterValuesObj}
                  toggleFilter={toggleFilter}
                  handlePageChange={handlePageChange}
                />
              )}
            </div>
          </FlexContainer>
        </div>
      </div>

      <UnpaidRequestsDataTable
        virtualTerminalList={virtualTerminalList}
        fetchListLoader={fetchListLoader}
        totalRows={totalRows}
        handlePerRowsChange={handlePerRowsChange}
        handlePageChange={handlePageChange}
        limit={limit}
        activePage={activePage}
        handleSort={handleSort}
        isFilterRequest={isFilterRequest || filterCount > 0}
        isErrorRequest={isErrorRequest}
        setCreateNewOrderModalOpen={setCreateNewOrderModalOpen}
        toggleFilter={toggleFilter}
        cancelOrder={cancelOrder}
        fetchOrderProcessUrl={fetchOrderProcessUrl}
        sendOrderComms={sendOrderComms}
      />

      <CreateNewOrderModal
        isOpen={createNewOrderModalOpen}
        modalToggler={setCreateNewOrderModalOpen}
        tradingCurrency={tradingCurrency}
        tradingCountry={tradingCountry}
        handleOrderCreation={handleOrderCreation}
        orderCustomFields={orderCustomFields}
      />
    </div>
  );
}

export default UnpaidRequestsPage;
