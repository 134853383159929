import { CustomField } from 'api/merchant/dashboard/merchants.types';
import { createContext, useContext } from 'react';
import { CountryType } from 'redux/merchant/slice/configSlice';
import { Currency } from 'utils/currency';

import { OrderDetails, OrderItem } from '../CreateNewOrderModal';

export const CreateOrderContext = createContext<
  | {
      itemList: OrderItem[];
      addItem: (item: OrderItem) => void;
      updateItem: (index: number, editedItem: OrderItem) => void;
      removeItem: (index: number) => void;
      editItem: (index: number) => void;
      resetEditMode: () => void;
      tradingCurrency: Currency;
      tradingCountry: CountryType;
      orderDetails: OrderDetails;
      setActiveStep: (activeStep: string) => void;
      modalToggler: (isOpen: boolean) => void;
      setOrderDetails: (orderDetails: OrderDetails) => void;
      isModalLoading: boolean;
      isSendingToCustomer: boolean;
      isProcessingNow: boolean;
      paymentRequestedEmail?: string;
      orderCustomFields: Array<CustomField>;
      displayTax: boolean;
      setDisplayTax: (displayTax: boolean) => void;
      request3ds: boolean;
      setRequest3ds: (request3ds: boolean) => void;
      errorMessage: string;
    }
  | undefined
>(undefined);

export function useCreateOrderContext() {
  const context = useContext(CreateOrderContext);
  if (context === undefined) {
    throw new Error('useCreateOrderContext must be used with a CreateOrderContext');
  }

  return context;
}
