import { AnyMerchant } from 'api/merchant/dashboard/merchants.types';
import * as s from 'assets/styles/Platforms.styles';
import { AustralianStates, NewZealandRegions } from 'config/constants';
import { ErrorMessage, Formik, FormikProps, getIn } from 'formik';
import { Fragment, RefObject, useEffect, useRef, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import scrollToComponent from 'react-scroll-to-component';

import { Button, Heading, Link, Message, Modal, Select, Text, TextField } from '@limepayments/cosmic';

import { BusinessProfileModalForm, toBusinessProfileModalForm } from './types';
import { validationSchema } from './validation.schema';

interface Props {
  isOpen: boolean;
  modalToggler: (val: boolean) => void;
  merchantDetails: AnyMerchant;
  onSubmitClick: (values: BusinessProfileModalForm, formObj: { resetForm: () => void }) => void;
  formRef: RefObject<FormikProps<BusinessProfileModalForm>>;
  errorMsg: string;
  setErrorMsg: (val: string) => void;
  editRequestLoading: boolean;
  isManualEnterAddress: boolean;
}

const getInitialState = (): BusinessProfileModalForm => {
  return {
    contactPerson: {
      jobTitle: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
    },
    businessAddress: {
      unitNumber: '',
      streetNumber: '',
      streetName: '',
      streetType: '',
      suburb: '',
      city: '',
      state: '',
      postalCode: '',
      country: 'AU',
    },
    businessAddressManual: '',
    isManualEnterAddress: true,
  };
};

function EditBusinessDetailsModal({
  isOpen,
  merchantDetails,
  formRef,
  errorMsg,
  setErrorMsg,
  editRequestLoading,
  modalToggler,
  onSubmitClick,
  isManualEnterAddress,
}: Props) {
  const [initialValues, setInitialValues] = useState<BusinessProfileModalForm>(getInitialState());

  const errorContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (errorMsg.length > 0 && errorContainerRef && errorContainerRef.current) {
      scrollToComponent(errorContainerRef.current, { duration: 500 });
    }
  }, [errorMsg]);

  useEffect(() => {
    if (isOpen) {
      setInitialValues({ ...toBusinessProfileModalForm(merchantDetails), isManualEnterAddress });
      setErrorMsg('');
    }
  }, [isOpen, merchantDetails, isManualEnterAddress, setErrorMsg]);

  return (
    <Modal isOpen={isOpen} className="modal-medium update-pass-modal" testId="businessprofile-update-modal">
      <s.Wrapper className="">
        <div className="modal-main p-24">
          <div className="modal-header">
            <div className="content-main bb-1">
              <Heading alignment="center" tagName="h2" variant="xs" className="mt-12 mb-16 lp-justify-center lp-flex">
                Business contact details
              </Heading>
            </div>
          </div>
          <Formik<BusinessProfileModalForm>
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values, formObj: { resetForm: () => void }) => {
              onSubmitClick(values, formObj);
            }}
            validationSchema={validationSchema}
            innerRef={formRef}
          >
            {({ values, handleSubmit, errors, touched, setFieldValue, handleChange, handleBlur }) => (
              <form autoComplete="off" onSubmit={handleSubmit} className="content-wrap-form">
                <div className="modal-content p-28 sm-p-0 sm-mt-24">
                  {errorMsg.length > 0 && (
                    <div className="mt-24 mb-24" ref={errorContainerRef}>
                      <Message
                        type="inline"
                        children={<span className="text-wrap">{errorMsg}</span>}
                        variant={'error'}
                      />
                    </div>
                  )}

                  <div className="lp-w-full lp-flex two-col mb-16">
                    <div className="lp-w-half w-200">
                      <TextField
                        containerId="standard"
                        label="First name"
                        inputMode="text"
                        testId="businessprofile-update-modal-firstname"
                        autoComplete="off"
                        id="contactPerson.firstName"
                        name="contactPerson.firstName"
                        onBlur={handleBlur}
                        value={values.contactPerson.firstName}
                        onChange={handleChange}
                        error={
                          errors.contactPerson &&
                          errors.contactPerson.firstName &&
                          touched.contactPerson &&
                          touched.contactPerson.firstName
                            ? errors.contactPerson.firstName
                            : ''
                        }
                      />
                    </div>
                    <div className="lp-w-half w-200">
                      <TextField
                        containerId="standard"
                        label="Last name"
                        inputMode="text"
                        autoComplete="off"
                        testId="businessprofile-update-modal-lastname"
                        id="contactPerson.lastName"
                        name="contactPerson.lastName"
                        onBlur={handleBlur}
                        value={values.contactPerson.lastName}
                        onChange={handleChange}
                        error={
                          errors.contactPerson &&
                          errors.contactPerson.lastName &&
                          touched.contactPerson &&
                          touched.contactPerson.lastName
                            ? errors.contactPerson.lastName
                            : ''
                        }
                      />
                    </div>
                  </div>

                  <div className="lp-w-full lp-flex mb-16">
                    <div className="lp-w-full w-200">
                      <TextField
                        containerId="standard"
                        label="Job title"
                        inputMode="text"
                        autoComplete="off"
                        testId="businessprofile-update-modal-title"
                        id="contactPerson.jobTitle"
                        name="contactPerson.jobTitle"
                        onBlur={handleBlur}
                        value={values.contactPerson.jobTitle}
                        onChange={handleChange}
                        error={
                          errors.contactPerson &&
                          errors.contactPerson.jobTitle &&
                          touched.contactPerson &&
                          touched.contactPerson.jobTitle
                            ? errors.contactPerson.jobTitle
                            : ''
                        }
                      />
                    </div>
                  </div>

                  <div className="lp-w-full lp-flex w-200 mb-16">
                    <TextField
                      containerId="standard"
                      label="Contact email address"
                      inputMode="email"
                      type="email"
                      autoComplete="off"
                      id="contactPerson.email"
                      name="contactPerson.email"
                      testId="businessprofile-update-modal-contactemail"
                      onBlur={handleBlur}
                      value={values.contactPerson.email}
                      onChange={handleChange}
                      error={
                        errors.contactPerson?.email && touched.contactPerson?.email ? errors.contactPerson.email : ''
                      }
                    />
                  </div>

                  <div
                    className={`lp-w-full w-200 white-bg mb-16${
                      errors.contactPerson?.phoneNumber && touched.contactPerson?.phoneNumber ? ` has-error` : ``
                    }`}
                  >
                    <div className={`form-floating no-pl`} data-testid="businessprofile-update-modal-phone">
                      <PhoneInput
                        id="contactPerson.phoneNumber"
                        name="contactPerson.phoneNumber"
                        defaultCountry="AU"
                        value={values.contactPerson.phoneNumber ?? ''}
                        onChange={(e) => {
                          setFieldValue('contactPerson.phoneNumber', e);
                        }}
                        onBlur={handleBlur}
                        className={`${values.contactPerson.phoneNumber ? `is-filled` : ``}`}
                        countries={['AU', 'NZ']}
                        error={
                          errors.contactPerson?.phoneNumber && touched.contactPerson?.phoneNumber
                            ? errors.contactPerson.phoneNumber
                            : ''
                        }
                      />
                      <label htmlFor="floatingPhoneInput">Contact phone number</label>
                    </div>
                    <ErrorMessage name="contactPerson.phoneNumber" component="span" className="Mui-error" />
                  </div>

                  {!values.isManualEnterAddress && (
                    <Fragment>
                      <div className="lp-w-full mb-16">
                        <div className="lp-w-full w-200 mb-4">
                          <TextField
                            containerId="standard"
                            label="Business address"
                            inputMode="text"
                            autoComplete="off"
                            testId="businessprofile-update-modal-address"
                            id="businessAddressManual"
                            name="businessAddressManual"
                            onBlur={handleBlur}
                            value={values.businessAddressManual}
                            onChange={handleChange}
                            error={
                              errors.businessAddressManual && touched.businessAddressManual
                                ? errors.businessAddressManual
                                : ''
                            }
                            helperText="You can use a home address if the business doesn't have a business address."
                          />
                        </div>
                      </div>
                      <Link
                        onClick={() => setFieldValue('isManualEnterAddress', true)}
                        size="small"
                        target="_self"
                        className={`fw-medium ${values.isManualEnterAddress ? `link-hidden` : ``}`}
                      >
                        Manually enter address
                      </Link>
                    </Fragment>
                  )}

                  {values.isManualEnterAddress && (
                    <div className="lp-w-full mt-24">
                      <div className="lp-w-full mb-16">
                        <Heading alignment="left" tagName="h2" variant="xs">
                          Business address
                        </Heading>
                        <Text variant="body-3" className="text-dark-50 mt-4">
                          You can use a home address if the business doesn't have a business address.
                        </Text>
                      </div>

                      <div className={`lp-w-full w-200 mb-16`}>
                        {
                          <Select
                            label="Country"
                            onChange={(value) => {
                              setFieldValue('businessAddress.country', value);
                              setFieldValue('businessAddress.state', '');
                            }}
                            options={[
                              {
                                text: 'Australia',
                                value: 'AU',
                              },
                              {
                                text: 'New Zealand',
                                value: 'NZ',
                              },
                            ]}
                            testId="businessprofile-update-modal-address-country"
                            value={values.businessAddress.country}
                          />
                        }
                      </div>

                      <div className="lp-w-full w-200 mb-16">
                        <TextField
                          fullWidth
                          label="Unit number (optional)"
                          inputMode="text"
                          autoComplete="off"
                          testId="businessprofile-update-modal-address-unitno"
                          id="businessAddress.unitNumber"
                          name="businessAddress.unitNumber"
                          onBlur={handleBlur}
                          value={values.businessAddress.unitNumber}
                          onChange={handleChange}
                          error={
                            getIn(errors, 'businessAddress.unitNumber') && getIn(touched, 'businessAddress.unitNumber')
                              ? getIn(errors, 'businessAddress.unitNumber')
                              : ''
                          }
                        />
                      </div>
                      <div className="lp-w-full w-200 mb-16">
                        <TextField
                          fullWidth
                          label="Street number"
                          inputMode="text"
                          autoComplete="off"
                          testId="businessprofile-update-modal-address-streetnumber"
                          id="businessAddress.streetNumber"
                          name="businessAddress.streetNumber"
                          onBlur={handleBlur}
                          value={values.businessAddress.streetNumber}
                          onChange={handleChange}
                          error={
                            getIn(errors, 'businessAddress.streetNumber') &&
                            getIn(touched, 'businessAddress.streetNumber')
                              ? getIn(errors, 'businessAddress.streetNumber')
                              : ''
                          }
                        />
                      </div>

                      <div className="lp-w-full w-200 mb-16">
                        <TextField
                          fullWidth
                          label="Street name"
                          inputMode="text"
                          autoComplete="off"
                          testId="businessprofile-update-modal-address-streetname"
                          id="businessAddress.streetName"
                          name="businessAddress.streetName"
                          onBlur={handleBlur}
                          value={values.businessAddress.streetName}
                          onChange={handleChange}
                          error={
                            getIn(errors, 'businessAddress.streetName') && getIn(touched, 'businessAddress.streetName')
                              ? getIn(errors, 'businessAddress.streetName')
                              : ''
                          }
                        />
                      </div>
                      <div className="lp-w-full w-200 mb-16">
                        <TextField
                          fullWidth
                          label="Street type"
                          inputMode="text"
                          autoComplete="off"
                          testId="businessprofile-update-modal-address-streettype"
                          id="businessAddress.streetType"
                          name="businessAddress.streetType"
                          onBlur={handleBlur}
                          value={values.businessAddress.streetType}
                          onChange={handleChange}
                          error={
                            getIn(errors, 'businessAddress.streetType') && getIn(touched, 'businessAddress.streetType')
                              ? getIn(errors, 'businessAddress.streetType')
                              : ''
                          }
                        />
                      </div>
                      <div className="lp-w-full w-200 mb-16">
                        <TextField
                          fullWidth
                          label="Suburb"
                          inputMode="text"
                          autoComplete="off"
                          testId="businessprofile-update-modal-address-suburb"
                          id="businessAddress.suburb"
                          name="businessAddress.suburb"
                          onBlur={handleBlur}
                          value={values.businessAddress.suburb}
                          onChange={handleChange}
                          error={
                            getIn(errors, 'businessAddress.suburb') && getIn(touched, 'businessAddress.suburb')
                              ? getIn(errors, 'businessAddress.suburb')
                              : ''
                          }
                        />
                      </div>

                      <div
                        className={`lp-w-full w-200 mb-16${
                          getIn(errors, 'businessAddress.city') && getIn(touched, 'businessAddress.city')
                            ? ` has-error`
                            : ``
                        }`}
                      >
                        {values.businessAddress.country === 'NZ' && (
                          <Select
                            label="City"
                            testId="businessprofile-update-modal-address-city"
                            onChange={(value) => {
                              setFieldValue('businessAddress.city', value);
                              setFieldValue('businessAddress.state', null);
                            }}
                            value={values.businessAddress.state ?? ''}
                            options={NewZealandRegions}
                            errorMessage={
                              getIn(errors, 'businessAddress.city') && getIn(touched, 'businessAddress.city')
                                ? getIn(errors, 'businessAddress.city')
                                : ''
                            }
                          />
                        )}
                      </div>
                      <div
                        className={`lp-w-full w-200 mb-16${
                          getIn(errors, 'businessAddress.state') && getIn(touched, 'businessAddress.state')
                            ? ` has-error`
                            : ``
                        }`}
                      >
                        {values.businessAddress.country === 'AU' && (
                          <Select
                            label="State"
                            testId="businessprofile-update-modal-address-state"
                            onChange={(value) => {
                              setFieldValue('businessAddress.state', value);
                              setFieldValue('businessAddress.city', null);
                            }}
                            value={values.businessAddress.state ?? ''}
                            options={AustralianStates}
                            errorMessage={
                              getIn(errors, 'businessAddress.state') && getIn(touched, 'businessAddress.state')
                                ? getIn(errors, 'businessAddress.state')
                                : ''
                            }
                          />
                        )}
                      </div>

                      <div className="lp-w-full w-200 mb-0">
                        <TextField
                          fullWidth
                          label="Postcode"
                          inputMode="text"
                          autoComplete="off"
                          testId="businessprofile-update-modal-address-postalcode"
                          id="businessAddress.postalCode"
                          name="businessAddress.postalCode"
                          onBlur={handleBlur}
                          value={values.businessAddress.postalCode}
                          onChange={handleChange}
                          error={
                            getIn(errors, 'businessAddress.postalCode') && getIn(touched, 'businessAddress.postalCode')
                              ? getIn(errors, 'businessAddress.postalCode')
                              : ''
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
                  <Button
                    size="medium"
                    variant="ghost"
                    className="no-min-width w-70"
                    testId="businessprofile-update-modal-cancel-button"
                    onClick={() => modalToggler(false)}
                    disabled={editRequestLoading}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="medium"
                    variant="primary"
                    className="no-min-width"
                    testId="businessprofile-update-modal-submit-button"
                    disabled={editRequestLoading}
                    isLoading={editRequestLoading}
                  >
                    Save
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </s.Wrapper>
    </Modal>
  );
}

export default EditBusinessDetailsModal;
