import { CustomField } from 'api/merchant/dashboard/merchants.types';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import * as Yup from 'yup';

export const itemFormValidationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Required'),
  quantity: Yup.number()
    .required('Required')
    .typeError('Must be a number')
    .positive('Must be a positive')
    .moreThan(0, 'Must be greater than 0'),
  amount: Yup.number()
    .required('Required')
    .typeError('Must be a number')
    .positive('Must be a positive')
    .moreThan(0, 'Must be greater than 0'),
});

export const orderDetailsValidationSchema = Yup.object().shape({
  orderNumber: Yup.string().trim().required('Required'),
  amount: Yup.number()
    .required('Required')
    .typeError('Must be a number')
    .positive('Must be a positive')
    .moreThan(0, 'Must be greater than 0'),
  customerEmail: Yup.string().trim().required('Required').email('Invalid email'),
  customerPhone: Yup.string().trim().required('Required'),
  customerName: Yup.string().trim().required('Required'),
});

export const getOrderDetailsValidationSchema = (orderCustomFields: CustomField[]) => {
  const customFieldsSchemaObject = Object.fromEntries(
    orderCustomFields.map((field) => [
      field.key,
      field.required ? Yup.string().trim().required('Required') : Yup.string(),
    ]),
  );

  return Yup.object().shape({
    orderNumber: Yup.string().trim().required('Required'),
    amount: Yup.number()
      .required('Required')
      .typeError('Must be a number')
      .positive('Must be a positive')
      .moreThan(0, 'Must be greater than 0'),
    customerEmail: Yup.string().trim().required('Required').email('Invalid email'),
    customerPhone: Yup.string()
      .trim()
      .test({
        name: 'phone-no-validation',
        test: function () {
          const { customerPhone } = this.parent;
          if (customerPhone && !isPossiblePhoneNumber(customerPhone)) {
            return this.createError({
              message: `Invalid phone`,
              path: `customerPhone`,
            });
          }
          return true;
        },
      }),
    customerName: Yup.string().trim().required('Required'),
    customFields: Yup.object().shape(customFieldsSchemaObject),
  });
};
