import { Address } from 'api/fragments';
import * as s from 'assets/styles/Platforms.styles';
import { AustralianStates, NewZealandRegions } from 'config/constants';
import { Formik, FormikHelpers, FormikProps, getIn } from 'formik';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import scrollToComponent from 'react-scroll-to-component';

import { Button, Heading, Message, Modal, Select, Text, TextField } from '@limepayments/cosmic';

import { AddressModalForm, initAddress } from './types';
import { validationSchema } from './validation.schema';

interface Props {
  heading?: string;
  title?: string;
  description?: string;
  address?: Address;
  errorMessage: string;
  isModalOpen: boolean;
  setIsModalOpen: (val: boolean) => void;
  submitHandler: (values: AddressModalForm, formObj: FormikHelpers<AddressModalForm>) => Promise<void>;
}

function AddressModal({
  heading,
  title,
  description,
  address,
  errorMessage,
  isModalOpen,
  setIsModalOpen,
  submitHandler,
}: Props) {
  const errorContainerRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<FormikProps<AddressModalForm>>(null);
  const [initialValues, setInitialValues] = useState<AddressModalForm>(initAddress);

  useEffect(() => {
    if (errorMessage.length > 0 && errorContainerRef && errorContainerRef.current) {
      scrollToComponent(errorContainerRef.current, { duration: 500 });
    }
  }, [errorMessage]);

  useLayoutEffect(() => {
    if (isModalOpen && address) {
      setInitialValues({ address });
    }
  }, [isModalOpen, address]);

  return (
    <Modal isOpen={isModalOpen} className="modal-medium update-pass-modal" testId="address-update-modal">
      <s.Wrapper className="">
        <div className="modal-main p-24">
          {heading && (
            <div className="modal-header">
              <div className="content-main bb-1">
                <Heading alignment="center" tagName="h2" variant="xs" className="mt-12 mb-16 lp-justify-center lp-flex">
                  {heading}
                </Heading>
              </div>
            </div>
          )}

          <Formik<AddressModalForm>
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={submitHandler}
            validationSchema={validationSchema}
            innerRef={formRef}
          >
            {({ values, handleSubmit, errors, touched, setFieldValue, handleChange, handleBlur, isSubmitting }) => (
              <form autoComplete="off" onSubmit={handleSubmit} className="content-wrap-form">
                <div className="modal-content p-28 sm-p-0 sm-mt-24">
                  {errorMessage.length > 0 && (
                    <div className="mt-24 mb-24" ref={errorContainerRef}>
                      <Message
                        type="inline"
                        children={<span className="text-wrap">{errorMessage}</span>}
                        variant={'error'}
                      />
                    </div>
                  )}

                  <div className="lp-w-full mt-24">
                    <div className="lp-w-full mb-16">
                      <Heading alignment="left" tagName="h2" variant="xs">
                        {title}
                      </Heading>
                      {description && (
                        <Text variant="body-3" className="text-dark-50 mt-4">
                          {description}
                        </Text>
                      )}
                    </div>

                    <div className={`lp-w-full w-200 mb-16`}>
                      {
                        <Select
                          label="Country"
                          onChange={(value) => {
                            setFieldValue('address.country', value);
                            setFieldValue('address.state', '');
                          }}
                          options={[
                            {
                              text: 'Australia',
                              value: 'AU',
                            },
                            {
                              text: 'New Zealand',
                              value: 'NZ',
                            },
                          ]}
                          testId="address-update-modal-address-country-select-box"
                          value={values.address.country}
                        />
                      }
                    </div>

                    <div className="lp-w-full w-200 mb-16">
                      <TextField
                        fullWidth
                        label="Unit number (optional)"
                        inputMode="text"
                        autoComplete="off"
                        id="address.unitNumber"
                        name="address.unitNumber"
                        onBlur={handleBlur}
                        value={values.address.unitNumber}
                        onChange={handleChange}
                        testId="address-update-modal-address-unitno"
                        error={
                          getIn(errors, 'address.unitNumber') && getIn(touched, 'address.unitNumber')
                            ? getIn(errors, 'address.unitNumber')
                            : ''
                        }
                      />
                    </div>
                    <div className="lp-w-full w-200 mb-16">
                      <TextField
                        fullWidth
                        label="Street number"
                        inputMode="text"
                        autoComplete="off"
                        id="address.streetNumber"
                        name="address.streetNumber"
                        onBlur={handleBlur}
                        value={values.address.streetNumber}
                        onChange={handleChange}
                        testId="address-update-modal-address-streetnumber"
                        error={
                          getIn(errors, 'address.streetNumber') && getIn(touched, 'address.streetNumber')
                            ? getIn(errors, 'address.streetNumber')
                            : ''
                        }
                      />
                    </div>

                    <div className="lp-w-full w-200 mb-16">
                      <TextField
                        fullWidth
                        label="Street name"
                        inputMode="text"
                        autoComplete="off"
                        id="address.streetName"
                        name="address.streetName"
                        onBlur={handleBlur}
                        value={values.address.streetName}
                        onChange={handleChange}
                        testId="address-update-modal-address-streetname"
                        error={
                          getIn(errors, 'address.streetName') && getIn(touched, 'address.streetName')
                            ? getIn(errors, 'address.streetName')
                            : ''
                        }
                      />
                    </div>
                    <div className="lp-w-full w-200 mb-16">
                      <TextField
                        fullWidth
                        label="Street type"
                        inputMode="text"
                        autoComplete="off"
                        id="address.streetType"
                        name="address.streetType"
                        onBlur={handleBlur}
                        value={values.address.streetType}
                        onChange={handleChange}
                        testId="address-update-modal-address-streettype"
                        error={
                          getIn(errors, 'address.streetType') && getIn(touched, 'address.streetType')
                            ? getIn(errors, 'address.streetType')
                            : ''
                        }
                      />
                    </div>
                    <div className="lp-w-full w-200 mb-16">
                      <TextField
                        fullWidth
                        label="Suburb"
                        inputMode="text"
                        autoComplete="off"
                        id="address.suburb"
                        name="address.suburb"
                        onBlur={handleBlur}
                        value={values.address.suburb}
                        onChange={handleChange}
                        testId="address-update-modal-address-suburb"
                        error={
                          getIn(errors, 'address.suburb') && getIn(touched, 'address.suburb')
                            ? getIn(errors, 'address.suburb')
                            : ''
                        }
                      />
                    </div>

                    <div
                      className={`lp-w-full w-200 mb-16${
                        getIn(errors, 'address.city') && getIn(touched, 'address.city') ? ` has-error` : ``
                      }`}
                    >
                      {values.address.country === 'NZ' && (
                        <Select
                          label="City"
                          onChange={(value) => {
                            setFieldValue('address.city', value);
                            setFieldValue('address.state', null);
                          }}
                          value={values.address.state ?? ''}
                          options={NewZealandRegions}
                          testId="address-update-modal-address-city"
                          errorMessage={
                            getIn(errors, 'address.city') && getIn(touched, 'address.city')
                              ? getIn(errors, 'address.city')
                              : ''
                          }
                        />
                      )}
                    </div>
                    <div
                      className={`lp-w-full w-200 mb-16${
                        getIn(errors, 'address.state') && getIn(touched, 'address.state') ? ` has-error` : ``
                      }`}
                    >
                      {values.address.country === 'AU' && (
                        <Select
                          label="State"
                          onChange={(value) => {
                            setFieldValue('address.state', value);
                            setFieldValue('address.city', null);
                          }}
                          value={values.address.state ?? ''}
                          options={AustralianStates}
                          testId="address-update-modal-address-state"
                          errorMessage={
                            getIn(errors, 'address.state') && getIn(touched, 'address.state')
                              ? getIn(errors, 'address.state')
                              : ''
                          }
                        />
                      )}
                    </div>

                    <div className="lp-w-full w-200 mb-0">
                      <TextField
                        fullWidth
                        label="Postcode"
                        inputMode="text"
                        autoComplete="off"
                        id="address.postalCode"
                        name="address.postalCode"
                        onBlur={handleBlur}
                        value={values.address.postalCode}
                        onChange={handleChange}
                        testId="address-update-modal-address-postalcode"
                        error={
                          getIn(errors, 'address.postalCode') && getIn(touched, 'address.postalCode')
                            ? getIn(errors, 'address.postalCode')
                            : ''
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
                  <Button
                    size="medium"
                    variant="ghost"
                    className="no-min-width w-70"
                    testId="address-update-modal-cancel-button"
                    onClick={() => setIsModalOpen(false)}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="medium"
                    variant="primary"
                    className="no-min-width"
                    testId="address-update-modal-submit-button"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  >
                    Save
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </s.Wrapper>
    </Modal>
  );
}

export default AddressModal;
