import { getMerchant, updateMerchant } from 'api/merchant/dashboard/merchants';
import { getStandardMerchantInitValues } from 'api/merchant/dashboard/merchants.init.values';
import { StandardMerchant } from 'api/merchant/dashboard/merchants.types';
import { FormikProps } from 'formik';
import MerchantDashboardLayout from 'layouts/merchant-dashboard/MerchantDashboardLayout';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/merchant/hooks';
import getErrorMessage from 'utils/getErrorMessage';

import BrandingSettingView from './partials/BrandingSettingView';
import { toUpdateMerchantBrandingRequest, toUpdateMerchantBusinessProfileRequest } from './partials/types';
import { BrandingModalForm } from './partials/types';

function BrandingSettingPage() {
  const [fetchBrandingDetailLoader, setFetchBrandingDetailLoader] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [updateErrorMsg, setUpdateErrorMsg] = useState<string>('');
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [editRequestLoading, setEditRequestLoading] = useState<boolean>(false);
  const [merchantResponse, setMerchantResponse] = useState<StandardMerchant>(() => getStandardMerchantInitValues());

  const formRef = useRef<FormikProps<BrandingModalForm>>(null);
  const [brandEditModalOpen, setBrandEditModalOpen] = useState<boolean>(false);

  const { merchantId } = useAppSelector((state) => ({
    merchantId: state.config.merchantId,
    apiBaseUri: state.config.apiBaseUri,
  }));

  const submitHandler = async (values: BrandingModalForm, formObj: { resetForm: () => void }) => {
    try {
      setEditRequestLoading(true);
      setUpdateErrorMsg('');

      const merchantBusinessProfile = toUpdateMerchantBusinessProfileRequest(values, merchantResponse);
      const merchantBranding = toUpdateMerchantBrandingRequest(values);

      const response = await updateMerchant(merchantId, { ...merchantBranding }).then(() =>
        updateMerchant(merchantId, { ...merchantBusinessProfile }),
      );

      setMerchantResponse(response as StandardMerchant);

      toast.success('Branding details updated successfully');
      formObj.resetForm();
      setBrandEditModalOpen(false);
    } catch (error) {
      setUpdateErrorMsg(getErrorMessage(error));
    } finally {
      setEditRequestLoading(false);
    }
  };

  const fetchMerchant = useCallback(async () => {
    try {
      setFetchBrandingDetailLoader(true);
      setIsPageLoading(true);

      const response = await getMerchant(merchantId);
      setMerchantResponse(response as StandardMerchant);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setFetchBrandingDetailLoader(false);
    }
  }, [merchantId]);

  useEffect(() => {
    if (!merchantId) {
      return;
    }

    setErrorMessage('');
    fetchMerchant();
  }, [merchantId, fetchMerchant]);

  useEffect(() => {
    if (!fetchBrandingDetailLoader) {
      setIsPageLoading(false);
    }
  }, [fetchBrandingDetailLoader, setIsPageLoading]);

  return (
    <MerchantDashboardLayout activeTab="settings" title="Branding Settings">
      <BrandingSettingView
        errorMsg={updateErrorMsg}
        editRequestLoading={editRequestLoading}
        merchantDetails={merchantResponse}
        onSubmitClick={submitHandler}
        formRef={formRef}
        setBrandEditModalOpen={setBrandEditModalOpen}
        brandEditModalOpen={brandEditModalOpen}
        isPageLoading={isPageLoading}
        errorMessage={errorMessage}
      />
    </MerchantDashboardLayout>
  );
}

export default BrandingSettingPage;
