import { Formik } from 'formik';
import { useCallback } from 'react';

import { Button, Text, TextField } from '@limepayments/cosmic';

import { useCreateOrderContext } from './context';
import { ItemForm } from './types';
import { itemFormValidationSchema as validationSchema } from './validation.schema';

interface Props {
  itemIndex: number;
}

function EditItem({ itemIndex }: Props) {
  const { itemList, resetEditMode, updateItem, tradingCurrency } = useCreateOrderContext();

  const handleCancel = useCallback(() => {
    resetEditMode();
  }, [resetEditMode]);

  const onSubmitClick = useCallback(
    (values: ItemForm) => {
      updateItem(itemIndex, {
        name: values.name,
        amount: Number(values.amount),
        quantity: Number(values.quantity),
        editMode: false,
      });
    },
    [itemIndex, updateItem],
  );

  return (
    <tr>
      <td colSpan={4}>
        <Formik<ItemForm>
          enableReinitialize={true}
          initialValues={itemList[itemIndex]}
          onSubmit={(values, formObj: { resetForm: () => void }) => {
            onSubmitClick(values);
          }}
          validationSchema={validationSchema}
        >
          {({ values, handleSubmit, errors, touched, handleChange, handleBlur }) => (
            <form autoComplete="off" onSubmit={handleSubmit} className="content-wrap-form">
              <div className="add-new-item">
                <Text tagName="p" variant="body-2" className="lp-neutral-600 mt-0 mb-16" isEmphasised>
                  Edit item
                </Text>
                <div className="two-col mb-16">
                  <div className="lp-w-half white-bg">
                    <TextField
                      id="name"
                      name="name"
                      fullWidth
                      containerId="standard"
                      label="Item name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.name && touched.name ? errors.name : ''}
                      testId="create-neworder-modal-edititemname-input"
                    />
                  </div>
                  <div className="lp-w-half white-bg">
                    <TextField
                      id="amount"
                      name="amount"
                      fullWidth
                      containerId="standard"
                      label={`Item cost (${tradingCurrency})`}
                      value={values.amount ? values.amount.toString() : ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.amount && touched.amount ? errors.amount : ''}
                      startAdornment="Filled-Dollar"
                      testId="create-neworder-modal-edititemamount-input"
                    />
                  </div>
                </div>
                <div className="two-col">
                  <div className="lp-w-half white-bg">
                    <TextField
                      id="quantity"
                      name="quantity"
                      fullWidth
                      containerId="standard"
                      label="Quantity"
                      value={values.quantity ? values.quantity.toString() : ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.quantity && touched.quantity ? errors.quantity : ''}
                      testId="create-neworder-modal-edititemquantity-input"
                    />
                  </div>
                  <div className="lp-w-half">
                    <div className="lp-flex h-58px">
                      <Button
                        type="button"
                        className="no-min-width w-70 mt-12"
                        size="medium"
                        variant="ghost"
                        onClick={handleCancel}
                        testId="create-neworder-modal-edititem-cancel-button"
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        className="no-min-width w-70 mt-12"
                        size="medium"
                        variant="primary"
                        testId="create-neworder-modal-edititem-submit-button"
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </td>
    </tr>
  );
}

export default EditItem;
