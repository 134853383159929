import { ErrorMessage, Formik, getIn } from 'formik';
import numeral from 'numeral';
import { Fragment, useCallback, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import { formatCurrencyWithCents } from 'utils/currency';
import { getInclusiveGstAmount } from 'utils/taxCalculations';

import { Button, Heading, TextField } from '@limepayments/cosmic';

import OrderItemsTable from './OrderItemsTable';
import { useCreateOrderContext } from './context';
import { ModalSteps, OrderDetailsForm } from './types';
import { getOrderDetailsValidationSchema } from './validation.schema';

function NewOrderDetails() {
  const {
    itemList,
    tradingCurrency,
    tradingCountry,
    orderDetails,
    setActiveStep,
    setOrderDetails,
    orderCustomFields,
    modalToggler,
  } = useCreateOrderContext();

  const [isAmountDisabled, setIsAmountDisabled] = useState<boolean>(false);
  const [orderAmount, setOrderAmount] = useState<number>(0);

  useEffect(() => {
    if (itemList && itemList.length > 0) {
      setIsAmountDisabled(true);
      setOrderAmount(
        itemList.reduce((total, item) => numeral(item.amount).multiply(item.quantity).add(total).value() ?? 0, 0),
      );
    } else {
      setIsAmountDisabled(false);
      setOrderAmount(orderDetails.amount);
    }
  }, [itemList, orderDetails.amount]);

  const calculateGstAmount = useCallback(
    (totalAmount: number) => getInclusiveGstAmount(totalAmount, tradingCountry),
    [tradingCountry],
  );

  return (
    <div className="modal-main p-24">
      <Fragment>
        <Formik<OrderDetailsForm>
          enableReinitialize={true}
          initialValues={{
            orderNumber: orderDetails?.orderNumber || '',
            amount: orderAmount || 0,
            description: orderDetails?.description || '',
            customerEmail: orderDetails?.customerEmail || '',
            customerPhone: orderDetails?.customerPhone || '',
            customerName: orderDetails?.customerName || '',
            customFields: Object.fromEntries(orderDetails.customFields.map((field) => [`${field.key}`, field.value])),
          }}
          onSubmit={(values, formObj: { resetForm: () => void }) => {
            setOrderDetails({
              ...values,
              currency: tradingCurrency,
              customFields: Object.entries(values.customFields).map(([key, value]) => ({ key, value })),
              gstAmount: calculateGstAmount(values.amount),
            });
            formObj.resetForm();
            setActiveStep(ModalSteps.ConfirmOrder);
          }}
          validationSchema={getOrderDetailsValidationSchema(orderCustomFields)}
        >
          {({ values, handleSubmit, errors, touched, handleChange, handleBlur, setFieldValue }) => (
            <form autoComplete="off" onSubmit={handleSubmit} className="content-wrap-form">
              <div className="modal-header">
                <div className="content-main bb-1">
                  <Heading
                    alignment="center"
                    tagName="h2"
                    variant="xs"
                    className="mt-12 mb-24 lp-justify-center lp-flex"
                  >
                    Order details
                  </Heading>
                </div>
              </div>
              <div className="modal-body h90vh p-28 sm-p-0 sm-mt-24">
                <div className="two-col mb-16">
                  <div className="lp-w-half white-bg">
                    <TextField
                      id="orderNumber"
                      name="orderNumber"
                      fullWidth
                      containerId="standard"
                      label="Order number"
                      value={values.orderNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.orderNumber && touched.orderNumber ? errors.orderNumber : ''}
                      testId="create-neworder-modal-ordernumber-input"
                    />
                  </div>
                  <div className="lp-w-half white-bg">
                    <TextField
                      id="amount"
                      name="amount"
                      disabled={isAmountDisabled}
                      fullWidth
                      containerId="standard"
                      label="Order amount"
                      value={
                        values.amount
                          ? isAmountDisabled
                            ? formatCurrencyWithCents(values.amount)
                            : values.amount.toString()
                          : ''
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.amount && touched.amount ? errors.amount : ''}
                      startAdornment="Filled-Dollar"
                      testId="create-neworder-modal-orderamount-input"
                    />
                  </div>
                </div>
                <div className="lp-w-full white-bg mb-16">
                  <TextField
                    id="description"
                    name="description"
                    fullWidth
                    containerId="standard"
                    label="Description (optional)"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.description && touched.description ? errors.description : ''}
                    testId="create-neworder-modal-orderdescription-input"
                  />
                </div>

                <div className="lp-w-full white-bg mb-16">
                  <TextField
                    id="customerEmail"
                    name="customerEmail"
                    fullWidth
                    containerId="standard"
                    label="Customer email"
                    value={values.customerEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.customerEmail && touched.customerEmail ? errors.customerEmail : ''}
                    testId="create-neworder-modal-customeremail-input"
                  />
                </div>

                <div
                  className={`lp-w-full w-200 white-bg mb-16${
                    errors.customerPhone && touched.customerPhone ? ` has-error` : ``
                  }`}
                >
                  <div className="form-floating no-pl">
                    <PhoneInput
                      id="customerPhone"
                      name="customerPhone"
                      defaultCountry={tradingCountry}
                      value={values.customerPhone ?? ''}
                      onChange={(e) => {
                        setFieldValue('customerPhone', e);
                      }}
                      onBlur={handleBlur}
                      className={`${values.customerPhone ? `is-filled` : ``}`}
                      countries={['AU', 'NZ']}
                      error={errors.customerPhone && touched.customerPhone ? errors.customerPhone : ''}
                      data-testid="create-neworder-modal-phonenumber-input"
                    />
                    <label htmlFor="floatingPhoneInput">Customer phone (optional)</label>
                  </div>
                  <ErrorMessage name="customerPhone" component="span" className="Mui-error" />
                </div>

                <div className="lp-w-full white-bg mb-16">
                  <TextField
                    id="customerName"
                    name="customerName"
                    fullWidth
                    containerId="standard"
                    label="Customer name"
                    value={values.customerName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.customerName && touched.customerName ? errors.customerName : ''}
                    testId="create-neworder-modal-customername-input"
                  />
                </div>

                {orderCustomFields &&
                  orderCustomFields.length > 0 &&
                  orderCustomFields.map((fields) => (
                    <div key={`customFields.${fields.key}`} className="lp-w-full white-bg mb-16">
                      <TextField
                        id={`customFields.${fields.key}`}
                        name={`customFields.${fields.key}`}
                        fullWidth
                        containerId="standard"
                        label={`${fields.label}${!fields.required ? ' (optional)' : ''}`}
                        value={values.customFields ? values.customFields[fields.key] : ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          getIn(errors.customFields, fields.key) && getIn(touched.customFields, fields.key)
                            ? getIn(errors.customFields, fields.key)
                            : ''
                        }
                        testId={`create-neworder-modal-${fields.required ? 'required' : 'optional'}-customfield-input`}
                      />
                    </div>
                  ))}

                {itemList && itemList.length > 0 && (
                  <Fragment>
                    <div className="separator"></div>
                    <div className="lp-w-full">
                      <OrderItemsTable allowActions={false} showEditButton={true} />
                    </div>
                  </Fragment>
                )}
              </div>
              <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
                <Fragment>
                  <Button
                    onClick={() => modalToggler(false)}
                    type="button"
                    size="medium"
                    variant="ghost"
                    className="no-min-width"
                    testId="create-neworder-modal-cancel-button"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    onClick={() => setActiveStep(ModalSteps.ManageOrderItems)}
                    size="medium"
                    variant="primary"
                    className="no-min-width w-70"
                    testId="create-neworder-modal-back-button"
                  >
                    Back
                  </Button>
                  <Button
                    type="submit"
                    size="medium"
                    variant="primary"
                    className="no-min-width"
                    testId="create-neworder-modal-next-button"
                  >
                    Next
                  </Button>
                </Fragment>
              </div>
            </form>
          )}
        </Formik>
      </Fragment>
    </div>
  );
}

export default NewOrderDetails;
