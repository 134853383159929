import { handleResponse } from 'api/utils';
import { getAprilApiHost } from 'utils/env';
import getFetchOptions from 'utils/getFetchOptions';

import {
  AnyMerchant,
  MerchantGeneralSettingsResponse,
  UpdateMerchantRequest,
  handleAnyMerchant,
} from './merchants.types';

export const updateMerchant = async (
  merchantId: string,
  payload: UpdateMerchantRequest,
  bearerToken?: string,
): Promise<AnyMerchant> => {
  const options = await getFetchOptions('PATCH', { payload, bearerToken });
  return await fetch(`${await getAprilApiHost()}/merchants/${merchantId}`, options)
    .then(handleResponse)
    .then(handleAnyMerchant);
};

export const getMerchant = async (merchantId: string, bearerToken?: string): Promise<AnyMerchant> => {
  const options = await getFetchOptions('GET', { bearerToken });
  return await fetch(`${await getAprilApiHost()}/merchants/${merchantId}`, options)
    .then(handleResponse)
    .then(handleAnyMerchant);
};

export const getMerchantGeneralSettings = async (
  merchantId: string,
  bearerToken?: string,
): Promise<MerchantGeneralSettingsResponse> => {
  const options = await getFetchOptions('GET', { bearerToken });
  return await fetch(`${await getAprilApiHost()}/merchants/${merchantId}/settings`, options).then(handleResponse);
};
