import { OrderStatus, OrdersQueryQuery } from 'api/graphql/generated/graphql';
import { AmountFilterValue, DateFilterValue, SearchFilterOption } from 'pages/common/filters';

export interface IVirtualFilterObj {
  amountChecked: boolean;
  amount: AmountFilterValue | null;
  orderIdChecked: boolean;
  orderId: SearchFilterOption | null;
  customerChecked: boolean;
  customer: SearchFilterOption | null;
  descriptionChecked: boolean;
  description: SearchFilterOption | null;
  requestedOnChecked: boolean;
  requestedOn: DateFilterValue | null;
  statusChecked: boolean;
  status: OrderStatus[] | null;
}

export const getFilterCount = (filterObj: IVirtualFilterObj) =>
  [
    filterObj.amountChecked,
    filterObj.orderIdChecked,
    filterObj.customerChecked,
    filterObj.descriptionChecked,
    filterObj.requestedOnChecked,
    filterObj.statusChecked,
  ].filter((value) => value).length;

export interface IVirtualFilterObjError {
  amountError: string;
  orderIdError: string;
  customerError: string;
  descriptionError: string;
  requestedOnError: string;
  statusError: string;
}

export const hasFilterError = (filterObjError: IVirtualFilterObjError) =>
  !!filterObjError.amountError ||
  !!filterObjError.customerError ||
  !!filterObjError.descriptionError ||
  !!filterObjError.orderIdError ||
  !!filterObjError.requestedOnError ||
  !!filterObjError.statusError;

export interface IListingPagePropsObj {
  page: number;
  last: number | null;
  before: string | null;
  first: number | null;
  after: string | null;
}

export type OrdersQueryEdges = NonNullable<OrdersQueryQuery['orders']>['edges'];
