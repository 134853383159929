import numeral from 'numeral';
import { CountryType } from 'redux/merchant/slice/configSlice';

export const getInclusiveGstAmount = (amount: number, country: CountryType): number => {
  /**
   * for general merchants.
   * in NZ, GST is 3/23 of total amount
   */
  return country === 'AU'
    ? numeral(amount).divide(11).value() ?? 0
    : numeral(amount).multiply(3).divide(23).value() ?? 0;
};
