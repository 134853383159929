import ErrorPage from 'pages/common/error';
import PaymentRoutes from 'pages/merchant/dashboard/payment/PaymentRoutes';
import SettingRoutes from 'pages/merchant/dashboard/setting/SettingRoutes';
import { Route, Routes } from 'react-router-dom';
import { useAppSelector } from 'redux/merchant/hooks';

import DashboardRoutesList from './constants';
import PayoutRoutes from './payout/PayoutRoutes';
import VirtualTerminalRoutes from './virtual-terminal/VirtualTerminalRoutes';

const DashboardRoutes = () => {
  const { merchantType } = useAppSelector((state) => ({
    merchantType: state.config.merchantType,
  }));

  return (
    <Routes>
      <Route index element={<PaymentRoutes />} />
      <Route path={`/${DashboardRoutesList.PAYMENT_ROUTE}/*`} caseSensitive={true} element={<PaymentRoutes />} />
      <Route path={`/${DashboardRoutesList.PAYOUT_ROUTE}/*`} caseSensitive={true} element={<PayoutRoutes />} />
      {merchantType === 'StandardMerchant' && (
        <Route
          path={`/${DashboardRoutesList.VIRTUAL_TERMINAL_ROUTE}/*`}
          caseSensitive={true}
          element={<VirtualTerminalRoutes />}
        />
      )}
      <Route path={`/${DashboardRoutesList.SETTINGS_ROUTE}/*`} caseSensitive={true} element={<SettingRoutes />} />
      {/* Fallback - Not Found */}
      <Route
        path={`*`}
        element={<ErrorPage bodyTitle="Page Not Found" bodyText="The page you are trying to access doesn't exists" />}
      />
    </Routes>
  );
};

export default DashboardRoutes;
