import { merchantPaymentCancel } from 'api/merchant/dashboard/payments';
import * as s from 'assets/styles/Platforms.styles';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/merchant/hooks';
import { Currency, toCurrency } from 'utils/currency';
import getErrorMessage from 'utils/getErrorMessage';

import { Button, Heading, Illustration, Modal, Text } from '@limepayments/cosmic';

interface Props {
  isOpen: boolean;
  modalToggler: (isOpen: boolean) => void;
  reloadPaymentDetails: () => void;
  cancelAmount: number;
  transactionId: string;
  currency: Currency;
  customerName: string;
}

function CancelPaymentModalComponent({
  isOpen,
  modalToggler,
  reloadPaymentDetails,
  cancelAmount,
  transactionId,
  customerName,
  currency,
}: Props) {
  const [cancelPaymentRequestLoader, setCancelPaymentRequestLoader] = useState<boolean>(false);

  const { merchantId, apiBaseUri } = useAppSelector((state) => ({
    merchantId: state.config.merchantId,
    apiBaseUri: state.config.apiBaseUri,
  }));

  const cancelPaymentRequestHandler = async () => {
    try {
      setCancelPaymentRequestLoader(true);

      await merchantPaymentCancel(apiBaseUri, merchantId, transactionId);

      toast.success(
        `You have successfully canceled ${customerName}’s payment of ${toCurrency(
          cancelAmount,
          currency,
        )} ${currency}.`,
      );

      modalToggler(false);
      reloadPaymentDetails();
    } catch (err: any) {
      toast.error(getErrorMessage(err));
      modalToggler(false);
    } finally {
      setCancelPaymentRequestLoader(false);
    }
  };

  useEffect(() => {
    setCancelPaymentRequestLoader(false);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} className="modal-medium update-pass-modal">
      <s.Wrapper className="">
        <div className="modal-main p-24">
          <div className="modal-header">
            <div className="content-main bb-1">
              <Heading alignment="center" tagName="h2" variant="xxs" className="mt-12 mb-24 lp-justify-center lp-flex">
                Cancel payment
              </Heading>
            </div>
          </div>
          <form autoComplete="off">
            <div className="modal-content p-28 sm-p-0 sm-mt-24">
              <div className="">
                <div className="lp-w-full lp-flex mb-24">
                  <div className="lp-w-full lp-flex lp-flex-column lp-align-center lp-justify-center">
                    <div className="illustration-90">
                      <Illustration name="Warning" size="medium" />
                    </div>
                    <div className="w-75">
                      <Text alignment="center" tagName="p" variant="body-3" className="text-dark-50 mb-0">
                        Are you sure you want to cancel this payment of{' '}
                        <strong>{toCurrency(cancelAmount, currency)}</strong> {currency} for{' '}
                        <strong>{customerName}</strong>?
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
              <Button
                onClick={() => modalToggler(false)}
                type="button"
                size="medium"
                variant="ghost"
                className="no-min-width"
                disabled={cancelPaymentRequestLoader ? true : false}
              >
                Cancel
              </Button>
              <Button
                onClick={cancelPaymentRequestHandler}
                type="button"
                size="medium"
                variant="destructive"
                className="no-min-width"
                disabled={cancelPaymentRequestLoader ? true : false}
                testId="pre-auth-cancel-modal-submit-btn"
              >
                Cancel payment
              </Button>
            </div>
          </form>
        </div>
      </s.Wrapper>
    </Modal>
  );
}

export default CancelPaymentModalComponent;
