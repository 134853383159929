/** @jsxImportSource @emotion/react */

import { OrderAPIResponseType } from 'api/merchant/dashboard/orders.types';
import {
  PaymentAPIResponseType,
  PaymentSourceVerificationAPIResponseType,
} from 'api/merchant/dashboard/payments.types';
import * as s from 'assets/styles/Platforms.styles';
import { DateTime } from 'luxon';
import { Metadata } from 'pages/common/Metadata';
import ErrorComponent from 'pages/common/error/ErrorComponent';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { toCurrency } from 'utils/currency';
import { capitalizeFirstLowercaseRest } from 'utils/stringUtils';

import { Button, Heading, Icon, Spinner, Text } from '@limepayments/cosmic';

import { DigitalWalletTypes, PaymentListStatusObj, PaymentSourceVerificationStatus } from '../constants';
import ErrorFetchPaymentListComponent from '../listing/partials/ErrorFetchPaymentListComponent';
import StatusTag from '../listing/partials/StatusTagComponent';
import CancelPaymentModalComponent from './partials/CancelPaymentModalComponent';
import CapturePaymentModalComponent from './partials/CapturePaymentModalComponent';
import ManualVerificationModal from './partials/ManualVerificationModal';
import RefundModalComponent from './partials/RefundModalComponent';

const Address = (props: { address?: NonNullable<OrderAPIResponseType['billing']>['address'] }) => {
  const address = useMemo(() => {
    if (!props.address) return null;

    const { line1, line2, city, state, postalCode, country } = props.address;

    return [line1, line2, city, state, postalCode, country].filter(Boolean);
  }, [props.address]);

  return <>{address?.length ? address.map((line, key) => (line ? <div key={key}>{line}</div> : null)) : '-'}</>;
};

export interface Props {
  transactionId: string | null;
  onBackButtonClick: () => void;
  reloadPaymentDetails: () => void;
  setPaymentSourceVerificationObj: (
    paymentSourceVerificationObj: PaymentSourceVerificationAPIResponseType | null,
  ) => void;
  detailErrorMsg: string;
  showDetailLoader: boolean;
  paymentDetailObj: PaymentAPIResponseType | null;
  paymentSourceVerificationObj: PaymentSourceVerificationAPIResponseType | null;
  orderDetailObj: OrderAPIResponseType | null;
  sourceVerificationEnabled: boolean;
  showRefundModal?: boolean;
  showPaymentCancelModal?: boolean;
  showPaymentCaptureModal?: boolean;
  showManualVerifyModal?: boolean;
}

function PaymentDetails({
  transactionId,
  onBackButtonClick,
  reloadPaymentDetails,
  setPaymentSourceVerificationObj,
  detailErrorMsg,
  showDetailLoader,
  paymentDetailObj,
  paymentSourceVerificationObj,
  orderDetailObj,
  sourceVerificationEnabled,
  showRefundModal,
  showPaymentCancelModal,
  showPaymentCaptureModal,
  showManualVerifyModal,
}: Props) {
  const [refundModalOpen, setRefundModalOpen] = useState<boolean>(showRefundModal ?? false);
  const [cancelPaymentModalOpen, setCancelPaymentModalOpen] = useState<boolean>(showPaymentCancelModal ?? false);
  const [capturePaymentModalOpen, setCapturePaymentModalOpen] = useState<boolean>(showPaymentCaptureModal ?? false);
  const [verifyManuallyModalOpen, setVerifyManuallyModalOpen] = useState<boolean>(showManualVerifyModal ?? false);

  const refundModalToggler = (value: boolean) => {
    setRefundModalOpen(value);
  };

  const cancelPaymentModalToggler = (value: boolean) => {
    setCancelPaymentModalOpen(value);
  };

  const capturePaymentModalToggler = (value: boolean) => {
    setCapturePaymentModalOpen(value);
  };

  useEffect(() => {
    if (!showDetailLoader && paymentDetailObj) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [showDetailLoader, paymentDetailObj]);

  const outcome3dsInfo = useMemo(() => {
    if (paymentDetailObj?.paymentMethod?.paymentMethodType !== 'Card') return null;

    const outcome3ds: { [key: string]: { info?: string; message?: string } } | null =
      paymentDetailObj.paymentMethod.outcome3ds ?? null;

    if (!outcome3ds) return null;

    const key = Object.keys(outcome3ds)[0];
    const info = outcome3ds[key].info || outcome3ds[key].message || '';

    return `${key}${info ? `: ${info}` : ''}`;
  }, [paymentDetailObj]);

  // TODO: timeline
  // const [isOpened, setIsOpened] = useState(false);
  // const [isActive, setActive] = useState(false);
  // function toggleDetails() {
  //   setActive(!isActive);
  //   setIsOpened((wasOpened) => !wasOpened);
  // }

  return (
    <>
      <Helmet>
        <title>Payment Detail</title>
      </Helmet>
      <div className="content-wrap-inner lp-full">
        <div className="header-back mb-28">
          <Button onClick={onBackButtonClick} variant="ghost" size={'small'} testId="payment-detail-back-button">
            <Icon name="ArrowLeft" className="mr-8" />
            Back
          </Button>
        </div>

        {!showDetailLoader && detailErrorMsg.length ? (
          <>
            <ErrorComponent bodyText={detailErrorMsg} />
            <ErrorFetchPaymentListComponent
              title="Unable to load payment details"
              body={`Sorry, we're unable to load the details for this payment at the moment, please try again later.`}
            />
          </>
        ) : null}

        {showDetailLoader && (
          <div className="spinner-wrapper">
            <Spinner variant="simple" isVisible label="Loading..." />
          </div>
        )}

        {!showDetailLoader && !detailErrorMsg.length && paymentDetailObj && (
          <>
            <Heading alignment="left" tagName="h2" variant="xxs" className="text-muted mb-4">
              Payment
            </Heading>
            <div className="header-details">
              <div className="lp-flex lp-flex-column">
                <div className="header-details-inner">
                  <Heading alignment="left" tagName="h2" variant="sm" className="mb-0 mr-16">
                    <span>
                      {toCurrency(
                        paymentDetailObj.purchaseDetails.amount.minorCurrencyUnits,
                        paymentDetailObj.purchaseDetails.amount.currency,
                      )}{' '}
                      {paymentDetailObj.purchaseDetails.amount.currency}
                    </span>
                  </Heading>
                  <StatusTag
                    label={
                      PaymentListStatusObj[paymentDetailObj.purchaseDetails.status as keyof typeof PaymentListStatusObj]
                        .label
                    }
                    variant={
                      PaymentListStatusObj[paymentDetailObj.purchaseDetails.status as keyof typeof PaymentListStatusObj]
                        .varient
                    }
                    tooltipDirection="up"
                    tooltipText={
                      PaymentListStatusObj[paymentDetailObj.purchaseDetails.status as keyof typeof PaymentListStatusObj]
                        .tooltipText
                    }
                  />
                </div>
              </div>
              <div className="lp-flex sm-flex-col sm-w-full">
                <div className="stacked-data">
                  <Text tagName="p" variant="caption" className="text-dark-50 m-0">
                    Order ID
                  </Text>
                  <Text
                    tagName="p"
                    variant="body-3"
                    className="text-dark m-0 mt-0"
                    testId="payment-detail-order-id-value"
                    isEmphasised
                  >
                    {paymentDetailObj.purchaseDetails.referenceOrderId || paymentDetailObj.purchaseDetails.orderId}
                  </Text>
                </div>
                <div className="stacked-data">
                  <Text tagName="p" variant="caption" className="text-dark-50 m-0">
                    Customer
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0" isEmphasised>
                    {paymentDetailObj.customerDetails?.customerName ?? '-'}
                  </Text>
                </div>
                <div className="stacked-data">
                  <Text tagName="p" variant="caption" className="text-dark-50 m-0">
                    Ordered on
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0" isEmphasised>
                    {DateTime.fromISO(paymentDetailObj.purchaseDetails.createdAt).toFormat('dd/LL/yyyy, HH:mm')}
                  </Text>
                </div>
                <div className="stacked-data">
                  <Text tagName="p" variant="caption" className="text-dark-50 m-0">
                    Available on
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0" isEmphasised>
                    {paymentDetailObj.purchaseDetails.paidOn
                      ? DateTime.fromISO(paymentDetailObj.purchaseDetails.paidOn).toFormat('dd/LL/yyyy, HH:mm')
                      : '-'}
                  </Text>
                </div>
              </div>
              <div className="lp-flex order-2 position-relative ml-auto merchant-controls">
                {paymentDetailObj.purchaseDetails.status !== 'refunded' &&
                  paymentDetailObj.purchaseDetails.status !== 'failed' &&
                  paymentDetailObj.purchaseDetails.status !== 'not_captured' &&
                  paymentDetailObj.purchaseDetails.status !== 'incomplete' &&
                  paymentDetailObj.purchaseDetails.status !== 'cancelled' && (
                    <Button
                      className="lp-w-full"
                      size="small"
                      variant="reversed"
                      testId="payment-refund-button"
                      onClick={() => refundModalToggler(!refundModalOpen)}
                    >
                      <Icon name="Return" className="ml-0 mr-8" />
                      Refund
                    </Button>
                  )}
                {paymentDetailObj.purchaseDetails.status === 'not_captured' && (
                  <>
                    <Button
                      onClick={() => capturePaymentModalToggler(!capturePaymentModalOpen)}
                      className="lp-w-full lp-nowrap"
                      size="small"
                      variant="reversed"
                      testId="payment-pre-auth-capture-button"
                    >
                      Capture payment
                    </Button>
                    <Button
                      onClick={() => cancelPaymentModalToggler(!cancelPaymentModalOpen)}
                      className="lp-w-full lp-nowrap"
                      size="small"
                      variant="destructive"
                      testId="payment-pre-auth-cancel-button"
                    >
                      Cancel payment
                    </Button>
                  </>
                )}
              </div>
            </div>
            {paymentDetailObj.timelines?.length && (
              <s.MerchantsData>
                <Heading alignment="left" tagName="h2" variant="xxs" className="mb-16">
                  Payment timeline
                </Heading>
                <div className="lp-half payment-timeline">
                  {/* TODO
                  <div className="lp-flex mb-28">
                    <div className="icon-back mr-24">
                      <Icon name="ArrowLeft" />
                    </div>
                    <div className="lp-flex lp-flex-column payment-timeline-details">
                      <Text tagName="p" variant="body-2" isEmphasised className="text-dark-50 mb-0">
                        790.00 AUD refunded
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark-50 mt-0 mb-8">
                        Reason for refund goes here. It may take 5-10 business days for funds to settle.
                      </Text>
                      <Text tagName="p" variant="caption" className="text-dark-50 mt-0">
                        15 May 2020, 10:23
                      </Text>
                      <div className="lp-w-full mt-12">
                        <button
                          type="button"
                          className={`lp-flex lp-flex-row lp-align-center btn-details ${isActive ? `is-opened` : ``}`}
                          onClick={toggleDetails}
                        >
                          <Icon name="ChevronUp" className="text-purple" />
                          <Text variant="legal" className="link-details">
                            Details
                          </Text>
                        </button>

                        {isOpened && (
                          <div className="lp-w-full lp-flex lp-justify-between mt-16 ">
                            <Text tagName="p" variant="body-3" className="text-dark-50 mt-0">
                              Refund ID
                            </Text>
                            <Text tagName="p" variant="body-3" className="text-dark1 mt-0">
                              3984723984303
                            </Text>
                          </div>
                        )}
                      </div>
                    </div>
                  </div> */}
                  {paymentDetailObj.timelines.map((timeline, key) => {
                    const isSuccess = ['PaymentSucceed', 'PaymentAuthorised', 'PaymentAccepted'].includes(
                      timeline.eventType,
                    );

                    return (
                      <div
                        key={key}
                        className={`lp-flex lp-flex-row mb-28 ${
                          key < (paymentDetailObj.timelines?.length ?? 0) - 1 ? 'mb-28' : ''
                        }`}
                      >
                        <div className={isSuccess ? 'icon-check mr-24' : 'icon-back mr-24'}>
                          <Icon name={isSuccess ? 'Tick' : 'ArrowLeft'} />
                        </div>
                        <div className="lp-flex lp-flex-column payment-timeline-details">
                          <Text tagName="p" variant="body-2" isEmphasised className="text-dark-50">
                            {timeline.amount !== undefined && (
                              <>
                                {toCurrency(timeline.amount, paymentDetailObj.purchaseDetails.amount.currency)}{' '}
                                {paymentDetailObj.purchaseDetails.amount.currency}{' '}
                              </>
                            )}
                            {timeline.eventType.split(/(?=[A-Z])/).join(' ')}
                          </Text>
                          <Text tagName="p" variant="caption" className="text-dark-50">
                            {DateTime.fromISO(timeline.eventTime).toFormat('dd LLL yyyy, HH:mm')}
                          </Text>
                        </div>
                      </div>
                    );
                  })}
                  {/* TODO
                  <div className="lp-flex mb-0">
                    <div className="icon-back icon-dot bg-sky-500 mr-24">
                      <span className="dot-8"></span>
                    </div>
                    <div className="lp-flex lp-flex-column payment-timeline-details">
                      <Text tagName="p" variant="body-2" isEmphasised className="text-dark-50 mb-0">
                        Payment authorised
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark-50 mt-0 mb-8">
                        The payment has been authorised but the funds are not yet captured.
                      </Text>
                      <Text tagName="p" variant="caption" className="text-dark-50 mt-0">
                        15 May 2020, 10:23
                      </Text>
                    </div>
                  </div> */}
                </div>
              </s.MerchantsData>
            )}

            <s.MerchantsData>
              <Heading alignment="left" tagName="h2" variant="xxs" className="mb-16">
                Payment method
              </Heading>

              {paymentDetailObj.paymentMethod?.paymentMethodType === 'Card' && (
                <div className="lp-half lp-flex two-column-block">
                  <ul className="two-column">
                    <li className="">
                      <Text tagName="p" variant="body-3" className="text-dark-50">
                        Card number
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                        **** **** {paymentDetailObj.paymentMethod.cardLast4}
                      </Text>
                    </li>
                    <li className="">
                      <Text tagName="p" variant="body-3" className="text-dark-50">
                        Card expiry date
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                        {paymentDetailObj.paymentMethod.cardExpiryDate}
                      </Text>
                    </li>
                    <li className="">
                      <Text tagName="p" variant="body-3" className="text-dark-50">
                        Card brand
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                        {capitalizeFirstLowercaseRest(paymentDetailObj.paymentMethod.cardBrand ?? '-')}
                      </Text>
                    </li>
                    <li className="">
                      <Text tagName="p" variant="body-3" className="text-dark-50">
                        Card type
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                        {capitalizeFirstLowercaseRest(paymentDetailObj.paymentMethod.cardFunding ?? '-')}
                      </Text>
                    </li>
                    {paymentDetailObj.paymentMethod.walletType && (
                      <li className="">
                        <Text tagName="p" variant="body-3" className="text-dark-50">
                          Digital wallet
                        </Text>
                        <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                          {DigitalWalletTypes[paymentDetailObj.paymentMethod.walletType].label}
                        </Text>
                      </li>
                    )}
                  </ul>

                  <ul className="two-column">
                    <li className="">
                      <Text tagName="p" variant="body-3" className="text-dark-50">
                        Issuing bank
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                        {paymentDetailObj.paymentMethod.cardIssuer ?? '-'}
                      </Text>
                    </li>
                    <li className="">
                      <Text tagName="p" variant="body-3" className="text-dark-50">
                        Issuing country
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                        {paymentDetailObj.paymentMethod.cardCountry ?? '-'}
                      </Text>
                    </li>
                    <li className="lp-align-center">
                      <Text tagName="p" variant="body-3" className="text-dark-50">
                        CVV check
                      </Text>
                      <Text
                        css={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 4 }}
                        tagName="p"
                        variant="body-3"
                        className="text-dark m-0 mt-0"
                      >
                        {paymentDetailObj.paymentMethod.cardCVCVerificationPassed ? (
                          <>
                            <span style={{ color: 'rgb(var(--lp-colors-success-600))' }}>
                              <Icon name={'Tick'} />
                            </span>
                            Successful
                          </>
                        ) : (
                          <>
                            <span style={{ color: 'rgb(var(--lp-colors-neutral-500))' }}>
                              <Icon name={'Error'} />
                            </span>
                            Unverified
                          </>
                        )}
                      </Text>
                    </li>
                    <li className="lp-align-center">
                      <Text tagName="p" variant="body-3" className="text-dark-50">
                        3DS
                      </Text>
                      <Text
                        css={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 4 }}
                        tagName="p"
                        variant="body-3"
                        className="text-dark m-0 mt-0"
                      >
                        {paymentDetailObj.paymentMethod.card3DSVerificationPassed ? (
                          <>
                            <span style={{ color: 'rgb(var(--lp-colors-success-600))' }}>
                              <Icon name={'Tick'} />
                            </span>
                            Passed
                          </>
                        ) : (
                          <>
                            <span style={{ color: 'rgb(var(--lp-colors-neutral-500))' }}>
                              <Icon name={'Error'} />
                            </span>
                            Unverified
                          </>
                        )}
                      </Text>
                    </li>

                    {outcome3dsInfo && (
                      <li className="lp-align-center">
                        <Text tagName="p" variant="body-3" className="text-dark-50">
                          3DS outcome
                        </Text>
                        <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                          {outcome3dsInfo}
                        </Text>
                      </li>
                    )}

                    {sourceVerificationEnabled && !paymentDetailObj.paymentMethod.walletType && (
                      <li className="lp-align-center">
                        <Text tagName="p" variant="body-3" className="text-dark-50">
                          Manual verification
                        </Text>

                        <Text
                          css={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 4 }}
                          tagName="p"
                          variant="body-3"
                          className="text-dark m-0 mt-0"
                        >
                          <span
                            style={{
                              color:
                                PaymentSourceVerificationStatus[paymentSourceVerificationObj?.state || 'unverified']
                                  .color,
                            }}
                          >
                            <Icon
                              name={
                                PaymentSourceVerificationStatus[paymentSourceVerificationObj?.state || 'unverified']
                                  .iconName
                              }
                            />
                          </span>
                          <span data-testid="payment-manual-verification-status">
                            {PaymentSourceVerificationStatus[paymentSourceVerificationObj?.state || 'unverified'].label}
                          </span>
                        </Text>
                      </li>
                    )}
                    {sourceVerificationEnabled && !paymentDetailObj.paymentMethod.walletType && (
                      <li className="text-right lp-flex lp-align-center lp-justify-end">
                        <div css={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                          {!paymentSourceVerificationObj ||
                          paymentSourceVerificationObj.state === 'unverified' ||
                          paymentSourceVerificationObj.state === 'expired' ? (
                            <>
                              <Button
                                size="medium"
                                variant="reversed"
                                testId="payment-manual-verification-btn"
                                onClick={() => {
                                  setVerifyManuallyModalOpen(true);
                                }}
                              >
                                Verify manually
                              </Button>
                              <span
                                data-balloon="Once clicked, your customer will automatically be charged a small amount to their payment source. Please have them available and ready to read out this amount to complete verification."
                                data-balloon-pos="up"
                                data-balloon-length="large"
                                className="lp-flex lp-align-center lp-justify-center"
                              >
                                <Icon css={{ color: 'rgb(var(--lp-colors-neutral-600))' }} name="Info" />
                              </span>
                            </>
                          ) : (
                            <>
                              <Button
                                size="medium"
                                variant="reversed"
                                testId="payment-manual-verification-btn"
                                onClick={() => {
                                  setVerifyManuallyModalOpen(true);
                                }}
                              >
                                Reset verification
                              </Button>
                              <span
                                data-balloon="If there has been a mistake in this card user’s verification status, you can reset it back to “unverfied”"
                                data-balloon-pos="up"
                                data-balloon-length="large"
                                className="lp-flex lp-align-center lp-justify-center"
                              >
                                <Icon css={{ color: 'rgb(var(--lp-colors-neutral-600))' }} name="Info" />
                              </span>
                            </>
                          )}
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
              )}

              {paymentDetailObj.paymentMethod?.paymentMethodType === 'DirectDebit' && (
                <div className="lp-half lp-flex two-column-block">
                  <ul className="two-column">
                    <li className="">
                      <Text tagName="p" variant="body-3" className="text-dark-50">
                        Payment method
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                        Direct Debit
                      </Text>
                    </li>
                    {!!paymentDetailObj.paymentMethod.directDebitFailure && (
                      <li className="">
                        <Text tagName="p" variant="body-3" className="text-dark-50">
                          Status detail
                        </Text>
                        <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                          {paymentDetailObj.paymentMethod.directDebitFailure.message}
                        </Text>
                      </li>
                    )}
                    <li className="">
                      <Text tagName="p" variant="body-3" className="text-dark-50">
                        BSB
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                        {paymentDetailObj.paymentMethod.accountRoutingNumber}
                      </Text>
                    </li>
                    <li className="">
                      <Text tagName="p" variant="body-3" className="text-dark-50">
                        Account number
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                        *****{paymentDetailObj.paymentMethod.accountLast4}
                      </Text>
                    </li>
                  </ul>
                </div>
              )}

              {paymentDetailObj.paymentMethod?.paymentMethodType === 'PayTo' && (
                <div className="lp-half lp-flex two-column-block">
                  <ul className="two-column">
                    <li className="">
                      <Text tagName="p" variant="body-3" className="text-dark-50">
                        Payment method
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                        PayTo
                      </Text>
                    </li>
                    {!!paymentDetailObj.paymentMethod.paymentFailure && (
                      <li className="">
                        <Text tagName="p" variant="body-3" className="text-dark-50">
                          Status detail
                        </Text>
                        <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                          {paymentDetailObj.paymentMethod.paymentFailure.message}
                        </Text>
                      </li>
                    )}
                    <li className="">
                      <Text tagName="p" variant="body-3" className="text-dark-50">
                        Account hint
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                        {paymentDetailObj.paymentMethod.accountHint}
                      </Text>
                    </li>
                  </ul>
                </div>
              )}
            </s.MerchantsData>

            <s.MerchantsData>
              <div className="lp-half lp-flex two-column-block">
                <ul className="two-column">
                  <li className="">
                    <Heading alignment="left" tagName="h2" variant="xxs" className="mb-0">
                      Customer details
                    </Heading>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Customer name
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {paymentDetailObj.customerDetails?.customerName ?? '-'}
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Customer ID
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {paymentDetailObj.customerDetails?.customerId ?? '-'}
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Customer email
                    </Text>
                    <Text
                      tagName="p"
                      variant="body-3"
                      className="text-dark m-0 mt-0"
                      testId="payment-detail-customeremail"
                    >
                      {paymentDetailObj.customerDetails?.customerEmailAddress ?? '-'}
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Customer phone number
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {paymentDetailObj.customerDetails?.customerPhoneNumber ?? '-'}
                    </Text>
                  </li>
                </ul>

                <ul className="two-column">
                  <li className="">
                    <Heading alignment="left" tagName="h2" variant="xxs" className="mb-0">
                      &nbsp;
                    </Heading>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Billing Address
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      <Address address={orderDetailObj?.billing?.address} />
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Shipping Address
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      <Address address={orderDetailObj?.shipping?.address} />
                    </Text>
                  </li>
                </ul>
              </div>
            </s.MerchantsData>

            <s.MerchantsData>
              <div className="lp-half lp-flex two-column-block last">
                <ul className="two-column">
                  <li className="">
                    <Heading alignment="left" tagName="h2" variant="xxs" className="mb-0">
                      Purchase details
                    </Heading>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Order ID
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {paymentDetailObj.purchaseDetails.orderId}
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Transaction ID
                    </Text>
                    <Text
                      tagName="p"
                      variant="body-3"
                      className="text-dark m-0 mt-0"
                      testId="payment-detail-txnid-value"
                    >
                      {paymentDetailObj.purchaseDetails.transactionId}
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Order type
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {capitalizeFirstLowercaseRest(paymentDetailObj.purchaseDetails.orderType)}
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Payment option
                    </Text>
                    <Text
                      tagName="p"
                      variant="body-3"
                      className="text-dark m-0 mt-0"
                      testId="payment-detail-payment-option-value"
                    >
                      {paymentDetailObj.purchaseDetails.payType === 'PayInFull'
                        ? 'Full payment'
                        : paymentDetailObj.purchaseDetails.payType === 'PayPlan'
                        ? 'Payment plan'
                        : paymentDetailObj.purchaseDetails.payType}
                    </Text>
                  </li>
                  {paymentDetailObj.purchaseDetails.description && (
                    <li className="">
                      <Text tagName="p" variant="body-3" className="text-dark-50">
                        Description
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                        {paymentDetailObj.purchaseDetails.description}
                      </Text>
                    </li>
                  )}
                </ul>
                {paymentDetailObj.serviceFees && (
                  <ul className="two-column">
                    <li className="">
                      <Heading alignment="left" tagName="h2" variant="xxs" className="mb-0">
                        Service fees
                      </Heading>
                    </li>
                    <li className="">
                      <Text tagName="p" variant="body-3" className="text-dark-50">
                        Fixed percentage amount
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                        {toCurrency(
                          paymentDetailObj.serviceFees.variableAmount,
                          paymentDetailObj.purchaseDetails.amount.currency,
                        )}{' '}
                        {paymentDetailObj.purchaseDetails.amount.currency}
                      </Text>
                    </li>
                    <li className="">
                      <Text tagName="p" variant="body-3" className="text-dark-50">
                        Fixed amount
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                        {toCurrency(
                          paymentDetailObj.serviceFees.fixedAmount,
                          paymentDetailObj.purchaseDetails.amount.currency,
                        )}{' '}
                        {paymentDetailObj.purchaseDetails.amount.currency}
                      </Text>
                    </li>
                    <li className="">
                      <Text tagName="p" variant="body-3" className="text-dark-50">
                        Tax
                      </Text>
                      <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                        {toCurrency(
                          paymentDetailObj.serviceFees.taxAmount,
                          paymentDetailObj.purchaseDetails.amount.currency,
                        )}{' '}
                        {paymentDetailObj.purchaseDetails.amount.currency}
                      </Text>
                    </li>
                    <li className="">
                      <Text tagName="p" variant="body-3" isEmphasised className="text-dark-50">
                        TOTAL inc. tax
                      </Text>
                      <Text tagName="p" variant="body-3" isEmphasised className="text-dark m-0 mt-0">
                        {toCurrency(
                          paymentDetailObj.serviceFees.totalAmount,
                          paymentDetailObj.purchaseDetails.amount.currency,
                        )}{' '}
                        {paymentDetailObj.purchaseDetails.amount.currency}
                      </Text>
                    </li>
                  </ul>
                )}
              </div>
            </s.MerchantsData>

            {!!paymentDetailObj.purchaseDetails.orderMetadata && (
              <s.MerchantsData>
                <div className="lp-half last">
                  <Heading alignment="left" tagName="h2" variant="xxs" className="mb-16">
                    Purchase metadata
                  </Heading>
                  <Metadata metadata={paymentDetailObj.purchaseDetails.orderMetadata} />
                </div>
              </s.MerchantsData>
            )}

            <RefundModalComponent
              isOpen={refundModalOpen}
              modalToggler={refundModalToggler}
              reloadPaymentDetails={reloadPaymentDetails}
              refundTransactionId={paymentDetailObj.purchaseDetails.transactionId}
              transactionAmount={paymentDetailObj.purchaseDetails.amount.minorCurrencyUnits}
              refundedAmount={paymentDetailObj.purchaseDetails.refundedAmount}
              currency={paymentDetailObj.purchaseDetails.amount.currency}
              customerName={paymentDetailObj.purchaseDetails.merchantName}
            />
            <CancelPaymentModalComponent
              isOpen={cancelPaymentModalOpen}
              modalToggler={cancelPaymentModalToggler}
              reloadPaymentDetails={reloadPaymentDetails}
              cancelAmount={paymentDetailObj.purchaseDetails.amount.minorCurrencyUnits}
              transactionId={paymentDetailObj.purchaseDetails.transactionId}
              currency={paymentDetailObj.purchaseDetails.amount.currency}
              customerName={paymentDetailObj.purchaseDetails.merchantName}
            />
            <CapturePaymentModalComponent
              isOpen={capturePaymentModalOpen}
              modalToggler={capturePaymentModalToggler}
              reloadPaymentDetails={reloadPaymentDetails}
              transactionId={paymentDetailObj.purchaseDetails.transactionId}
              transactionAmount={paymentDetailObj.purchaseDetails.amount.minorCurrencyUnits}
              currency={paymentDetailObj.purchaseDetails.amount.currency}
              customerName={paymentDetailObj.purchaseDetails.merchantName}
            />
            <ManualVerificationModal
              transactionId={transactionId}
              isOpen={verifyManuallyModalOpen}
              modalToggler={setVerifyManuallyModalOpen}
              setPaymentSourceVerificationObj={setPaymentSourceVerificationObj}
              paymentSourceVerificationObj={paymentSourceVerificationObj}
              customerName={paymentDetailObj.customerDetails?.customerName}
              customerEmailAddress={paymentDetailObj.customerDetails?.customerEmailAddress}
              customerPhoneNumber={paymentDetailObj.customerDetails?.customerPhoneNumber}
            />
          </>
        )}
      </div>
    </>
  );
}

export default PaymentDetails;
