import { MutableRefObject, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { formatCurrency } from 'utils/currency';

import { Icon } from '@limepayments/cosmic';

import { useCreateOrderContext } from './context';

interface Props {
  itemIndex: number;
  allowActions: boolean;
  parentRef: MutableRefObject<null>;
}

function OrderListItem({ itemIndex, allowActions, parentRef }: Props) {
  const { itemList, removeItem, editItem, tradingCurrency } = useCreateOrderContext();
  const [isActionDropdownOpen, setIsActionDropdownOpen] = useState<boolean>(false);

  const orderItem = itemList[itemIndex];

  const onEditClick = () => {
    editItem(itemIndex);
  };

  const onRemoveClick = () => {
    setIsActionDropdownOpen(false);
    removeItem(itemIndex);
  };

  return (
    <tr data-testid="create-neworder-modal-orderitem">
      <td>{orderItem.name}</td>
      <td>{orderItem.quantity}</td>
      <td>{formatCurrency(orderItem.amount * orderItem.quantity, tradingCurrency)}</td>
      {allowActions && (
        <td>
          <div className="lp-edit-action">
            <Popover
              isOpen={isActionDropdownOpen}
              align={'end'}
              positions={['bottom', 'top', 'left', 'right']}
              reposition={false}
              containerStyle={{
                zIndex: '10000',
              }}
              onClickOutside={() => setIsActionDropdownOpen(false)}
              content={
                <div className="t-action-dropdown" style={{ width: 154 }}>
                  <div className="lp-flex lp-flex-column">
                    <div className="t-action-dropdown-body">
                      <button className="lp-flex lp-align-center lp-w-full" onClick={onEditClick}>
                        <Icon name="Edit" className="mr-6" />
                        Edit
                      </button>
                      <button className="lp-flex lp-align-center lp-w-full" onClick={onRemoveClick}>
                        <Icon name="Cross" className="mr-6" />
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              }
            >
              <div
                className="lp-edit-action-holder"
                onClick={() => {
                  setIsActionDropdownOpen(!isActionDropdownOpen);
                }}
              >
                <button className="edit-action-button">
                  <Icon name="Ellipse" />
                </button>
              </div>
            </Popover>
          </div>
        </td>
      )}
    </tr>
  );
}

export default OrderListItem;
