import { Button, Heading, Icon, Text } from '@limepayments/cosmic';

interface Props {
  toggleFilter: () => void;
}

function EmptyUnpaidRequestComponent({ toggleFilter }: Props) {
  return (
    <div className="transaction-empty">
      <div className="transaction-empty-inner">
        <Icon name="Search" />
        <Heading
          alignment="center"
          tagName="h2"
          variant="xs"
          className="mt-12 mb-8"
          testId="virtual-terminal-filter-no-data-title"
        >
          No results found
        </Heading>
        <Text
          alignment="center"
          tagName="p"
          variant="body-2"
          className="text-dark-50 mt-0 mb-16"
          testId="virtual-terminal-filter-no-data-sub-title"
        >
          There aren't any results to display for your applied filters.
        </Text>
        <Button type="button" size="medium" variant="primary" onClick={toggleFilter}>
          <Icon name="Filter" className="mr-8" />
          Edit filters
        </Button>
      </div>
    </div>
  );
}

export default EmptyUnpaidRequestComponent;
