import { Tag } from '@limepayments/cosmic';
import { TagProps } from '@limepayments/cosmic/build/Tag/Tag';

export interface StatusTagProps {
  label: string;
  variant: TagProps['variant'];
  tooltipText: string;
  tooltipDirection: any;
  paymentTxnId?: string;
}

function StatusTag({ label, variant, tooltipText, tooltipDirection, paymentTxnId }: StatusTagProps) {
  return (
    <div
      className="lp-flex"
      data-balloon={tooltipText}
      data-balloon-pos={tooltipDirection}
      data-balloon-mobile-pos="left"
      data-balloon-length="large"
      data-balloon-mobile-length="medium"
    >
      <Tag testId="status-tag-value" label={label} size="small" variant={variant} />
    </div>
  );
}

export default StatusTag;
