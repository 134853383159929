import { CustomField } from 'api/merchant/dashboard/merchants.types';
import * as s from 'assets/styles/Platforms.styles';
import { TableThemeSettings } from 'pages/platform/constants';
import DataTable, { TableColumn, createTheme } from 'react-data-table-component';

import PopoverComponent from './PopoverComponent';
import VisibilityTag from './VisibilityTagComponent';
import { CustomFieldActions } from './types';

createTheme('polarized', TableThemeSettings, 'dark');

interface Props {
  customFieldsList: Array<CustomField>;
  totalRows: number;
  editDeleteHandler: (key: string, action: CustomFieldActions) => void;
}

function CustomFieldsDataTable({ customFieldsList, totalRows, editDeleteHandler }: Props) {
  const columns: TableColumn<CustomField>[] = [
    {
      name: 'Field Label',
      selector: (row: CustomField) => row.label,
    },
    {
      name: 'Condition',
      selector: (row: CustomField) => (row.required ? 'Required' : 'Optional'),
      width: '150px',
    },
    {
      name: 'Visibility',
      cell: (row: CustomField) => (
        <VisibilityTag
          label={row.visibleForCustomer ? 'Visible' : 'Hidden'}
          variant={row.visibleForCustomer ? 'warning' : 'neutral'}
        />
      ),
      width: '150px',
      style: {
        color: 'rgb(var(--lp-colors-neutral-400))',
      },
    },
    {
      name: '',
      width: '50px',
      cell: (row: CustomField) => {
        return <PopoverComponent onEditDelete={editDeleteHandler} customFieldKey={row.key} />;
      },
    },
  ];

  return (
    <s.MerchantPaymentsTable>
      <div data-testid="settings-paybylink-customfields-table">
        <DataTable responsive columns={columns} data={customFieldsList} theme="polarized" />
      </div>
    </s.MerchantPaymentsTable>
  );
}

export default CustomFieldsDataTable;
