import { createContext, useContext } from 'react';
import { Currency } from 'utils/currency';

export type OrderActionsProps = {
  orderId: string;
  amount: number;
  currency: Currency;
  customerEmail?: string;
  customerPhoneNumber?: string;
  customerName?: string;
  errorMessage?: string;
  cancelOrder: (orderId: string) => Promise<void>;
  fetchOrderProcessUrl: (orderId: string) => Promise<string>;
  sendOrderComms: (orderId: string, sendSms: boolean) => Promise<void>;
};

export const OrderActionsContext = createContext<OrderActionsProps | undefined>(undefined);

export function useOrderActionsContext() {
  const context = useContext(OrderActionsContext);
  if (context === undefined) {
    throw new Error('useOrderActionsContext must be used with a OrderActionsContext');
  }

  return context;
}
