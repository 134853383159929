import { Heading, Icon, Text } from '@limepayments/cosmic';

function UnbaleToLoadComponent() {
  return (
    <div className="transaction-empty">
      <div className="transaction-empty-inner">
        <Icon name="Platforms" />
        <Heading
          alignment="center"
          tagName="h2"
          variant="xs"
          className="mt-12 mb-8"
          testId="virtual-terminal-filter-no-data-title"
        >
          Unable to load unpaid requests
        </Heading>
        <Text
          alignment="center"
          tagName="p"
          variant="body-2"
          className="text-dark-50 mt-0 mb-16"
          testId="virtual-terminal-filter-no-data-sub-title"
        >
          Sorry, we're unable to load your unpaid requests at the moment, please try again later.
        </Text>
      </div>
    </div>
  );
}

export default UnbaleToLoadComponent;
