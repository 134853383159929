import { getPayoutSettlementEntries } from 'api/merchant/dashboard/payouts';
import * as s from 'assets/styles/Platforms.styles';
import { useCallback, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useAppSelector } from 'redux/merchant/hooks';
import { Currency, toCurrency } from 'utils/currency';
import { getHumanReadableDate } from 'utils/dateUtils';

import { Button, Heading, Icon, Modal, Text } from '@limepayments/cosmic';

interface Props {
  isOpen: boolean;
  modalToggler: (isOpen: boolean) => void;
  payoutId?: string;
  payoutAmount?: number;
  currency?: Currency;
  availableOn?: string;
}

function PayoutCSVDownloadModal({ isOpen, modalToggler, payoutId, payoutAmount, currency, availableOn }: Props) {
  const { merchantId } = useAppSelector((state) => ({
    merchantId: state.config.merchantId,
  }));

  const csvRef = useRef<any>();

  const [csvDownloadedData, setCsvDownloadedData] = useState<string>('');
  const [downloadReady, setDownloadReady] = useState<boolean>(false);
  const [isModalLoading, setIsModalLoading] = useState<boolean>(false);

  const fetchPayoutReport = useCallback(async () => {
    try {
      if (!payoutId) return;

      setIsModalLoading(true);
      const response = await getPayoutSettlementEntries(merchantId, payoutId);
      setCsvDownloadedData(response);
      setDownloadReady(true);
      setIsModalLoading(false);
    } catch (error) {
      console.log(error);
      modalToggler(false);
    } finally {
      setIsModalLoading(false);
    }
  }, [merchantId, payoutId, modalToggler]);

  useEffect(() => {
    if (payoutId && isOpen && csvDownloadedData && downloadReady) {
      csvRef.current.link.click();
      setDownloadReady(false);
      modalToggler(false);
    }
  }, [payoutId, isOpen, csvDownloadedData, downloadReady, modalToggler]);

  return (
    <Modal isOpen={isOpen} className="modal-small update-pass-modal" testId="payout-csv-download-modal">
      <s.Wrapper className="">
        <div className="modal-main p-24">
          <div className="modal-header">
            <div className="content-main bb-1">
              <Heading alignment="center" tagName="h2" variant="xs" className="mt-12 mb-24 lp-justify-center lp-flex">
                Download CSV
              </Heading>
            </div>
          </div>
          <div className="modal-body pt-24">
            <Text alignment="center" tagName="p" variant="body-3" className="lp-neutral-600">
              Download CSV of <span data-testid="payout-csv-download-modal-payout-id">{payoutId}</span> of{' '}
              <strong data-testid="payout-csv-download-modal-amount">
                {payoutAmount && currency && toCurrency(payoutAmount, currency)}
              </strong>{' '}
              {currency}
              {availableOn && (
                <span>
                  {' '}
                  available on{' '}
                  <strong data-testid="payout-csv-download-modal-date">{getHumanReadableDate(availableOn)}</strong>
                </span>
              )}
              .
            </Text>
          </div>
          <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
            <Button
              onClick={() => modalToggler(false)}
              disabled={isModalLoading}
              size="medium"
              variant="ghost"
              className="no-min-width w-70"
              testId="payout-csv-download-modal-cancel-button"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              isLoading={isModalLoading}
              size="medium"
              variant="primary"
              className="no-min-width"
              onClick={fetchPayoutReport}
              testId="payout-csv-download-modal-submit-button"
            >
              Download CSV
              <Icon name="Download" />
            </Button>
          </div>
          {csvDownloadedData ? (
            <CSVLink ref={csvRef} data={csvDownloadedData} target="_self" filename={Date.now() + '.csv'} />
          ) : (
            <></>
          )}
        </div>
      </s.Wrapper>
    </Modal>
  );
}

export default PayoutCSVDownloadModal;
