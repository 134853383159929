/** @jsxImportSource @emotion/react */

import { AmountFilterInput } from 'api/graphql/generated/graphql';
import { fromCurrency } from 'utils/currency';

import { Icon, Text } from '@limepayments/cosmic';

export type AmountFilterValue =
  | {
      option: 'is_equal_to' | 'is_after' | 'is_before';
      amount: string;
    }
  | {
      option: 'is_between';
      amounts: string[];
    };

export const defaultAmountFilter: AmountFilterValue = {
  option: 'is_equal_to',
  amount: '',
};

export const toAmountFilterInput = (amountFilter: AmountFilterValue): AmountFilterInput | null => {
  if (amountFilter.option === 'is_between') {
    const gte = fromCurrency(amountFilter.amounts[0]);
    const lte = fromCurrency(amountFilter.amounts[1]);

    return { gte, lte };
  }

  const amount = fromCurrency(amountFilter.amount);

  return amountFilter.option === 'is_equal_to'
    ? { eq: amount }
    : amountFilter.option === 'is_after'
    ? { gt: amount }
    : amountFilter.option === 'is_before'
    ? { lt: amount }
    : null;
};

const sanitizeAmountInput = (amount: string) => amount.replace(/[^0-9.]+/g, '');

export type AmountFilterProps = {
  value: AmountFilterValue | null;
  onChange(value: AmountFilterValue): void;
};

export function AmountFilter({ onChange, ...props }: AmountFilterProps) {
  const value = props.value ?? defaultAmountFilter;

  return (
    <>
      <div className="lp-w-full mb-16">
        <div className="selectdropdown">
          <Icon name="ChevronDown" className="arrow-down" />
          <select
            className="form-control"
            value={value.option}
            onChange={({ target: { value: option } }) =>
              onChange(
                option === 'is_between' ? { option, amounts: ['', ''] } : ({ option, amount: '' } as AmountFilterValue),
              )
            }
            data-testid="amount-filter-type-select"
          >
            <option value="is_equal_to">is equal to</option>
            <option value="is_between">is between</option>
            <option value="is_after">is greater than</option>
            <option value="is_before">is less than</option>
          </select>
        </div>
      </div>
      <div className="lp-w-full lp-flex lp-align-center mb-0">
        <div className="w-24">
          <Icon name="Return" className="text-primary" />
        </div>
        <div className="lp-w-full ml-16px">
          {value.option === 'is_between' ? (
            <div css={{ display: 'flex', alignItems: 'center', gap: 'var(--lp-space-xs)' }}>
              <input
                type="text"
                className="form-control radius-0"
                value={value.amounts[0]}
                onChange={({ target }) =>
                  onChange({
                    option: value.option,
                    amounts: [sanitizeAmountInput(target.value), value.amounts[1]],
                  })
                }
                data-testid="amount-filter-minamount-input"
              />
              <Text tagName="span" variant="body-3">
                and
              </Text>
              <input
                type="text"
                className="form-control radius-0"
                value={value.amounts[1]}
                onChange={({ target }) =>
                  onChange({
                    option: value.option,
                    amounts: [value.amounts[0], sanitizeAmountInput(target.value)],
                  })
                }
                data-testid="amount-filter-maxamount-input"
              />
            </div>
          ) : (
            <input
              type="text"
              className="form-control radius-0"
              value={value.amount}
              onChange={({ target }) =>
                onChange({
                  option: value.option,
                  amount: sanitizeAmountInput(target.value),
                })
              }
              data-testid="amount-filter-amount-input"
            />
          )}
        </div>
      </div>
    </>
  );
}
