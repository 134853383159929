import { OrderStatus } from 'api/graphql/generated/graphql';
import { toVirtualTerminalStatusObj } from 'pages/merchant/dashboard/virtual-terminal/constants';
import { useCallback } from 'react';

import { Checkbox, CheckboxGroup } from '@limepayments/cosmic';

import { SmallCheckboxes } from './FilterDropdown';

export type OrderStatusFilterProps = {
  value: OrderStatus[];
  onChange: (value: OrderStatus[]) => void;
};

export function OrderStatusFilter({ value, onChange }: OrderStatusFilterProps) {
  const onToggle = useCallback(
    (status: OrderStatus) => {
      onChange(
        value.some((value) => value === status) ? [...value].filter((value) => value !== status) : [...value, status],
      );
    },
    [onChange, value],
  );

  const StatusCheckbox = useCallback(
    ({ status }: { status: OrderStatus }) => (
      <Checkbox
        variant="body-3"
        id={status}
        label={toVirtualTerminalStatusObj(status).label}
        checked={value.some((value) => value === status)}
        onChange={() => onToggle(status)}
        testId={`order-status-filter-status-check-${status}`}
      />
    ),
    [onToggle, value],
  );

  return (
    <SmallCheckboxes>
      <CheckboxGroup>
        <StatusCheckbox status={OrderStatus.Created} />
        <StatusCheckbox status={OrderStatus.Cancelled} />
      </CheckboxGroup>
    </SmallCheckboxes>
  );
}
