import { updateSettingPassword } from 'api/merchant/dashboard/settings/my-profile';
import { FormikProps } from 'formik';
import MerchantDashboardLayout from 'layouts/merchant-dashboard/MerchantDashboardLayout';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/merchant/hooks';
import getCurrentUser from 'utils/getCurrentUser';
import getErrorMessage from 'utils/getErrorMessage';

import { Button, Heading, Icon, LabelledValue } from '@limepayments/cosmic';

import * as s from '../../../../../assets/styles/Platforms.styles';
import SettingRoutesList from '../constants';
// Import Static UIs
import SettingsSidebar from '../partials/SettingsSidebar';
import UpdatePasswordModal from '../partials/UpdatePasswordModal';
import { SettingPasswordUpdatePayload } from './types';

function ProfileSettingPage() {
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [passwordEditModalOpen, setPasswordEditModalOpen] = useState(false);
  const [updateLoader, setUpdateLoader] = useState<boolean>(false);
  const [merchantEmail, setMerchantEmail] = useState<string>('');
  const [initialState] = useState<SettingPasswordUpdatePayload>({
    password: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const formRef = useRef<FormikProps<SettingPasswordUpdatePayload>>(null);

  const { apiBaseUri } = useAppSelector((state) => ({
    apiBaseUri: state.config.apiBaseUri,
  }));

  const fetchMerchantDetails = useCallback(async () => {
    const currentUser = await getCurrentUser();
    setMerchantEmail(currentUser?.claims.email ?? '');
  }, []);

  useEffect(() => {
    fetchMerchantDetails();
  }, [fetchMerchantDetails]);

  useEffect(() => {
    setErrorMsg('');
  }, [passwordEditModalOpen]);

  const submitHandler = async (values: SettingPasswordUpdatePayload, formObj: { resetForm: () => void }) => {
    try {
      setUpdateLoader(true);
      setErrorMsg('');

      const { confirmNewPassword, ...postData } = { ...values };

      await updateSettingPassword(apiBaseUri, postData);

      toast.success('Password updated successfully');
      formObj.resetForm();
      setPasswordEditModalOpen(false);
    } catch (error) {
      setErrorMsg(
        getErrorMessage(error, ({ errorCode, message }) =>
          errorCode === 'credentials_invalid' ? 'Incorrect Password' : message,
        ),
      );
    } finally {
      setUpdateLoader(false);
    }
  };

  return (
    <MerchantDashboardLayout activeTab="settings" title="Setting">
      <div className="content-wrap-inner with-gutter">
        <div className="lp-w-full lp-flex">
          <div className="payment-sidebar">
            <SettingsSidebar activeTab={SettingRoutesList.SETTINGS_PROFILE} />
          </div>

          <div className="payment-content-area">
            <div className="lp-w-full lp-flex lp-justify-between sm-lp-flex-col mb-32">
              <Heading alignment="left" tagName="h2" variant="sm">
                My profile
              </Heading>
            </div>

            <s.MerchantsData className="bt-1 mb-32">
              {/* TODO: missing API to retrieve merchant user name
              <div className="lp-w-full mb-32">
                <LabelledValue label="Name" value={merchantName} variant="vertical" />
              </div>
              */}
              <div className="lp-w-full mb-0">
                <LabelledValue
                  label="Email"
                  value={merchantEmail}
                  variant="vertical"
                  testId="settings-myprofile-email"
                />
              </div>
            </s.MerchantsData>

            <s.MerchantsData className="bt-1">
              <div className="lp-w-full lp-flex lp-align-center lp-flex-row mb-32">
                <LabelledValue label="Password" value="*****************" variant="vertical" />
                <div className="ml-auto">
                  <Button
                    size="small"
                    variant="reversed"
                    onClick={() => setPasswordEditModalOpen(!passwordEditModalOpen)}
                    testId="settings-update-password-button"
                  >
                    Update
                    <Icon name="Edit" />
                  </Button>
                </div>
              </div>
            </s.MerchantsData>
          </div>
        </div>
      </div>
      {/* Modals UI(s) */}
      <UpdatePasswordModal
        isOpen={passwordEditModalOpen}
        modalToggler={setPasswordEditModalOpen}
        onSubmitClick={submitHandler}
        formRef={formRef}
        errorMsg={errorMsg}
        updateLoader={updateLoader}
        initialValues={initialState}
      />
    </MerchantDashboardLayout>
  );
}

export default ProfileSettingPage;
