import { getMerchant } from 'api/merchant/dashboard/merchants';
import { AnyMerchant } from 'api/merchant/dashboard/merchants.types';
import { getMerchantBalances } from 'api/merchant/dashboard/payouts';
import { getPayoutBankAccounts } from 'api/merchant/dashboard/settings/payouts';
import { AccountInterface } from 'api/merchant/dashboard/settings/payouts.types';
import { GetMerchantBalancesResponse } from 'api/merchant/dashboard/settlement.types';
import { getPlatformMerchant } from 'api/platform/merchant';
import { PlatformMerchantObj } from 'api/platform/merchant.types';
import PlatformLayout from 'layouts/platform/PlatformLayout';
import { useCallback, useEffect, useState } from 'react';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/platform/hooks';
import { getPlatformBaseUrl } from 'utils/getBaseUrl';
import getErrorMessage from 'utils/getErrorMessage';

import DashboardRoutesList from '../../constants';
import MerchantRoutesList from '../constants';
import { IFilterComponentObj } from '../listing/types';
import MerchantDetails from './MerchantDetails';

export interface Props {
  merchantList: Array<PlatformMerchantObj>;
  activeStatusTab: string;
  statusTabChangeHandler: (val: string) => void;
  fetchListLoader: boolean;
  totalRows: number;
  handlePerRowsChange: (newPerPage: number, page: number) => void;
  handlePageChange: (page: number) => void;
  limit: number;
  exportOpen: boolean;
  filterPopupOpen: boolean;
  toggleFilter: (val: boolean) => void;
  toggleExport: () => void;
  filterRecordHandler: (obj: IFilterComponentObj) => void;
  isFilterRequest: boolean;
  startDate: string;
  endDate: string;
  activePage: number;
  handleSort: (selectedColumn: TableColumn<PlatformMerchantObj>, sortDirection: SortOrder) => void;
}

function MerchantDetailPage() {
  const navigate = useNavigate();
  const { merchantId } = useParams();

  const getAccountInitialState = (): AccountInterface => {
    return {
      externalAccountId: '',
      accountNumberLast4: '',
      bankName: '',
      country: '',
      currency: 'AUD',
      isDefaultForCurrency: false,
    };
  };

  // detail section variables
  const [showMerchantDetailLoader, setShowMerchantDetailLoader] = useState<boolean>(false);
  const [merchantDetailObj, setMerchantDetailObj] = useState<PlatformMerchantObj | null>(null);
  const [merchantDetailsObj, setMerchantDetailsObj] = useState<AnyMerchant | null>(null);
  const [merchantBalancesObj, setMerchantBalancesObj] = useState<GetMerchantBalancesResponse | null>(null);
  const [payoutBankAccounts, setPayoutBankAccounts] = useState<AccountInterface>(getAccountInitialState());
  const [merchantDetailErrorMsg, setMerchantDetailErrorMsg] = useState<string>('');
  const [timestamp] = useState<number>(0);

  const { platformName, marketplaceId, apiBaseUri } = useAppSelector((state) => ({
    platformName: state.config.platformName,
    marketplaceId: state.config.marketplaceId,
    apiBaseUri: state.config.apiBaseUri,
  }));

  const detailSectionBackClickhandler = useCallback(() => {
    navigate(
      `${getPlatformBaseUrl(platformName)}/dashboard/${DashboardRoutesList.MERCHANT_ROUTE}/${
        MerchantRoutesList.MERCHANT_LANDING
      }`,
    );
  }, [navigate, platformName]);

  const fetchMerchantDetails = useCallback(async () => {
    try {
      setShowMerchantDetailLoader(true);

      const [response, merchantResponse, balancesResponse, bankAccountsResponse] = await Promise.all([
        getPlatformMerchant(apiBaseUri, marketplaceId, merchantId as string),
        getMerchant(merchantId as string),
        getMerchantBalances(merchantId as string),
        getPayoutBankAccounts(apiBaseUri, merchantId as string, timestamp),
      ]);

      const activeBankArray = bankAccountsResponse.filter((obj) => {
        return obj.isDefaultForCurrency === true;
      });
      setMerchantDetailObj(response);
      setMerchantDetailsObj(merchantResponse);
      setMerchantBalancesObj(balancesResponse);
      setPayoutBankAccounts(activeBankArray.length > 0 ? activeBankArray[0] : getAccountInitialState());
    } catch (error) {
      setMerchantDetailErrorMsg(getErrorMessage(error));
    } finally {
      setShowMerchantDetailLoader(false);
    }
  }, [apiBaseUri, marketplaceId, merchantId, timestamp]);

  useEffect(() => {
    if (merchantId) {
      fetchMerchantDetails();
    } else {
      detailSectionBackClickhandler();
    }
  }, [merchantId, fetchMerchantDetails, detailSectionBackClickhandler]);

  return (
    <PlatformLayout activeTab="merchant" title="Merchant Detail">
      <MerchantDetails
        onBackButtonClick={() => detailSectionBackClickhandler()}
        showMerchantDetailLoader={showMerchantDetailLoader}
        merchantDetailObj={merchantDetailObj}
        merchantDetailsObj={merchantDetailsObj}
        merchantBalancesObj={merchantBalancesObj}
        payoutBankAccounts={payoutBankAccounts}
        merchantDetailErrorMsg={merchantDetailErrorMsg}
      />
    </PlatformLayout>
  );
}

export default MerchantDetailPage;
