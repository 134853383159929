import MerchantDefaultRoutes from 'pages/merchant/constants';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/merchant/hooks';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';

import { Button, Heading } from '@limepayments/cosmic';

import DashboardRoutesList from '../../constants';
import SettingRoutesList from '../constants';

interface Props {
  activeTab?: string;
}

function SettingsSidebar({ activeTab }: Props) {
  const navigate = useNavigate();
  const { merchantName, merchantType } = useAppSelector((state) => ({
    merchantName: state.config.merchantName,
    merchantType: state.config.merchantType,
  }));

  return (
    <div className="payment-sidebar-inner sticky-sidebar">
      <div className="lp-w-full mb-32">
        <Heading alignment="left" tagName="h2" variant="xs" className="sidebar-title mb-0">
          My account
        </Heading>
        <Button
          size="small"
          onClick={() =>
            navigate(
              `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/${
                DashboardRoutesList.SETTINGS_ROUTE
              }/${SettingRoutesList.SETTINGS_PROFILE}`,
            )
          }
          variant="primary"
          testId="sidebar-menu-item-myprofile"
          className={`lp-w-full lp-button-transaction ${
            activeTab === SettingRoutesList.SETTINGS_PROFILE ? `is-selected` : ``
          }`}
        >
          My profile
        </Button>
      </div>

      <div className="lp-w-full mb-32">
        <Heading alignment="left" tagName="h2" variant="xs" className="sidebar-title mb-0">
          Business settings
        </Heading>
        <Button
          size="small"
          onClick={() =>
            navigate(
              `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/${
                DashboardRoutesList.SETTINGS_ROUTE
              }/${SettingRoutesList.SETTINGS_BUSINESS_PROFILE}`,
            )
          }
          variant="primary"
          testId="sidebar-menu-item-businessprofile"
          className={`lp-w-full lp-button-transaction ${
            activeTab === SettingRoutesList.SETTINGS_BUSINESS_PROFILE ? `is-selected` : ``
          }`}
        >
          Business profile
        </Button>
        <Button
          size="small"
          onClick={() =>
            navigate(
              `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/${
                DashboardRoutesList.SETTINGS_ROUTE
              }/${SettingRoutesList.SETTINGS_BILLING}`,
            )
          }
          variant="primary"
          testId="sidebar-menu-item-billing"
          className={`lp-w-full lp-button-transaction ${
            activeTab === SettingRoutesList.SETTINGS_BILLING ? `is-selected` : ``
          }`}
        >
          Billing
        </Button>
        {merchantType === 'StandardMerchant' && (
          <Button
            size="small"
            onClick={() =>
              navigate(
                `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/${
                  DashboardRoutesList.SETTINGS_ROUTE
                }/${SettingRoutesList.SETTINGS_BRANDING}`,
              )
            }
            variant="primary"
            testId="sidebar-menu-item-branding"
            className={`lp-w-full lp-button-transaction ${
              activeTab === SettingRoutesList.SETTINGS_BRANDING ? `is-selected` : ``
            }`}
          >
            Branding
          </Button>
        )}

        {/* <Button
          size="small"
          onClick={() =>
            navigate(
              `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/${
                DashboardRoutesList.SETTINGS_ROUTE
              }/${SettingRoutesList.SETTINGS_FRAUD_PREVENTIONS}`,
            )
          }
          variant="primary"
          className={`lp-w-full lp-button-transaction ${
            activeTab === SettingRoutesList.SETTINGS_FRAUD_PREVENTIONS ? `is-selected` : ``
          }`}
        >
          Fraud prevention
        </Button> */}

        {/* <Button
          size="small"
          onClick={() =>
            navigate(
              `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/${
                DashboardRoutesList.SETTINGS_ROUTE
              }/${SettingRoutesList.SETTINGS_FEE_STRUCTURE}`,
            )
          }
          variant="primary"
          className={`lp-w-full lp-button-transaction ${
            activeTab === SettingRoutesList.SETTINGS_FEE_STRUCTURE ? `is-selected` : ``
          }`}
        >
          Fee structure
        </Button> */}
      </div>

      <div className="lp-w-full mb-32">
        <Heading alignment="left" tagName="h2" variant="xs" className="sidebar-title mb-0">
          Product settings
        </Heading>
        {merchantType === 'StandardMerchant' && (
          <Button
            size="small"
            onClick={() =>
              navigate(
                `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/${
                  DashboardRoutesList.SETTINGS_ROUTE
                }/${SettingRoutesList.SETTINGS_PAYMENT}`,
              )
            }
            variant="primary"
            testId="sidebar-menu-item-payments"
            className={`lp-w-full lp-button-transaction ${
              activeTab === SettingRoutesList.SETTINGS_PAYMENT ? `is-selected` : ``
            }`}
          >
            Payments
          </Button>
        )}
        {merchantType === 'StandardMerchant' && (
          <Button
            size="small"
            onClick={() =>
              navigate(
                `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/${
                  DashboardRoutesList.SETTINGS_ROUTE
                }/${SettingRoutesList.SETTINGS_PAY_BY_LINK}`,
              )
            }
            variant="primary"
            testId="sidebar-menu-item-paybylink"
            className={`lp-w-full lp-button-transaction ${
              activeTab === SettingRoutesList.SETTINGS_PAY_BY_LINK ? `is-selected` : ``
            }`}
          >
            Pay by link
          </Button>
        )}
        <Button
          size="small"
          onClick={() =>
            navigate(
              `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/${
                DashboardRoutesList.SETTINGS_ROUTE
              }/${SettingRoutesList.SETTINGS_BALANCES}`,
            )
          }
          variant="primary"
          testId="sidebar-menu-item-balances"
          className={`lp-w-full lp-button-transaction ${
            activeTab === SettingRoutesList.SETTINGS_BALANCES ? `is-selected` : ``
          }`}
        >
          Balances
        </Button>
        <Button
          size="small"
          onClick={() =>
            navigate(
              `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/${
                DashboardRoutesList.SETTINGS_ROUTE
              }/${SettingRoutesList.SETTINGS_API_KEYS}`,
            )
          }
          variant="primary"
          testId="sidebar-menu-item-apikeys"
          className={`lp-w-full lp-button-transaction ${
            activeTab === SettingRoutesList.SETTINGS_API_KEYS ? `is-selected` : ``
          }`}
        >
          API keys
        </Button>
      </div>
    </div>
  );
}

export default SettingsSidebar;
