import { CreateOrderRequest } from 'api/merchant/dashboard/orders.types';
import { fromCurrency } from 'utils/currency';

import { OrderDetails, OrderItem } from '../CreateNewOrderModal';

export const ModalSteps = {
  ManageOrderItems: 'manage_order_items',
  NewOrderDetails: 'new_order_details',
  ConfirmOrder: 'confirm_order',
  OrderCreationSuccess: 'order_creation_success',
};

export interface ItemForm {
  name: string;
  amount: number;
  quantity: number;
}

export interface OrderDetailsForm {
  orderNumber: string;
  amount: number;
  description?: string;
  customerEmail: string;
  customerPhone: string;
  customerName: string;
  customFields: {
    [x: string]: string;
  };
}

type toCreateOrderRequestType = (
  itemList: OrderItem[],
  orderDetails: OrderDetails,
  sendEmail: boolean,
  displayTax: boolean,
  request3ds: boolean,
) => CreateOrderRequest;

export const toCreateOrderRequest: toCreateOrderRequestType = (
  itemList: OrderItem[],
  orderDetails: OrderDetails,
  sendEmail: boolean,
  displayTax: boolean,
  request3ds: boolean,
) => {
  return {
    CreatePayByLinkOrder: {
      referenceOrderId: orderDetails.orderNumber,
      amount: {
        minorCurrencyUnits: fromCurrency(orderDetails.amount),
        currency: orderDetails.currency,
      },
      customerEmailAddress: orderDetails.customerEmail,
      customerPhoneNumber: orderDetails.customerPhone,
      description: orderDetails.description,
      items:
        itemList && itemList.length > 0
          ? itemList.map((item) => ({
              amount: {
                minorCurrencyUnits: fromCurrency(item.amount),
                currency: orderDetails.currency,
              },
              quantity: item.quantity,
              description: item.name,
            }))
          : undefined,
      sendPaymentLinkEmail: sendEmail,
      sendPaymentLinkSms: sendEmail && !!orderDetails.customerPhone,
      billing: {
        address: {
          line1: '',
          line2: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        name: orderDetails.customerName,
      },
      metadata: orderDetails.customFields
        ? Object.fromEntries(orderDetails.customFields.map((field) => [`${field.key}`, field.value]))
        : undefined,
      taxIncluded: displayTax,
      request3DSOnPayment: request3ds,
    },
  };
};
