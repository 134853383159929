import PrivateRoute from 'PrivateRoute';
import PublicRoute from 'PublicRoute';
import { logout } from 'api/auth/identity';
import { createUrqlClient } from 'api/graphql';
import { getMerchant } from 'api/merchant/dashboard/merchants';
import { getMerchantConfig } from 'api/merchant/merchant';
import { getMerchantStatus } from 'api/merchant/onbording/introduction';
import RoutesList from 'config/constants';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import ErrorPage from 'pages/common/error';
import AuthRoutesList from 'pages/merchant/auth/constants';
import LogoutPage from 'pages/merchant/auth/logout';
import MerchantDefaultRoutes from 'pages/merchant/constants';
import DashboardRoutes from 'pages/merchant/dashboard/DashboardRoutes';
import DashboardRoutesList from 'pages/merchant/dashboard/constants';
import PaymentRoutesList from 'pages/merchant/dashboard/payment/constants';
import { InvoiceRoutes } from 'pages/merchant/invoice/InvoiceRoutes';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/merchant/hooks';
import {
  setConfig,
  setLoggedIn,
  setMerchantEmail,
  setMerchantName,
  setMerchantType,
} from 'redux/merchant/slice/configSlice';
import { setMerchantStatus } from 'redux/merchant/slice/merchantSlice';
import { Client, Provider as UrqlProvider } from 'urql';
import compareTwoStrings from 'utils/compareTwoStrings';
import { getEnv } from 'utils/env';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';
import getCurrentUser from 'utils/getCurrentUser';
import getErrorMessage from 'utils/getErrorMessage';
import getMerchantName from 'utils/getMerchantName';
import { clearZendeskWidget, configZendeskWidget, identifyZendeskUser, loadZendeskScript } from 'utils/zendesk';

import { Spinner } from '@limepayments/cosmic';

import { firebaseApp } from './api/auth/firebase';
import AuthRoutes from './pages/merchant/auth/AuthRoutes';
import OnboardingRoutes from './pages/merchant/onboarding/OnboardingRoutes';

function MerchantApp() {
  const [merchantName] = useState(() => getMerchantName());
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [merchantStatusLoader, setMerchantStatusLoader] = useState<boolean>(false);
  const [merchantTypeLoader, setMerchantTypeLoader] = useState<boolean>(false);
  const [authLoader, setAuthLoader] = useState<boolean>(true);
  const [isEnabled, setIsEnabled] = useState(false);
  const [urqlClient, setUrqlClient] = useState<Client>();

  const dispatch = useAppDispatch();

  const { isLoggedIn, merchantEmail, apiBaseUri, merchantId, merchantStatus, merchantType } = useAppSelector(
    (state) => ({
      isLoggedIn: state.config.isLoggedIn,
      merchantEmail: state.config.merchantEmail,
      apiBaseUri: state.config.apiBaseUri,
      merchantId: state.config.merchantId,
      merchantStatus: state.merchant.merchantStatus,
      merchantType: state.config.merchantType,
    }),
  );

  const isOnboarding = useMemo(
    () =>
      merchantStatus &&
      merchantType !== 'ServiceProviderMerchant' &&
      ['Onboarding', 'OnboardingReview'].includes(merchantStatus.state),
    [merchantStatus, merchantType],
  );

  useEffect(() => {
    if (!merchantName) {
      setErrorMsg('No merchant slug found');
      return;
    }

    const initialize = async () => {
      try {
        const env = await getEnv();
        setIsEnabled(['dev', 'tst'].includes(env)); // TODO

        const config = await getMerchantConfig(merchantName);
        dispatch(setConfig(config));
        dispatch(setMerchantName(merchantName));

        const fireBaseApp = firebaseApp.initializeApp({ apiKey: config.authApiKey, authDomain: config.authDomain });
        const firebaseAuth = getAuth(fireBaseApp);

        const urqlClient = await createUrqlClient();
        setUrqlClient(urqlClient);

        await loadZendeskScript().catch(console.error);
        configZendeskWidget();

        onAuthStateChanged(firebaseAuth, async () => {
          const currentUser = await getCurrentUser();
          if (!currentUser) {
            clearZendeskWidget();
            setAuthLoader(false);
            return;
          }
          if (
            currentUser.claims.limepay.merchantId &&
            compareTwoStrings(config.merchantId, currentUser.claims.limepay.merchantId)
          ) {
            dispatch(setLoggedIn(!!currentUser));
            dispatch(setMerchantEmail(currentUser.claims.email));
            setAuthLoader(false);
          } else {
            await logout();

            if (!window.location.href.includes('/auth/login')) {
              window.location.href = `/${RoutesList.MERCHANT_ROUTE}/${merchantName}/auth/login`;
            }
          }
        });
      } catch (error) {
        setErrorMsg(getErrorMessage(error));
        setAuthLoader(false);
      }
    };

    initialize();
  }, [merchantName, dispatch]);

  useEffect(() => {
    if (!merchantEmail || !merchantName) {
      return;
    }
    const name = `${merchantName}`;
    identifyZendeskUser(name, merchantEmail, merchantName);
  }, [merchantName, merchantEmail]);

  useEffect(() => {
    if (!merchantId || !apiBaseUri || !merchantName || !isLoggedIn || merchantStatus) {
      return;
    }

    const fetchMerchantStatus = async () => {
      try {
        setMerchantStatusLoader(true);
        setIsPageLoading(true);
        setErrorMsg('');

        const response = await getMerchantStatus(apiBaseUri, merchantId);
        dispatch(setMerchantStatus(response));
      } catch (err) {
        setErrorMsg(getErrorMessage(err));
      } finally {
        setMerchantStatusLoader(false);
      }
    };

    fetchMerchantStatus();
  }, [apiBaseUri, merchantName, merchantId, isLoggedIn, merchantStatus, dispatch]);

  useEffect(() => {
    if (!merchantStatusLoader && !merchantTypeLoader && !authLoader) {
      setIsPageLoading(false);
    }
  }, [setIsPageLoading, merchantStatusLoader, authLoader, merchantTypeLoader]);

  useEffect(() => {
    const fetchMerchantType = async () => {
      if (isLoggedIn) {
        try {
          setMerchantTypeLoader(true);
          setIsPageLoading(true);
          setErrorMsg('');

          const merchant = await getMerchant(merchantId);
          dispatch(setMerchantType(merchant.type));
        } catch (err) {
          setErrorMsg(getErrorMessage(err));
        } finally {
          setMerchantTypeLoader(false);
        }
      }
    };
    fetchMerchantType();
  }, [dispatch, merchantId, isLoggedIn]);

  return (
    <Fragment>
      {!isPageLoading && errorMsg.length > 0 && <ErrorPage bodyTitle="Error" bodyText={errorMsg} />}

      {isPageLoading && errorMsg.length === 0 && (
        <div className="spinner-wrapper">
          <Spinner variant="simple" isVisible label="Loading..." />
        </div>
      )}

      {!isPageLoading && errorMsg.length === 0 && !!urqlClient && (
        <UrqlProvider value={urqlClient}>
          <BrowserRouter>
            <Routes>
              {/* Invoice Routes */}
              <Route
                path={`/${RoutesList.MERCHANT_ROUTE}/:merchantName/${RoutesList.BILLING_ROUTE}/*`}
                element={<InvoiceRoutes />}
              />
              {/* Auth Routes - NON Logged In Users */}
              <Route
                element={
                  <PublicRoute
                    applicationArea="merchant"
                    isLoggedIn={isLoggedIn}
                    merchantState={merchantStatus?.state}
                    defaultRoute={
                      isOnboarding
                        ? `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.ONBOARDING_ROUTE}`
                        : `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/${
                            DashboardRoutesList.PAYMENT_ROUTE
                          }/${PaymentRoutesList.PAYMENT_LANDING}`
                    }
                  />
                }
              >
                <Route path={`/${RoutesList.MERCHANT_ROUTE}/:merchantName`} element={<AuthRoutes />} />
                <Route
                  path={`/${RoutesList.MERCHANT_ROUTE}/:merchantName/${RoutesList.AUTH_ROUTE}/*`}
                  element={<AuthRoutes />}
                />
              </Route>

              {/* Private Routes - Logged In Users */}
              <Route element={<PrivateRoute isLoggedIn={isLoggedIn} />}>
                <Route
                  path={`/${RoutesList.MERCHANT_ROUTE}/:merchantName/${AuthRoutesList.LOGOUT_PAGE}`}
                  element={<LogoutPage />}
                />

                {/* Onboarding Routes */}
                {isOnboarding && (
                  <Route
                    path={`/${RoutesList.MERCHANT_ROUTE}/:merchantName/${MerchantDefaultRoutes.ONBOARDING_ROUTE}/*`}
                    element={<OnboardingRoutes />}
                  />
                )}

                {/* Active merchants - Dashboard Routes */}
                {isEnabled && (
                  <Route
                    path={`/${RoutesList.MERCHANT_ROUTE}/:merchantName/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/*`}
                    element={<DashboardRoutes />}
                  />
                )}
              </Route>

              {/* Fallback - Not Found */}
              <Route
                path={`*`}
                element={
                  <ErrorPage bodyTitle="Page Not Found" bodyText="The page you are trying to access doesn't exists" />
                }
              />
            </Routes>
          </BrowserRouter>
        </UrqlProvider>
      )}
    </Fragment>
  );
}

export default MerchantApp;
