import { Fragment, useCallback, useEffect, useRef } from 'react';
import { formatCurrency } from 'utils/currency';

import { Button, Checkbox, Heading, Icon, Message, Text } from '@limepayments/cosmic';

import { OrderDetails, OrderItem } from '../CreateNewOrderModal';
import OrderItemsTable from './OrderItemsTable';
import { useCreateOrderContext } from './context';
import { ModalSteps } from './types';

interface Props {
  handleOrderSubmit: (itemList: OrderItem[], orderDetails: OrderDetails, sendEmail: boolean) => void;
}

function ConfirmOrder({ handleOrderSubmit }: Props) {
  const {
    setActiveStep,
    orderDetails,
    itemList,
    tradingCurrency,
    isModalLoading,
    isSendingToCustomer,
    isProcessingNow,
    displayTax,
    setDisplayTax,
    request3ds,
    setRequest3ds,
    errorMessage,
  } = useCreateOrderContext();

  const sendToCustomer = useCallback(() => {
    handleOrderSubmit(itemList, orderDetails, true);
  }, [handleOrderSubmit, itemList, orderDetails]);

  const processNow = useCallback(() => {
    handleOrderSubmit(itemList, orderDetails, false);
  }, [handleOrderSubmit, itemList, orderDetails]);

  const errorContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (errorMessage.length > 0 && errorContainerRef && errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView();
    }
  }, [errorMessage]);

  return (
    <div className="modal-main p-24">
      <div className="modal-header">
        <div className="content-main bb-1">
          <Heading alignment="center" tagName="h2" variant="xs" className="mt-12 mb-24 lp-justify-center lp-flex">
            {`Confirm order ${orderDetails?.orderNumber}`}
          </Heading>
        </div>
      </div>
      <div className="modal-body h90vh p-28 sm-p-0 sm-mt-24">
        <Fragment>
          {errorMessage.length > 0 && (
            <div className="mt-24 mb-24" ref={errorContainerRef}>
              <Message type="inline" children={<span className="text-wrap">{errorMessage}</span>} variant={'error'} />
            </div>
          )}
          <div>
            <div className="lp-w-full mb-16">
              <Text tagName="p" variant="body-2" className="text-dark-50 mt-0">
                Order amount
              </Text>
              <Heading alignment="left" tagName="h1" variant="lg" testId="create-neworder-modal-orderamount">
                {itemList && formatCurrency(orderDetails?.amount || 0, tradingCurrency)}
              </Heading>
            </div>
            <div className="lp-w-full mb-16">
              <Text tagName="p" variant="caption" className="text-dark-50 mt-0">
                Description
              </Text>
              <Text
                tagName="p"
                variant="body-3"
                className="text-dark mt-0"
                testId="create-neworder-modal-orderdesciption"
              >
                {orderDetails?.description || '-'}
              </Text>
            </div>
            <div className="lp-w-full mb-16">
              <Text tagName="p" variant="caption" className="text-dark-50 mt-0">
                Customer details
              </Text>
              <Text tagName="p" variant="body-3" className="text-dark mt-0" testId="create-neworder-modal-customername">
                {orderDetails?.customerName || ''}
                <br />
                {orderDetails?.customerEmail || ''}
                <br />
                {orderDetails?.customerPhone || ''}
              </Text>
            </div>
            {orderDetails.customFields &&
              orderDetails.customFields
                .filter((field) => field.value)
                .map((field) => (
                  <div className="lp-w-full mb-16">
                    <Text
                      tagName="p"
                      variant="caption"
                      className="text-dark-50 mt-0"
                      testId="create-neworder-modal-customfield-name"
                    >
                      {field.key}
                    </Text>
                    <Text
                      tagName="p"
                      variant="body-3"
                      className="text-dark mt-0"
                      testId="create-neworder-modal-customfield-value"
                    >
                      {field.value}
                    </Text>
                  </div>
                ))}
            <div className="separator"></div>
            <div className="lp-w-full">
              {itemList && itemList.length > 0 && (
                <OrderItemsTable
                  allowActions={false}
                  showEditButton={false}
                  showTotal={true}
                  displayTaxAmount={displayTax}
                />
              )}
            </div>
          </div>
        </Fragment>
        <div>
          {itemList && itemList.length > 0 && (
            <Checkbox
              id="displayTax"
              label="Display GST"
              checked={displayTax}
              variant="body-3"
              onChange={() => {
                setDisplayTax(!displayTax);
              }}
              testId="create-neworder-modal-displaytax-checkbox"
            ></Checkbox>
          )}
          <Checkbox
            id="request3ds"
            label="Request 3DS on payment"
            checked={request3ds}
            variant="body-3"
            onChange={() => {
              setRequest3ds(!request3ds);
            }}
            testId="create-neworder-modal-request3ds-checkbox"
          ></Checkbox>
        </div>
        <div>
          <Text variant="caption" className="mt-24 mb-4">
            Note: Surcharge is calculated at checkout and may vary by payment method selected.
          </Text>
        </div>
      </div>
      <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
        <div className="lp-flex lp-align-center sm:flex-col sm:justify-end sm:align-end">
          <div className="lp-flex lp-align-center sm:mb-12">
            <Button
              disabled={isModalLoading}
              onClick={() => setActiveStep(ModalSteps.NewOrderDetails)}
              size="medium"
              variant="ghost"
              className="no-min-width w-70"
              testId="create-neworder-modal-edit-button"
            >
              Edit
            </Button>
            <Button
              disabled={!isSendingToCustomer && isModalLoading}
              isLoading={isSendingToCustomer && isModalLoading}
              type="button"
              size="medium"
              variant="primary"
              className="no-min-width"
              onClick={() => {
                sendToCustomer();
              }}
              testId="create-neworder-modal-sendtocustomer-button"
            >
              <Icon name="Filled-Email" className="ml-0 mr-12" />
              Send to customer
            </Button>
          </div>
          <Button
            disabled={!isProcessingNow && isModalLoading}
            isLoading={isProcessingNow && isModalLoading}
            type="button"
            size="medium"
            variant="primary"
            className="no-min-width"
            onClick={() => processNow()}
            testId="create-neworder-modal-processnow-button"
          >
            <Icon name="Filled-CC" className="ml-0 mr-12" />
            Process now
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmOrder;
