import { Fragment, useState } from 'react';

import { Button, Heading, Icon, Text } from '@limepayments/cosmic';

import AddNewItem from './AddNewItem';
import OrderItemsTable from './OrderItemsTable';
import { useCreateOrderContext } from './context';
import { ModalSteps } from './types';

function ManageOrderItems() {
  const { itemList, setActiveStep, modalToggler } = useCreateOrderContext();
  const [isNewItemOpen, setIsNewItemOpen] = useState<boolean>(false);

  const handleAddNewItemButton = () => {
    setIsNewItemOpen(true);
  };

  return (
    <div className="modal-main p-24">
      <div className="modal-header">
        <div className="content-main bb-1">
          <Heading alignment="center" tagName="h2" variant="xs" className="mt-12 mb-24 lp-justify-center lp-flex">
            Build your order
          </Heading>
        </div>
      </div>
      <div className="modal-body h90vh p-28 sm-p-0 sm-mt-24">
        <div>
          <Text tagName="p" variant="body-2" className="lp-neutral-600 mt-0 mb-24">
            Create a breakdown of a customer's order by adding items
          </Text>
          {itemList && itemList.length > 0 && <OrderItemsTable allowActions={true} />}

          {isNewItemOpen && <AddNewItem toggle={setIsNewItemOpen} />}

          {!isNewItemOpen && (
            <Button
              className="no-min-width w-70 mt-12"
              size="medium"
              variant="reversed"
              onClick={handleAddNewItemButton}
              testId="create-neworder-modal-addnew-button"
            >
              Add new item
              <Icon name="Plus" className="ml-16" />
            </Button>
          )}

          {!(itemList && itemList.length > 0) && (
            <Button
              className="no-min-width w-70 mt-24"
              size="medium"
              variant="ghost"
              onClick={() => setActiveStep(ModalSteps.NewOrderDetails)}
              testId="create-neworder-modal-skip-button"
            >
              Skip to order details
              <Icon name="ArrowRight" className="ml-16" />
            </Button>
          )}
        </div>
      </div>
      <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
        <Fragment>
          <Button
            onClick={() => modalToggler(false)}
            size="medium"
            variant="ghost"
            className="no-min-width w-70"
            testId="create-neworder-modal-cancel-button"
          >
            Cancel
          </Button>
          <Button
            type="button"
            size="medium"
            variant="primary"
            className="no-min-width"
            onClick={() => setActiveStep(ModalSteps.NewOrderDetails)}
            testId="create-neworder-modal-next-button"
          >
            Next
          </Button>
        </Fragment>
      </div>
    </div>
  );
}

export default ManageOrderItems;
