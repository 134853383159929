import { useEffect, useMemo, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { Currency } from 'utils/currency';

import { Button, Icon } from '@limepayments/cosmic';

import CancelPaymentModal from './order-actions/CancelPaymentModal';
import ProcessPaymentModal from './order-actions/ProcessPaymentModal';
import ResendToCustomerModal from './order-actions/ResendToCustomerModal';
import { OrderActionsContext, OrderActionsProps } from './order-actions/context';

interface Props {
  orderId: string;
  amount: number;
  currency: Currency;
  customerEmail?: string;
  customerPhoneNumber?: string;
  customerName?: string;
  cancelOrder: (orderId: string) => Promise<void>;
  fetchOrderProcessUrl: (orderId: string) => Promise<string>;
  sendOrderComms: OrderActionsProps['sendOrderComms'];
}

const ActionsPopover = ({
  orderId,
  amount,
  currency,
  customerEmail,
  customerPhoneNumber,
  customerName,
  cancelOrder,
  fetchOrderProcessUrl,
  sendOrderComms,
}: Props) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [showProcessModal, setShowProcessModal] = useState<boolean>(false);
  const [showResendModal, setShowResendModal] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const contextValues = useMemo(
    () => ({
      orderId,
      amount,
      currency,
      customerEmail,
      customerPhoneNumber,
      customerName,
      cancelOrder,
      fetchOrderProcessUrl,
      sendOrderComms,
    }),
    [
      amount,
      cancelOrder,
      currency,
      customerEmail,
      customerPhoneNumber,
      customerName,
      fetchOrderProcessUrl,
      orderId,
      sendOrderComms,
    ],
  );

  useEffect(() => {
    if (isPopoverOpen && (showProcessModal || showResendModal || showCancelModal)) {
      setIsPopoverOpen(false);
    }
  }, [isPopoverOpen, showCancelModal, showProcessModal, showResendModal]);

  return (
    <>
      <OrderActionsContext.Provider value={contextValues}>
        <Popover
          isOpen={isPopoverOpen}
          align={'end'}
          positions={['bottom', 'top', 'left', 'right']}
          reposition={false}
          onClickOutside={() => setIsPopoverOpen(false)}
          content={
            <div className="t-action-dropdown w-204">
              <div className="lp-flex lp-flex-column">
                <div className="t-action-dropdown-body">
                  <button
                    className="lp-flex lp-align-center lp-w-full"
                    onClick={() => setShowProcessModal(!showProcessModal)}
                  >
                    <Icon name="Card" className="mr-6" />
                    Process payment now
                  </button>
                  {customerEmail && (
                    <button
                      className="lp-flex lp-align-center lp-w-full"
                      onClick={() => setShowResendModal(!showResendModal)}
                    >
                      <Icon name="Filled-Email" className="mr-6" />
                      Resend to customer
                    </button>
                  )}
                  <button
                    className="lp-flex lp-align-center lp-w-full"
                    onClick={() => setShowCancelModal(!showCancelModal)}
                  >
                    <Icon name="Cross" className="mr-6" />
                    Cancel payment
                  </button>
                </div>
              </div>
            </div>
          }
        >
          <div className="table-actions" onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
            <Button className="lp-w-full" size="small" variant="reversed">
              <Icon name="Ellipse" className="m-0" />
            </Button>
          </div>
        </Popover>
        <>
          {/* Modals UI(s) */}
          {showProcessModal && <ProcessPaymentModal modalToggler={setShowProcessModal} />}
          {customerEmail && showResendModal && <ResendToCustomerModal modalToggler={setShowResendModal} />}
          {showCancelModal && <CancelPaymentModal modalToggler={setShowCancelModal} />}
        </>
      </OrderActionsContext.Provider>
    </>
  );
};

export default ActionsPopover;
