import { isPossiblePhoneNumber } from 'react-phone-number-input';
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  contactPerson: Yup.object().shape({
    jobTitle: Yup.string().trim().required('This field is required'),
    firstName: Yup.string().trim().required('This field is required'),
    lastName: Yup.string().trim().required('This field is required'),
    phoneNumber: Yup.string()
      .trim()
      .test({
        name: 'phone-no-validation',
        test: function () {
          const { phoneNumber } = this.parent;
          if (!phoneNumber)
            return this.createError({
              message: `This field is required`,
              path: `contactPerson.phoneNumber`,
            });
          if (!isPossiblePhoneNumber(phoneNumber))
            return this.createError({
              message: `Phone number is invalid`,
              path: `contactPerson.phoneNumber`,
            });
          return true;
        },
      }),
    email: Yup.string().trim().email('Please enter valid email address').required('This field is required'),
  }),
  businessAddressManual: Yup.string().when('isManualEnterAddress', {
    is: false,
    then: Yup.string().trim().required('This field is required'),
  }),
  businessAddress: Yup.object().shape({
    city: Yup.string()
      .when('country', {
        is: 'NZ',
        then: Yup.string().nullable().trim().required('This field is required'),
      })
      .nullable(),
    state: Yup.string()
      .when('country', {
        is: 'AU',
        then: Yup.string().nullable().trim().required('This field is required'),
      })
      .nullable(),
    postalCode: Yup.string()
      .trim()
      .required('This field is required')
      .matches(/^[0-9]+$/, 'Only digits are allowed for this field'),
    streetNumber: Yup.string().trim().required('This field is required'),
    streetName: Yup.string().trim().required('This field is required'),
  }),
});
