import { updatePayoutSchedule } from 'api/merchant/dashboard/settings/payouts';
import * as s from 'assets/styles/Platforms.styles';
import { Formik, FormikProps } from 'formik';
import { useState } from 'react';
import { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/merchant/hooks';
import getErrorMessage from 'utils/getErrorMessage';
import * as Yup from 'yup';

import { Button, Heading, Message, Modal, RadioGroup } from '@limepayments/cosmic';

import { PayoutSchedulePayload } from '../types';

const validationSchema = Yup.object().shape({
  payoutValue: Yup.string().trim().required('This field is required'),
});

interface Props {
  isOpen: boolean;
  modalToggler: (val: boolean) => void;
  payoutValue: string;
  refreshDataHandler: () => void;
}

function PayoutScheduleModal({ isOpen, modalToggler, payoutValue, refreshDataHandler }: Props) {
  const errorContainerRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<FormikProps<PayoutSchedulePayload>>(null);
  const [editRequestLoading, setEditRequestLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [initialValues, setInitialValues] = useState<PayoutSchedulePayload>({
    payoutValue: payoutValue,
  });

  const { apiBaseUri, merchantId } = useAppSelector((state) => ({
    apiBaseUri: state.config.apiBaseUri,
    merchantId: state.config.merchantId,
  }));

  const submitHandler = async (values: PayoutSchedulePayload, formObj: { resetForm: () => void }) => {
    try {
      setEditRequestLoading(true);
      setErrorMsg('');

      await updatePayoutSchedule(apiBaseUri, merchantId, values.payoutValue);

      toast.success('Payout frequency updated successfully');

      formObj.resetForm();
      modalToggler(false);
      refreshDataHandler();
    } catch (error) {
      setErrorMsg(getErrorMessage(error));
    } finally {
      setEditRequestLoading(false);
    }
  };

  useEffect(() => {
    if (errorMsg.length > 0 && errorContainerRef && errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView();
    }
  }, [errorMsg]);

  useEffect(() => {
    if (isOpen) {
      setInitialValues({
        payoutValue,
      });
      setErrorMsg('');
    }
  }, [isOpen, payoutValue]);

  return (
    <Modal isOpen={isOpen} className="modal-medium update-pass-modal" testId="payout-schedule-modal">
      <s.Wrapper className="">
        <div className="modal-main p-24">
          <div className="modal-header">
            <div className="content-main bb-1">
              <Heading alignment="center" tagName="h2" variant="xs" className="mt-12 mb-24 lp-justify-center lp-flex">
                Payout frequency
              </Heading>
            </div>
          </div>

          <Formik<PayoutSchedulePayload>
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values, formObj: { resetForm: () => void }) => {
              submitHandler(values, formObj);
            }}
            validationSchema={validationSchema}
            innerRef={formRef}
          >
            {({ values, handleSubmit, errors, touched, setFieldValue }) => (
              <form autoComplete="off" onSubmit={handleSubmit} className="content-wrap-form">
                <div className="modal-content p-28 sm-p-0 sm-mt-24">
                  {errorMsg.length > 0 && (
                    <div className="mt-24 mb-24" ref={errorContainerRef}>
                      <Message
                        type="inline"
                        children={<span className="text-wrap">{errorMsg}</span>}
                        variant={'error'}
                      />
                    </div>
                  )}

                  <div className="equal-radio-btn mb-0" data-testid="payout-schedule-modal-frequency">
                    <RadioGroup
                      defaultValue={values.payoutValue}
                      description="Set the frequency to automatically receive payouts."
                      header="Frequency"
                      name="nationality"
                      onChange={(value) => setFieldValue('payoutValue', value)}
                      orientation="horizontal"
                      radios={[
                        {
                          text: 'Daily',
                          value: 'daily',
                        },
                        {
                          text: 'Weekly',
                          value: 'weekly',
                        },
                        {
                          text: 'Monthly',
                          value: 'monthly',
                        },
                      ]}
                      errorMessage={errors.payoutValue && touched.payoutValue ? errors.payoutValue : ''}
                    />
                  </div>
                </div>

                <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
                  <Button
                    size="medium"
                    variant="ghost"
                    className="no-min-width w-70"
                    onClick={() => modalToggler(false)}
                    disabled={editRequestLoading}
                    testId="payout-schedule-modal-cancel-button"
                  >
                    Cancel
                  </Button>
                  <Button
                    size="medium"
                    variant="primary"
                    className="no-min-width"
                    type="submit"
                    disabled={editRequestLoading}
                    isLoading={editRequestLoading}
                    testId="payout-schedule-modal-submit-button"
                  >
                    Save
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </s.Wrapper>
    </Modal>
  );
}

export default PayoutScheduleModal;
