import {
  CheckoutApplePayAPIResponse,
  PaymentConfirmationAPIResponse,
} from 'api/merchant/dashboard/settings/payment.types';
import ErrorComponent from 'pages/common/error/ErrorComponent';
import { Fragment, useState } from 'react';

import { Button, Heading, Icon, LabelledValue, Link, Message, Spinner, Text } from '@limepayments/cosmic';

import * as s from '../../../../../../assets/styles/Platforms.styles';
import SettingRoutesList from '../../constants';
// Import Static UIs
import SettingsSidebar from '../../partials/SettingsSidebar';
import ApplePayModal from './ApplePayModal';
import PaymentConfirmationsModal from './PaymentConfirmationsModal';
import PaymentSourceModal from './PaymentSourceModal';

// import { useAppSelector } from 'redux/merchant/hooks';

export interface Props {
  paymentConfirmationObj: PaymentConfirmationAPIResponse;
  paymentSourceObj: CheckoutApplePayAPIResponse;
  setPaymentConfirmationEditModalOpen: (val: boolean) => void;
  paymentConfirmationEditModalOpen: boolean;
  setPaymentSourceEditModalOpen: (val: boolean) => void;
  paymentSourceEditModalOpen: boolean;
  isPageLoading: boolean;
  errorMessage: string;
  paymentConfirmationRefreshDataHandler: () => void;
  paymentConfirmationDetailFetchLoader: boolean;
  paymentSourceRefreshDataHandler: () => void;
  paymentSourceDetailFetchLoader: boolean;
  paymentConfirmationModal?: boolean;
  paymentSourceModal?: boolean;
  applePayModal?: boolean;
}

function PaymentSettingView({
  paymentConfirmationObj,
  paymentSourceObj,
  setPaymentConfirmationEditModalOpen,
  paymentConfirmationEditModalOpen,
  setPaymentSourceEditModalOpen,
  paymentSourceEditModalOpen,
  isPageLoading,
  errorMessage,
  paymentConfirmationRefreshDataHandler,
  paymentConfirmationDetailFetchLoader,
  paymentSourceRefreshDataHandler,
  paymentSourceDetailFetchLoader,
  applePayModal,
}: Props) {
  const [showApplePayModal, setApplePayModal] = useState<boolean>(applePayModal ?? false);

  // const { branding } = useAppSelector((state) => ({
  //   branding: state.config.branding,
  // }));

  // const supportEmail = branding?.marketplaceSupportEmail ?? 'support@meetapril.com';

  return (
    <Fragment>
      <div className="content-wrap-inner with-gutter">
        <div className="lp-w-full lp-flex">
          <div className="payment-sidebar">
            <SettingsSidebar activeTab={SettingRoutesList.SETTINGS_PAYMENT} />
          </div>

          <div className="payment-content-area">
            {!isPageLoading && errorMessage.length ? <ErrorComponent bodyText={errorMessage} /> : null}

            {isPageLoading && (
              <div className="spinner-wrapper">
                <Spinner variant="simple" isVisible label="Loading..." />
              </div>
            )}

            {!isPageLoading && !errorMessage.length && (
              <Fragment>
                <div className="lp-w-full lp-flex lp-justify-between sm-lp-flex-col mb-32">
                  <Heading alignment="left" tagName="h2" variant="sm">
                    Payments
                  </Heading>
                </div>

                <s.MerchantsData className="bt-1">
                  <div className="lp-w-full lp-flex lp-align-start lp-justify-between mb-32">
                    <div>
                      <Heading alignment="left" tagName="h2" variant="xs">
                        Payment confirmations
                      </Heading>
                      <Text variant="body-3" className="mt-4">
                        After a new transaction is processed, a payment notification will be sent to nominated emails.
                      </Text>
                    </div>
                    <div className="ml-auto">
                      {!paymentConfirmationDetailFetchLoader && (
                        <Button
                          size="small"
                          variant="reversed"
                          onClick={() => setPaymentConfirmationEditModalOpen(!paymentConfirmationEditModalOpen)}
                          testId="settings-payments-notificationemails-edit-button"
                        >
                          Edit
                          <Icon name="Edit" />
                        </Button>
                      )}
                    </div>
                  </div>

                  {paymentConfirmationDetailFetchLoader && (
                    <div className="spinner-wrapper">
                      <Spinner variant="simple" isVisible label="Loading..." />
                    </div>
                  )}

                  {!paymentConfirmationDetailFetchLoader && (
                    <Fragment>
                      <div className="lp-w-full mb-32">
                        <LabelledValue
                          label="Receive notifications via email"
                          value={paymentConfirmationObj.orderPaidNotificationsEnabled ? `Enabled` : `Disabled`}
                          variant="vertical"
                          testId="settings-payments-notificationemailsenabled"
                        />
                      </div>

                      <div className="lp-w-full mb-32">
                        <div className="lp-flex lp-align-center">
                          <Text variant="caption" className="text-dark-50 m-0">
                            Recipients
                          </Text>
                        </div>
                        <div data-testid="settings-payments-notificationemails">
                          {paymentConfirmationObj.orderPaidNotificationEmails &&
                          paymentConfirmationObj.orderPaidNotificationEmails.length > 0
                            ? paymentConfirmationObj.orderPaidNotificationEmails.map((obj, key) => (
                                <Text variant="body-3" className="mt-0" key={key}>
                                  {obj}
                                </Text>
                              ))
                            : null}
                        </div>
                      </div>
                    </Fragment>
                  )}
                </s.MerchantsData>

                <s.MerchantsData className="bt-1">
                  <div className="lp-w-full lp-flex lp-align-start lp-justify-between mb-32">
                    <div>
                      <Heading alignment="left" tagName="h2" variant="xs">
                        Payment sources
                      </Heading>
                      <Text variant="body-3" className="mt-4">
                        Sources available for your customers to fund their transaction.
                      </Text>
                    </div>
                    <div className="ml-auto">
                      {!paymentSourceDetailFetchLoader && (
                        <Button
                          size="small"
                          variant="reversed"
                          onClick={() => setPaymentSourceEditModalOpen(!paymentSourceEditModalOpen)}
                          testId="settings-payments-paymentsources-edit-button"
                        >
                          Edit
                          <Icon name="Edit" />
                        </Button>
                      )}
                    </div>
                  </div>

                  {paymentSourceDetailFetchLoader && (
                    <div className="spinner-wrapper">
                      <Spinner variant="simple" isVisible label="Loading..." />
                    </div>
                  )}

                  {!paymentSourceDetailFetchLoader && (
                    <Fragment>
                      {/* 
                      TODO:  Credit/Debit card option to be imeplemented when APIs are available
                      
                      <div className="lp-w-full mb-32">
                        <LabelledValue label="Debit / Credit card" value="Enabled" variant="vertical" />
                      </div> */}

                      <div className="lp-w-full mb-32">
                        <div className="lp-flex lp-align-center">
                          <Text variant="caption" className="text-dark-50 m-0">
                            Apple Pay™
                          </Text>
                        </div>
                        {paymentSourceObj.domainNames.length > 0 ? (
                          <Fragment>
                            <Text variant="body-3" className="mt-0" testId="settings-payments-applepaystatus">
                              Enabled for
                            </Text>
                            <div data-testid="settings-payments-applepaydomains">
                              {paymentSourceObj.domainNames.map((obj, key) => (
                                <Text variant="body-3" className="mt-0" key={key}>
                                  {obj}
                                </Text>
                              ))}
                            </div>
                          </Fragment>
                        ) : (
                          <Text variant="body-3" className="mt-0" testId="settings-payments-applepaystatus">
                            Disabled
                          </Text>
                        )}
                      </div>

                      <div className="lp-w-full lp-message mb-32">
                        <Message dismissalType="none" type="inline" variant="warning">
                          <Text variant="caption" className="mb-0 mt-4">
                            Merchants are required to host an Apple Pay domain verification file for each registered
                            domain.
                            <strong className="ml-4">
                              <Link
                                onClick={() => setApplePayModal(!showApplePayModal)}
                                target="_self"
                                testId="settings-payments-applepaymodal-button"
                              >
                                Learn more
                              </Link>
                            </strong>
                          </Text>
                        </Message>
                      </div>

                      {/* 
                      TODO: GooglePay, Buy now Pay later, Direct debit, PayTo options to be imeplemented when APIs are available

                      <div className="lp-w-full mb-32">
                        <LabelledValue label="Google Pay™" value="Enabled" variant="vertical" />
                      </div>

                      <div className="lp-w-full mb-32">
                        <LabelledValue label="Buy now, Pay later" value="Enabled" variant="vertical" />
                      </div>

                      <div className="lp-w-full mb-32">
                        <LabelledValue label="Direct Debit" value="Enabled" variant="vertical" />
                      </div>

                      <div className="lp-w-full mb-32">
                        <LabelledValue label="PayTo" value="Enabled" variant="vertical" />
                      </div> */}
                    </Fragment>
                  )}
                </s.MerchantsData>

                {/* 
                TODO: Payment method details to be imeplemented when APIs are available

                <s.MerchantsData className="bt-1">
                  <div className="lp-w-full lp-flex lp-align-start lp-justify-between sm:flex-col mb-32">
                    <div>
                      <Heading alignment="left" tagName="h2" variant="xs">
                        Payment methods
                      </Heading>
                      <Text variant="body-3" className="mt-4">
                        Methods available for your customers to pay.
                      </Text>
                    </div>
                    <div className="ml-auto sm:ml-0 sm:mt-24">
                      <div className="lp-message">
                        <Message dismissalType="none" type="inline" variant="information">
                          <Text variant="caption" className="mb-0 mt-4">
                            To learn more about the payment methods we provide, please
                            <strong className="ml-4">
                              <Link target="_self" href={`mailto:${supportEmail}`}>
                                contact support
                              </Link>
                            </strong>
                            .
                          </Text>
                        </Message>
                      </div>
                    </div>
                  </div>
                  <div className="lp-w-full mb-32">
                    <LabelledValue label="Full payment" value="Enabled" variant="vertical" />
                  </div>
                  <div className="lp-w-full mb-32">
                    <LabelledValue label="Buy now, Pay later" value="Enabled" variant="vertical" />
                  </div>
                  <div className="lp-w-full mb-32">
                    <LabelledValue label="$0 upfront and 100% after 7 weeks" value="Enabled" variant="vertical" />
                  </div>
                </s.MerchantsData> */}
              </Fragment>
            )}
          </div>
        </div>
      </div>

      <PaymentConfirmationsModal
        modalToggler={setPaymentConfirmationEditModalOpen}
        isOpen={paymentConfirmationEditModalOpen}
        paymentConfirmationObj={paymentConfirmationObj}
        refreshDataHandler={paymentConfirmationRefreshDataHandler}
      />

      <PaymentSourceModal
        modalToggler={setPaymentSourceEditModalOpen}
        isOpen={paymentSourceEditModalOpen}
        paymentSourceObj={paymentSourceObj}
        refreshDataHandler={paymentSourceRefreshDataHandler}
      />

      <ApplePayModal modalToggler={setApplePayModal} isOpen={showApplePayModal} />
    </Fragment>
  );
}

export default PaymentSettingView;
