import { PayoutDetailAPIResponseType } from 'api/merchant/dashboard/payouts.types';
import { DateTime } from 'luxon';
import ErrorComponent from 'pages/common/error/ErrorComponent';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { toCurrency } from 'utils/currency';

import { Button, Heading, Icon, Spinner, Text } from '@limepayments/cosmic';

import { PayoutStatusObj } from '../constants';
import StatusTag from '../listing/partials/StatusTagComponent';
import PayoutCSVDownloadModal from '../partials/PayoutCSVDownloadModal';
// Import Static UIs
import AccountDetails from './partials/AccountDetails';
import PayoutErrorComponent from './partials/ErrorComponent';
import SummaryTable from './partials/SummaryTable';

export interface Props {
  onBackButtonClick: () => void;
  detailErrorMsg: string;
  showDetailLoader: boolean;
  payoutDetailObj: PayoutDetailAPIResponseType | null;
  showPayoutReportModal?: boolean;
}

function PayoutDetails({
  onBackButtonClick,
  detailErrorMsg,
  showDetailLoader,
  payoutDetailObj,
  showPayoutReportModal,
}: Props) {
  const [showDownloadModal, setShowDownloadModal] = useState(showPayoutReportModal ?? false);

  useEffect(() => {
    if (!showDetailLoader && payoutDetailObj) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [showDetailLoader, payoutDetailObj]);

  return (
    <>
      <Helmet>
        <title>Payout Detail</title>
      </Helmet>
      <div className="content-wrap-inner lp-full">
        <div className="header-back mb-28">
          <Button onClick={onBackButtonClick} variant="ghost" size={'small'} testId="payout-detail-back-button">
            <Icon name="ArrowLeft" className="mr-8" />
            Back
          </Button>
        </div>

        {!showDetailLoader && detailErrorMsg.length ? (
          <>
            <ErrorComponent bodyText={detailErrorMsg} />
            <PayoutErrorComponent
              title="Unable to load payout details"
              body={`Sorry, we're unable to load the details for this payout at the moment, please try again later.`}
            />
          </>
        ) : null}

        {showDetailLoader && (
          <div className="spinner-wrapper">
            <Spinner variant="simple" isVisible label="Loading..." />
          </div>
        )}

        {!showDetailLoader && !detailErrorMsg.length && !!payoutDetailObj && (
          <>
            <Heading alignment="left" tagName="h2" variant="xxs" className="page-title text-muted mb-4">
              Payouts
            </Heading>
            <div className="header-details pb-16 md:flex-col md:lp-full">
              <div className="lp-flex md:flex-col md:lp-full">
                <div className="header-details-inner md:mb-16">
                  <Heading alignment="left" tagName="h2" variant="sm" className="mb-0 mr-16">
                    {toCurrency(payoutDetailObj.summary.amount, payoutDetailObj.summary.currency)}{' '}
                    {payoutDetailObj.summary.currency}
                  </Heading>
                  <StatusTag
                    label={PayoutStatusObj[payoutDetailObj.summary.status as keyof typeof PayoutStatusObj].label}
                    variant={PayoutStatusObj[payoutDetailObj.summary.status as keyof typeof PayoutStatusObj].varient}
                    tooltipDirection="up"
                    tooltipText={
                      PayoutStatusObj[payoutDetailObj.summary.status as keyof typeof PayoutStatusObj].tooltipText
                    }
                  />
                </div>
                <div className="lp-flex md:flex-col">
                  <div className="stacked-data md-stacked-data">
                    <Text tagName="p" variant="caption" className="text-dark-50 m-0">
                      Payout ID
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0" isEmphasised>
                      {payoutDetailObj.summary.payoutId}
                    </Text>
                  </div>
                  <div className="stacked-data md-stacked-data">
                    <Text tagName="p" variant="caption" className="text-dark-50 m-0">
                      Created on
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0" isEmphasised>
                      {DateTime.fromISO(payoutDetailObj.summary.createdAt).toFormat('dd/LL/yyyy, HH:mm')}
                    </Text>
                  </div>
                  <div className="stacked-data md-stacked-data">
                    <Text tagName="p" variant="caption" className="text-dark-50 m-0">
                      Available on
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0" isEmphasised>
                      {payoutDetailObj.summary.settlementDate
                        ? DateTime.fromISO(payoutDetailObj.summary.settlementDate).toFormat('dd/LL/yyyy, HH:mm')
                        : '-'}
                    </Text>
                  </div>
                </div>
              </div>
              <div className="ml-auto md:ml-0  md:lp-full md:mt-24">
                <Button
                  onClick={() => setShowDownloadModal(!showDownloadModal)}
                  variant="reversed"
                  size={'small'}
                  className="md:lp-full"
                  testId="payout-download-button"
                >
                  <Icon name="Download" className="mr-8 ml-0" /> Download payout report
                </Button>
              </div>
            </div>

            {!!payoutDetailObj.summaryLineItems && (
              <SummaryTable
                summaryLineItems={payoutDetailObj.summaryLineItems}
                total={payoutDetailObj.summary.amount}
                currency={payoutDetailObj.summary.currency}
              />
            )}
            {!!payoutDetailObj.accountDetails && <AccountDetails accountDetails={payoutDetailObj.accountDetails} />}
          </>
        )}
      </div>

      {/* Modals UI(s) */}
      <PayoutCSVDownloadModal
        modalToggler={setShowDownloadModal}
        isOpen={showDownloadModal}
        payoutId={payoutDetailObj?.summary.payoutId}
        payoutAmount={payoutDetailObj?.summary.amount}
        currency={payoutDetailObj?.summary.currency}
        availableOn={payoutDetailObj?.summary.settlementDate}
      />
    </>
  );
}

export default PayoutDetails;
