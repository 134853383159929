import * as s from 'assets/styles/Platforms.styles';
import { useEffect, useRef, useState } from 'react';
import getErrorMessage from 'utils/getErrorMessage';

import { Button, Heading, Illustration, Message, Modal, Text } from '@limepayments/cosmic';

import { useOrderActionsContext } from './context';

interface Props {
  modalToggler: (showResendModal: boolean) => void;
}

function ResendToCustomerModal({ modalToggler }: Props) {
  const { orderId, customerEmail, customerPhoneNumber, sendOrderComms } = useOrderActionsContext();

  const [isModalLoading, setIsModalLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const errorContainerRef = useRef<HTMLDivElement>(null);

  const handleResend = () => {
    setIsModalLoading(true);
    setErrorMessage('');
    sendOrderComms(orderId, !!customerPhoneNumber)
      .then(() => {
        modalToggler(false);
      })
      .catch((error: Error) => {
        setErrorMessage(getErrorMessage(error));
      })
      .finally(() => {
        setIsModalLoading(false);
      });
  };

  useEffect(() => {
    if (errorMessage.length > 0 && errorContainerRef && errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView();
    }
  }, [errorMessage]);

  return (
    <Modal isOpen={true} className="modal-small update-pass-modal" testId="resend-to-customer-modal">
      <s.Wrapper className="">
        <div className="modal-main p-24">
          <div className="modal-header">
            <div className="content-main bb-1">
              <Heading alignment="center" tagName="h2" variant="xs" className="mt-12 mb-24 lp-justify-center lp-flex">
                Resend to customer
              </Heading>
            </div>
          </div>
          <div className="modal-body mb-16">
            {errorMessage.length > 0 && (
              <div className="mt-24 mb-24" ref={errorContainerRef}>
                <Message type="inline" children={<span className="text-wrap">{errorMessage}</span>} variant={'error'} />
              </div>
            )}
            <div className="mx-auto illustration-90 mt-12">
              <Illustration name="Email" size="medium" />
            </div>
            <Text alignment="center" tagName="p" variant="body-3" className="lp-neutral-600">
              Are you sure you want to resend the payment link to <strong>{customerEmail}</strong>?
            </Text>
          </div>
          <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
            <Button
              type="button"
              onClick={() => {
                modalToggler(false);
              }}
              disabled={isModalLoading}
              size="medium"
              variant="ghost"
              className="no-min-width w-70"
              testId="resend-to-customer-modal-cancel-button"
            >
              Cancel
            </Button>
            <Button
              onClick={handleResend}
              isLoading={isModalLoading}
              type="submit"
              size="medium"
              variant="primary"
              className="no-min-width"
              testId="resend-to-customer-modal-submit-button"
            >
              Send
            </Button>
          </div>
        </div>
      </s.Wrapper>
    </Modal>
  );
}

export default ResendToCustomerModal;
