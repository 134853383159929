import { PayType } from 'api/graphql/generated/graphql';
import {
  AmountFilter,
  CustomerFilter,
  DateFilter,
  FilterDropdown,
  OrderFilter,
  TransactionFilter,
} from 'pages/common/filters';
import { TransactionStatusFilter } from 'pages/common/filters/TransactionStatusFilter';
import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/merchant/hooks';
import { setPaymentFilterObj } from 'redux/merchant/slice/paymentSlice';

import { Button, Checkbox, CheckboxGroup, Heading, Icon, Text } from '@limepayments/cosmic';

import { PaymentFilterPaymentOptionObj } from '../../constants';
import { getInitialErrorState, getInitialFilterState } from '../initialStates';
import { IPaymentFilterObj, IPaymentFilterObjError, hasFilterError } from '../types';

interface Props {
  modalToggler(): void;
  handlePageChange(page: number): void;
}

function FilterComponent({ modalToggler, handlePageChange }: Props) {
  const { config, paymentFilterObj } = useAppSelector((state) => ({
    config: state.config,
    paymentFilterObj: state.payment.paymentFilterObj,
  }));

  const [filterObj, setFilterObj] = useState<IPaymentFilterObj>(paymentFilterObj);
  const [filterObjError, setFilterObjError] = useState<IPaymentFilterObjError>(getInitialErrorState());

  const dispatch = useAppDispatch();

  const filterChangeHandler = useCallback(
    (filterObj: Partial<IPaymentFilterObj>) =>
      setFilterObj((prevState) => ({
        ...prevState,
        ...filterObj,
      })),
    [],
  );

  const validate = useCallback(() => {
    const filterObjError = getInitialErrorState();
    const isRequiredError = 'This field is required';

    if (filterObj.amountChecked && !filterObj.amount) {
      filterObjError.amountError = isRequiredError;
    }
    if (filterObj.statusChecked && !filterObj.status) {
      filterObjError.statusError = isRequiredError;
    }
    if (filterObj.customerChecked && !filterObj.customer) {
      filterObjError.customerError = isRequiredError;
    }
    if (filterObj.paymentOptionChecked && !filterObj.paymentOption) {
      filterObjError.paymentOptionError = isRequiredError;
    }
    if (filterObj.orderIdChecked && !filterObj.orderId) {
      filterObjError.orderIdError = isRequiredError;
    }
    if (filterObj.transactionIdChecked && !filterObj.transactionId) {
      filterObjError.transactionIdError = isRequiredError;
    }
    if (filterObj.createdOnChecked && !filterObj.createdOn) {
      filterObjError.createdOnError = isRequiredError;
    }
    if (filterObj.availableOnChecked && !filterObj.availableOn) {
      filterObjError.availableOnError = isRequiredError;
    }

    setFilterObjError(filterObjError);
    return filterObjError;
  }, [filterObj]);

  const filterSaveHandler = useCallback(async () => {
    if (hasFilterError(validate())) return;

    dispatch(setPaymentFilterObj(filterObj));
    handlePageChange(1);
    modalToggler();
  }, [dispatch, filterObj, validate, modalToggler, handlePageChange]);

  const clearFunction = useCallback(() => {
    setFilterObj(getInitialFilterState());
  }, []);

  return (
    <FilterDropdown className="w-328">
      <div className="lp-flex lp-flex-column">
        <div className="filter-header">
          <Heading alignment="left" tagName="h2" variant="xs" className="mb-0">
            Filters
          </Heading>
          <Button
            type="button"
            onClick={() => modalToggler()}
            size="small"
            variant="ghost"
            className="button-close m-0 p-0"
          >
            <Icon name="Cross" className="m-0 p-0" />
          </Button>
        </div>
        <div className="filter-body">
          <CheckboxGroup>
            <div className="checkbox-items" data-testid="payment-filter-amount">
              <Checkbox
                id="amountChecked"
                testId="payment-filter-amount-check"
                label="Amount"
                variant="body-2"
                checked={filterObj.amountChecked}
                onChange={() =>
                  filterChangeHandler({
                    amountChecked: !filterObj.amountChecked,
                    amount: null,
                  })
                }
              />
              {filterObj.amountChecked && (
                <div className="checkbox-items-details">
                  <AmountFilter
                    value={filterObj.amount}
                    onChange={(amount) => filterChangeHandler({ amount: amount })}
                  />
                  {filterObjError.amountError && (
                    <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                      {filterObjError.amountError}
                    </Text>
                  )}
                </div>
              )}
            </div>

            <div className="checkbox-items" data-testid="payment-filter-status">
              <Checkbox
                id="statusChecked"
                testId="payment-filter-status-check"
                label="Status"
                variant="body-2"
                checked={filterObj.statusChecked}
                onChange={() =>
                  filterChangeHandler({
                    statusChecked: !filterObj.statusChecked,
                    status: null,
                  })
                }
              />
              {filterObj.statusChecked && (
                <div className="checkbox-items-details">
                  <TransactionStatusFilter
                    value={filterObj.status ?? []}
                    onChange={(value) => filterChangeHandler({ status: value.length ? value : null })}
                  />
                  {filterObjError.statusError && (
                    <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                      {filterObjError.statusError}
                    </Text>
                  )}
                </div>
              )}
            </div>

            <div className="checkbox-items" data-testid="payment-filter-customer">
              <Checkbox
                id="customerChecked"
                testId="payment-filter-customer-check"
                label="Customer"
                variant="body-2"
                checked={filterObj.customerChecked}
                onChange={() =>
                  filterChangeHandler({
                    customerChecked: !filterObj.customerChecked,
                    customer: null,
                  })
                }
              />
              {filterObj.customerChecked && (
                <div className="checkbox-items-details">
                  <CustomerFilter
                    merchantIds={config.merchantId}
                    value={filterObj.customer}
                    onChange={(customer) => filterChangeHandler({ customer })}
                  />
                  {filterObjError.customerError && (
                    <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                      {filterObjError.customerError}
                    </Text>
                  )}
                </div>
              )}
            </div>

            <div className="checkbox-items" data-testid="payment-filter-payment-option">
              <Checkbox
                id="paymentOptionChecked"
                testId="payment-filter-payment-option-check"
                label="Payment option"
                variant="body-2"
                checked={filterObj.paymentOptionChecked}
                onChange={() =>
                  filterChangeHandler({
                    paymentOptionChecked: !filterObj.paymentOptionChecked,
                    paymentOption: null,
                  })
                }
              />
              {filterObj.paymentOptionChecked && (
                <div className="checkbox-items-details">
                  <div className="lp-w-full mb-0">
                    <div className="selectdropdown">
                      <Icon name="ChevronDown" className="arrow-down" />
                      <select
                        className="form-control"
                        data-testid="payment-filter-payment-option-select"
                        value={filterObj.paymentOption ?? ''}
                        onChange={(e) => filterChangeHandler({ paymentOption: (e.target.value as PayType) || null })}
                      >
                        <option value="">please select</option>
                        {Object.values(PaymentFilterPaymentOptionObj).map((obj, key) => (
                          <option value={obj.value} key={key}>
                            {obj.label}
                          </option>
                        ))}
                      </select>
                      {filterObjError.paymentOptionError && (
                        <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                          {filterObjError.paymentOptionError}
                        </Text>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="checkbox-items" data-testid="payment-filter-internal-order-id">
              <Checkbox
                id="orderIdChecked"
                testId="payment-filter-internal-order-id-check"
                label="Order ID"
                variant="body-2"
                checked={filterObj.orderIdChecked}
                onChange={() =>
                  filterChangeHandler({
                    orderIdChecked: !filterObj.orderIdChecked,
                    orderId: null,
                  })
                }
              />
              {filterObj.orderIdChecked && (
                <div className="checkbox-items-details">
                  <OrderFilter
                    merchantIds={config.merchantId}
                    value={filterObj.orderId}
                    onChange={(orderId) => filterChangeHandler({ orderId })}
                  />
                  {filterObjError.orderIdError && (
                    <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                      {filterObjError.orderIdError}
                    </Text>
                  )}
                </div>
              )}
            </div>

            <div className="checkbox-items" data-testid="payment-filter-txn-id">
              <Checkbox
                id="transactionIdChecked"
                testId="payment-filter-txn-id-check"
                label="Transaction ID"
                variant="body-2"
                checked={filterObj.transactionIdChecked}
                onChange={() =>
                  filterChangeHandler({
                    transactionIdChecked: !filterObj.transactionIdChecked,
                    transactionId: null,
                  })
                }
              />
              {filterObj.transactionIdChecked && (
                <div className="checkbox-items-details" data-testid="payment-filter-txn-id-search">
                  <TransactionFilter
                    merchantIds={config.merchantId}
                    value={filterObj.transactionId}
                    onChange={(transactionId) => filterChangeHandler({ transactionId })}
                  />
                  {filterObjError.transactionIdError && (
                    <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                      {filterObjError.transactionIdError}
                    </Text>
                  )}
                </div>
              )}
            </div>

            <div className="checkbox-items" data-testid="payment-filter-created-on">
              <Checkbox
                id="createdOn"
                testId="payment-filter-created-on-check"
                label="Created on"
                variant="body-2"
                checked={filterObj.createdOnChecked}
                onChange={() =>
                  filterChangeHandler({
                    createdOnChecked: !filterObj.createdOnChecked,
                    createdOn: null,
                  })
                }
              />
              {filterObj.createdOnChecked && (
                <div className="checkbox-items-details">
                  <DateFilter
                    value={filterObj.createdOn}
                    onChange={(createdOn) => filterChangeHandler({ createdOn })}
                  />
                  {filterObjError.createdOnError && (
                    <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                      {filterObjError.createdOnError}
                    </Text>
                  )}
                </div>
              )}
            </div>

            <div className="checkbox-items" data-testid="payment-filter-available-on">
              <Checkbox
                id="availableOn"
                testId="payment-filter-available-on-check"
                label="Available on"
                variant="body-2"
                checked={filterObj.availableOnChecked}
                onChange={() =>
                  filterChangeHandler({
                    availableOnChecked: !filterObj.availableOnChecked,
                    availableOn: null,
                  })
                }
              />
              {filterObj.availableOnChecked && (
                <div className="checkbox-items-details">
                  <DateFilter
                    value={filterObj.availableOn}
                    onChange={(availableOn) => filterChangeHandler({ availableOn })}
                  />
                  {filterObjError.availableOnError && (
                    <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                      {filterObjError.availableOnError}
                    </Text>
                  )}
                </div>
              )}
            </div>
          </CheckboxGroup>
        </div>
        <div className="filter-footer">
          <Button
            className="lp-w-full"
            onClick={() => clearFunction()}
            size="medium"
            variant="reversed"
            testId="payment-filter-clear-button"
          >
            Clear
          </Button>
          <Button
            className="lp-w-full"
            onClick={() => filterSaveHandler()}
            size="medium"
            variant="primary"
            testId="payment-filter-save-button"
          >
            Save
          </Button>
        </div>
      </div>
    </FilterDropdown>
  );
}

export default FilterComponent;
