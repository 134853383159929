import { AmountFilter, DateFilter, FilterDropdown, OrderFilter } from 'pages/common/filters';
import { OrderStatusFilter } from 'pages/common/filters/OrderStatusFilter';
import { useCallback, useState } from 'react';
import { useAppSelector } from 'redux/merchant/hooks';

import { Button, Checkbox, CheckboxGroup, Heading, Icon, Text } from '@limepayments/cosmic';

import { getInitialErrorState, getInitialFilterState } from '../initialStates';
import { IVirtualFilterObj, IVirtualFilterObjError, hasFilterError } from '../types';

interface Props {
  filterValuesObj: IVirtualFilterObj;
  setFilterValuesObj(filterObj: IVirtualFilterObj): void;
  toggleFilter: () => void;
  handlePageChange(page: number): void;
}

function FilterComponent({ filterValuesObj, setFilterValuesObj, toggleFilter, handlePageChange }: Props) {
  const [filterObj, setFilterObj] = useState<IVirtualFilterObj>(filterValuesObj);
  const [filterObjError, setFilterObjError] = useState<IVirtualFilterObjError>(getInitialErrorState());

  const { config } = useAppSelector((state) => ({
    config: state.config,
  }));

  const filterChangeHandler = useCallback(
    (filterObj: Partial<IVirtualFilterObj>) =>
      setFilterObj((prevState) => ({
        ...prevState,
        ...filterObj,
      })),
    [],
  );

  const validate = useCallback(() => {
    const filterObjError = getInitialErrorState();
    const isRequiredError = 'This field is required';

    if (filterObj.amountChecked && !filterObj.amount) {
      filterObjError.amountError = isRequiredError;
    }
    if (filterObj.orderIdChecked && !filterObj.orderId) {
      filterObjError.orderIdError = isRequiredError;
    }
    if (filterObj.customerChecked && !filterObj.customer) {
      filterObjError.customerError = isRequiredError;
    }
    if (filterObj.descriptionChecked && !filterObj.description) {
      filterObjError.descriptionError = isRequiredError;
    }
    if (filterObj.requestedOnChecked && !filterObj.requestedOn) {
      filterObjError.requestedOnError = isRequiredError;
    }
    if (filterObj.statusChecked && !filterObj.status) {
      filterObjError.statusError = isRequiredError;
    }

    setFilterObjError(filterObjError);
    return filterObjError;
  }, [filterObj]);

  const filterSaveHandler = useCallback(async () => {
    if (hasFilterError(validate())) return;

    setFilterValuesObj(filterObj);
    handlePageChange(1);
    toggleFilter();
  }, [filterObj, handlePageChange, setFilterValuesObj, toggleFilter, validate]);

  const clearFunction = useCallback(() => {
    setFilterObj(getInitialFilterState());
  }, []);

  return (
    <FilterDropdown className="w-328">
      <div className="lp-flex lp-flex-column" data-testid="virtual-terminal-filter">
        <div className="filter-header mb-0">
          <Heading alignment="left" tagName="h2" variant="xs" className="mb-0">
            Filters
          </Heading>
          <Button
            type="button"
            onClick={() => toggleFilter()}
            size="small"
            variant="ghost"
            className="button-close m-0 p-0"
          >
            <Icon name="Cross" className="m-0 p-0" />
          </Button>
        </div>
        <div className="filter-body">
          <CheckboxGroup>
            <div className="checkbox-items" data-testid="virtual-terminal-filter-amount">
              <Checkbox
                id="amountChecked"
                testId="virtual-terminal-filter-amount-check"
                label="Amount"
                variant="body-2"
                checked={filterObj.amountChecked}
                onChange={() =>
                  filterChangeHandler({
                    amountChecked: !filterObj.amountChecked,
                    amount: null,
                  })
                }
              />
              {filterObj.amountChecked && (
                <div className="checkbox-items-details">
                  <AmountFilter
                    value={filterObj.amount}
                    onChange={(amount) => filterChangeHandler({ amount: amount })}
                  />
                  {filterObjError.amountError && (
                    <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                      {filterObjError.amountError}
                    </Text>
                  )}
                </div>
              )}
            </div>

            <div className="checkbox-items" data-testid="virtual-terminal-filter-internal-order-id">
              <Checkbox
                id="orderIdChecked"
                testId="virtual-terminal-filter-internal-order-id-check"
                label="Order ID"
                variant="body-2"
                checked={filterObj.orderIdChecked}
                onChange={() =>
                  filterChangeHandler({
                    orderIdChecked: !filterObj.orderIdChecked,
                    orderId: null,
                  })
                }
              />
              {filterObj.orderIdChecked && (
                <div className="checkbox-items-details">
                  <OrderFilter
                    merchantIds={config.merchantId}
                    value={filterObj.orderId}
                    onChange={(orderId) => filterChangeHandler({ orderId })}
                  />
                  {filterObjError.orderIdError && (
                    <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                      {filterObjError.orderIdError}
                    </Text>
                  )}
                </div>
              )}
            </div>

            {/* TODO: customer filter */}
            {/* <div className="checkbox-items">
              <Checkbox
                id="customerChecked"
                testId="filter-customer-check"
                label="Customer"
                variant="body-2"
                checked={filterObj.customerChecked}
                onChange={() =>
                  filterChangeHandler({
                    customerChecked: !filterObj.customerChecked,
                    customer: null,
                  })
                }
              />
              {filterObj.customerChecked && (
                <div className="checkbox-items-details">
                  <CustomerFilter
                    merchantIds={config.merchantId}
                    value={filterObj.customer}
                    onChange={(customer) => filterChangeHandler({ customer })}
                  />
                  {filterObjError.customerError && (
                    <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                      {filterObjError.customerError}
                    </Text>
                  )}
                </div>
              )}
            </div> */}

            <div className="checkbox-items" data-testid="virtual-terminal-filter-description">
              <Checkbox
                id="descriptionChecked"
                testId="virtual-terminal-filter-description-check"
                label="Description"
                variant="body-2"
                checked={filterObj.descriptionChecked}
                onChange={() =>
                  filterChangeHandler({
                    descriptionChecked: !filterObj.descriptionChecked,
                    description: null,
                  })
                }
              />
              {filterObj.descriptionChecked && (
                <div className="checkbox-items-details">
                  <OrderFilter
                    merchantIds={config.merchantId}
                    value={filterObj.description}
                    onChange={(description) => filterChangeHandler({ description })}
                    searchDescription
                  />
                  {filterObjError.descriptionError && (
                    <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                      {filterObjError.descriptionError}
                    </Text>
                  )}
                </div>
              )}
            </div>

            <div className="checkbox-items" data-testid="virtual-terminal-filter-requested-on">
              <Checkbox
                id="requestedOn"
                testId="virtual-terminal-filter-requested-on-check"
                label="Requested on"
                variant="body-2"
                checked={filterObj.requestedOnChecked}
                onChange={() =>
                  filterChangeHandler({
                    requestedOnChecked: !filterObj.requestedOnChecked,
                    requestedOn: null,
                  })
                }
              />
              {filterObj.requestedOnChecked && (
                <div className="checkbox-items-details">
                  <DateFilter
                    value={filterObj.requestedOn}
                    onChange={(requestedOn) => filterChangeHandler({ requestedOn })}
                  />
                  {filterObjError.requestedOnError && (
                    <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                      {filterObjError.requestedOnError}
                    </Text>
                  )}
                </div>
              )}
            </div>

            <div className="checkbox-items" data-testid="virtual-terminal-filter-status">
              <Checkbox
                id="statusChecked"
                testId="virtual-terminal-filter-status-check"
                label="Status"
                variant="body-2"
                checked={filterObj.statusChecked}
                onChange={() =>
                  filterChangeHandler({
                    statusChecked: !filterObj.statusChecked,
                    status: null,
                  })
                }
              />
              {filterObj.statusChecked && (
                <div className="checkbox-items-details">
                  <OrderStatusFilter
                    value={filterObj.status ?? []}
                    onChange={(value) => filterChangeHandler({ status: value.length ? value : null })}
                  />
                  {filterObjError.statusError && (
                    <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                      {filterObjError.statusError}
                    </Text>
                  )}
                </div>
              )}
            </div>
          </CheckboxGroup>
        </div>
        <div className="filter-footer">
          <Button
            className="lp-w-full"
            onClick={() => clearFunction()}
            size="medium"
            variant="reversed"
            testId="virtual-terminal-filter-clear-button"
          >
            Clear
          </Button>
          <Button
            className="lp-w-full"
            onClick={() => filterSaveHandler()}
            size="medium"
            variant="primary"
            testId="virtual-terminal-filter-save-button"
          >
            Save
          </Button>
        </div>
      </div>
    </FilterDropdown>
  );
}

export default FilterComponent;
