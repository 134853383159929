import { transactionsQueryDocument } from 'api/graphql';
import { TransactionOrderField } from 'api/graphql/generated/graphql';
import MerchantDashboardLayout from 'layouts/merchant-dashboard/MerchantDashboardLayout';
import ErrorComponent from 'pages/common/error/ErrorComponent';
import { toAmountFilterInput, toInstantFilterInput, toLocalDateFilterInput } from 'pages/common/filters';
import { LISTING_RECORDS_PER_PAGE } from 'pages/platform/constants';
import { useCallback, useMemo, useState } from 'react';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { useAppDispatch, useAppSelector } from 'redux/merchant/hooks';
import { setListingPagePropsObj } from 'redux/merchant/slice/paymentSlice';
import { useQuery } from 'urql';

import { getInitialListingPageState } from './initialStates';
// import FixtureData from './__fixtures__.json';
import PaymentsList from './partials/PaymentsList';
import { TransactionsQueryEdges } from './types';

function PaymentListPage() {
  const dispatch = useAppDispatch();
  const [filterPopupOpen, setFilterPopupOpen] = useState<boolean>(false);

  const { paymentFilterObj, listingPageProps } = useAppSelector((state) => ({
    paymentFilterObj: state.payment.paymentFilterObj,
    listingPageProps: state.payment.listingPageProps,
  }));

  const { first, last, before, after, orderBy } = useMemo(() => listingPageProps, [listingPageProps]);

  const limit = useMemo(() => first ?? last ?? LISTING_RECORDS_PER_PAGE, [first, last]);

  const filters = useMemo(() => {
    const { amount, status, customer, paymentOption, orderId, transactionId, createdOn, availableOn } =
      paymentFilterObj;

    return {
      amount: amount ? toAmountFilterInput(amount) : null,
      status,
      customerIds: customer?.value,
      payType: paymentOption,
      orderIds: orderId?.value,
      transactionIds: transactionId?.value,
      createdAt: createdOn ? toInstantFilterInput(createdOn) : null,
      availableOn: availableOn ? toLocalDateFilterInput(availableOn) : null,
    };
  }, [paymentFilterObj]);

  const [{ fetching, data, error }] = useQuery({
    query: transactionsQueryDocument,
    variables: { last, before, first, after, orderBy, ...filters },
  });

  const handleSort = useCallback(
    (selectedColumn: TableColumn<TransactionsQueryEdges[number]>, sortDirection: SortOrder) => {
      const field = selectedColumn.sortField ? (selectedColumn.sortField as TransactionOrderField) : null;
      const ascending = sortDirection === 'asc';

      dispatch(
        setListingPagePropsObj({
          ...listingPageProps,
          ...getInitialListingPageState(),
          orderBy: field ? { field, ascending } : null,
        }),
      );
    },
    [dispatch, listingPageProps],
  );

  const toggleFilter = useCallback(() => {
    setFilterPopupOpen((wasOpened) => !wasOpened);
  }, []);

  const handlePageChange = useCallback(
    (page: number) => {
      if (!data?.transactions) return;

      const isFirst = page === 1;
      const isPrev = !isFirst && page === listingPageProps.page - 1;
      const isNext = page === listingPageProps.page + 1;
      const isLast = !isFirst && !isNext && page === Math.ceil(data.transactions.totalCount / limit);

      dispatch(
        setListingPagePropsObj({
          ...listingPageProps,
          page,
          last: isLast || isPrev ? limit : null,
          before: !isLast && isPrev ? data.transactions.pageInfo.startCursor ?? null : null,
          first: isFirst || isNext ? limit : null,
          after: !isFirst && isNext ? data.transactions.pageInfo.endCursor ?? null : null,
        }),
      );
    },
    [data?.transactions, dispatch, limit, listingPageProps],
  );

  const handlePerRowsChange = useCallback(
    async (newPerPage: number, page: number) => {
      dispatch(
        setListingPagePropsObj({
          ...listingPageProps,
          page,
          last: listingPageProps.last !== null ? newPerPage : null,
          first: listingPageProps.first !== null ? newPerPage : null,
        }),
      );
    },
    [dispatch, listingPageProps],
  );

  return (
    <MerchantDashboardLayout activeTab="payment" title="Payments Listing">
      {!!error && <ErrorComponent bodyText={error.message} />}
      <PaymentsList
        paymentList={data?.transactions?.edges ?? []}
        fetchListLoader={fetching}
        totalRows={data?.transactions?.totalCount ?? 0}
        handlePerRowsChange={handlePerRowsChange}
        handlePageChange={handlePageChange}
        limit={limit}
        toggleFilter={toggleFilter}
        activePage={listingPageProps.page}
        handleSort={handleSort}
        filterPopupOpen={filterPopupOpen}
      />
    </MerchantDashboardLayout>
  );
}

export default PaymentListPage;
