import {
  StandardMerchant,
  UpdateMerchantBrandingRequest,
  UpdateMerchantBusinessProfileRequest,
} from 'api/merchant/dashboard/merchants.types';

export interface BrandingModalForm {
  tradingName: string;
  logoUrl: string;
  faviconUrl: string;
  logoAltText: string;
  cardStatementName: string;
  smsSenderName: string;
  businessWebsite: string;
  primaryBrandColor: string;
}

type toBrandingModalFormType = (merchantResponse: StandardMerchant) => BrandingModalForm;
type toUpdateMerchantBusinessProfileRequestType = (
  brandingModalFormData: BrandingModalForm,
  standardMerchantResponse: StandardMerchant,
) => UpdateMerchantBusinessProfileRequest;
type toUpdateMerchantBrandingRequestType = (brandingModalFormData: BrandingModalForm) => UpdateMerchantBrandingRequest;

export const toBrandingModalForm: toBrandingModalFormType = ({ businessProfile, branding }) => {
  return {
    tradingName: businessProfile.brandName,
    logoUrl: branding.logoUri || '',
    faviconUrl: branding.iconUri || '',
    logoAltText: branding.logoAltText || '',
    cardStatementName: businessProfile.cardStatementName,
    smsSenderName: businessProfile.smsSenderName,
    businessWebsite: businessProfile.website,
    primaryBrandColor: branding.primaryColor || '',
  };
};

export const toUpdateMerchantBusinessProfileRequest: toUpdateMerchantBusinessProfileRequestType = (
  brandingModalFormData,
  { businessProfile },
) => {
  return {
    UpdateMerchantBusinessProfile: {
      timeZone: businessProfile.timeZone,
      businessName: businessProfile.businessName,
      businessType: businessProfile.businessType,
      companyType: businessProfile.companyType,
      website: brandingModalFormData.businessWebsite,
      supportSite: businessProfile.supportSite,
      industry: businessProfile.industry,
      taxId: businessProfile.taxId,
      contactPerson: businessProfile.contactPerson,
      contactEmailAddress: businessProfile.contactEmailAddress,
      supportEmailAddress: businessProfile.supportEmailAddress,
      phoneNumber: businessProfile.phoneNumber,
      address: businessProfile.address,
      brandName: businessProfile.brandName,
      cardStatementName: brandingModalFormData.cardStatementName,
      smsSenderName: brandingModalFormData.smsSenderName,
    },
  };
};

export const toUpdateMerchantBrandingRequest: toUpdateMerchantBrandingRequestType = (brandingModalFormData) => ({
  UpdateMerchantBranding: {
    branding: {
      iconUri: brandingModalFormData.faviconUrl,
      logoUri: brandingModalFormData.logoUrl,
      logoAltText: brandingModalFormData.logoAltText,
      primaryColor: brandingModalFormData.primaryBrandColor,
    },
  },
});
