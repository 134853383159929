import { PayoutStatus } from 'api/graphql/generated/graphql';
import { toPayoutStatusObj } from 'pages/merchant/dashboard/payout/constants';
import { useCallback } from 'react';

import { Checkbox, CheckboxGroup } from '@limepayments/cosmic';

import { SmallCheckboxes } from './FilterDropdown';

export type PayoutStatusFilterProps = {
  value: PayoutStatus[];
  onChange: (value: PayoutStatus[]) => void;
};

export function PayoutStatusFilter({ value, onChange }: PayoutStatusFilterProps) {
  const onToggle = useCallback(
    (status: PayoutStatus) => {
      onChange(
        value.some((value) => value === status) ? [...value].filter((value) => value !== status) : [...value, status],
      );
    },
    [onChange, value],
  );

  const StatusCheckbox = useCallback(
    ({ status }: { status: PayoutStatus }) => (
      <Checkbox
        variant="body-3"
        id={status}
        label={toPayoutStatusObj(status).label}
        checked={value.some((value) => value === status)}
        onChange={() => onToggle(status)}
        testId={`payout-filter-status-check-${status}`}
      />
    ),
    [onToggle, value],
  );

  return (
    <SmallCheckboxes>
      <CheckboxGroup>
        <StatusCheckbox status={PayoutStatus.Paid} />
        <StatusCheckbox status={PayoutStatus.Pending} />
        <StatusCheckbox status={PayoutStatus.InTransit} />
        <StatusCheckbox status={PayoutStatus.Canceled} />
        <StatusCheckbox status={PayoutStatus.Failed} />
        <StatusCheckbox status={PayoutStatus.Reversed} />
        <StatusCheckbox status={PayoutStatus.Suspended} />
      </CheckboxGroup>
    </SmallCheckboxes>
  );
}
