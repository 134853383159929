import * as s from 'assets/styles/Platforms.styles';
import { ErrorMessage, Formik, FormikHelpers, FormikProps } from 'formik';
import { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { Button, Heading, Icon, Message, Modal, Switch, Text, TextField } from '@limepayments/cosmic';

import { NotificationEmailsModalForm } from './types';
import { validationSchema } from './validation.schema';

interface Props {
  heading: string;
  title?: string;
  description?: string;
  notificationEmailsData: NotificationEmailsModalForm;
  errorMessage: string;
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  submitHandler: (
    values: NotificationEmailsModalForm,
    formObj: FormikHelpers<NotificationEmailsModalForm>,
  ) => Promise<void>;
}

function NotificationEmailModal({
  heading,
  title,
  description,
  notificationEmailsData,
  errorMessage,
  isModalOpen,
  setIsModalOpen,
  submitHandler,
}: Props) {
  const errorContainerRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<FormikProps<NotificationEmailsModalForm>>(null);
  const [initialValues, setInitialValues] = useState<NotificationEmailsModalForm>(notificationEmailsData);

  useEffect(() => {
    if (errorMessage.length > 0 && errorContainerRef && errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView();
    }
  }, [errorMessage]);

  useLayoutEffect(() => {
    if (isModalOpen) {
      setInitialValues(notificationEmailsData);
    }
  }, [isModalOpen, notificationEmailsData]);

  return (
    <Modal isOpen={isModalOpen} className="modal-medium update-pass-modal" testId="notificationemails-update-modal">
      <s.Wrapper className="">
        <div className="modal-main p-24">
          <div className="modal-header">
            <div className="content-main bb-1">
              <Heading alignment="center" tagName="h2" variant="xs" className="mt-12 mb-16 lp-justify-center lp-flex">
                {heading}
              </Heading>
            </div>
          </div>
          <Formik<NotificationEmailsModalForm>
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={submitHandler}
            validationSchema={validationSchema}
            innerRef={formRef}
          >
            {({ values, handleSubmit, errors, touched, setFieldValue, handleChange, handleBlur, isSubmitting }) => (
              <form autoComplete="off" onSubmit={handleSubmit} className="content-wrap-form">
                <div className="modal-content p-28 sm-p-0 sm-mt-24">
                  {errorMessage.length > 0 && (
                    <div className="mt-24 mb-24" ref={errorContainerRef}>
                      <Message
                        type="inline"
                        children={<span className="text-wrap">{errorMessage}</span>}
                        variant={'error'}
                      />
                    </div>
                  )}

                  <div className="lp-w-full mb-8">
                    <div>
                      <Text variant="body-2" className="text-dark-50 mb-0" isEmphasised>
                        {title || ''}
                      </Text>
                      {description && (
                        <Text variant="caption" className="text-dark-50 mt-4">
                          {description}
                        </Text>
                      )}
                    </div>
                  </div>
                  <div className="lp-w-full w-200 mb-24">
                    <div className={`lp-flex payment-switch ${errors.enabled && touched.enabled ? ` has-error` : ``}`}>
                      <Switch
                        className="text-neutral-900"
                        id="enabled"
                        label={values.enabled ? `Enabled` : `Disabled`}
                        size="small"
                        checked={values.enabled}
                        onChange={(value) => {
                          setFieldValue('enabled', value);
                          if (!value) setFieldValue('enteredEmail', '');
                        }}
                        testId="notificationemails-update-modal-switch"
                      />
                      <ErrorMessage name="notificationEmailsEnabled" component="span" className="Mui-error" />
                    </div>
                  </div>
                  <div className="lp-w-full mb-24">
                    {!values.enabled && (
                      <div>
                        <Text variant="body-2" className="text-dark-50 mb-0" isEmphasised>
                          Recipients
                        </Text>
                        <div data-testid="notificationemails-update-modal-emails">
                          {values.emailList
                            ? values.emailList.map((email, key) => (
                                <Text key={key} variant="body-3" className="text-dark-25 m-0">
                                  {email}
                                </Text>
                              ))
                            : null}
                        </div>
                      </div>
                    )}

                    {values.enabled && (
                      <Fragment>
                        <div
                          className="lp-flex lp-tags lp-flex-wrap"
                          data-testid="notificationemails-update-modal-emails"
                        >
                          {values.emailList
                            ? values.emailList.map((email, key) => (
                                <div className="lp-flex lp-align-center tag" key={key}>
                                  <Text variant="body-3" isEmphasised className="text-dark mt-0">
                                    {email}
                                  </Text>
                                  <button
                                    type="button"
                                    className="lp-flex lp-align-center lp-justify-center tag-remove"
                                    onClick={() => {
                                      const emailArray = values.emailList
                                        ? values.emailList.filter((obj) => {
                                            return obj !== email;
                                          })
                                        : [];

                                      setFieldValue('emailList', emailArray);
                                    }}
                                  >
                                    <Icon name="Cross" />
                                  </button>
                                </div>
                              ))
                            : null}
                        </div>
                        <ErrorMessage name="notificationEmails" component="span" className="Mui-error" />
                      </Fragment>
                    )}
                  </div>

                  {values.enabled && (
                    <div className="lp-w-full lp-flex lp-align-end lp-justify-between mb-16">
                      <div className="lp-w-full w-200">
                        <TextField
                          fullWidth
                          label="Email"
                          containerId="standard"
                          inputMode="email"
                          type="email"
                          value={values.enteredEmail}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id="enteredEmail"
                          name="enteredEmail"
                          error={errors.enteredEmail && touched.enteredEmail ? errors.enteredEmail : ``}
                          testId="notificationemails-update-modal-enteremail-input"
                        />
                      </div>
                      <div className="lp-w-120 btm-4px ml-auto">
                        <Button
                          type="button"
                          onClick={() => {
                            if (values.enteredEmail) {
                              const notificationEmails = values.emailList ? values.emailList : [];
                              notificationEmails.push(values.enteredEmail);
                              setFieldValue('notificationEmails', notificationEmails);
                              setFieldValue('email', '');
                            }
                          }}
                          size="medium"
                          variant="reversed"
                          className="no-min-width lp-w-full"
                          disabled={
                            !values.enteredEmail || (errors.enteredEmail && touched.enteredEmail) ? true : false
                          }
                          testId="notificationemails-update-modal-addemail-button"
                        >
                          <Icon name="Plus" />
                          Add
                        </Button>
                      </div>
                    </div>
                  )}
                </div>

                <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
                  <Button
                    size="medium"
                    variant="ghost"
                    className="no-min-width w-70"
                    type="button"
                    onClick={() => setIsModalOpen(!isModalOpen)}
                    disabled={isSubmitting}
                    testId="notificationemails-update-modal-cancel-input"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="medium"
                    variant="primary"
                    className="no-min-width"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    testId="notificationemails-update-modal-submit-button"
                  >
                    Update
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </s.Wrapper>
    </Modal>
  );
}

export default NotificationEmailModal;
