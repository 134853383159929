import { ServiceProviderMerchant, StandardMerchant } from './merchants.types';

export const getStandardMerchantInitValues = (): StandardMerchant => ({
  type: 'StandardMerchant',
  merchantId: '',
  businessProfile: {
    timeZone: '',
    businessName: '',
    businessType: 'company',
    companyType: 'Private',
    website: '',
    supportSite: '',
    industry: '',
    taxId: '',
    taxCountry: 'AU',
    contactPerson: {
      title: '',
      givenName: '',
      familyName: '',
    },
    contactEmailAddress: '',
    supportEmailAddress: '',
    phoneNumber: '',
    address: {
      unitNumber: '',
      streetNumber: '',
      streetName: '',
      streetType: '',
      suburb: '',
      city: '',
      state: '',
      postalCode: '',
      country: 'AU',
    },
    brandName: '',
    cardStatementName: '',
    smsSenderName: '',
  },
  branding: {
    iconUri: '',
    logoUri: '',
    logoAltText: '',
    primaryColor: '',
  },
  billingDetails: {
    billingNotificationsEnabled: false,
  },
  orderCustomFields: [],
  status: 'Active',
});

export const getServiceProviderMerchantInitValues = (): ServiceProviderMerchant => ({
  type: 'ServiceProviderMerchant',
  merchantId: '',
  businessProfile: {
    timeZone: '',
    businessName: '',
    businessType: 'company',
    companyType: 'Private',
    website: '',
    supportSite: '',
    industry: '',
    taxId: '',
    taxCountry: 'AU',
    contactPerson: {
      title: '',
      givenName: '',
      familyName: '',
    },
    contactEmailAddress: '',
    supportEmailAddress: '',
    phoneNumber: '',
    address: {
      unitNumber: '',
      streetNumber: '',
      streetName: '',
      streetType: '',
      suburb: '',
      city: '',
      state: '',
      postalCode: '',
      country: 'AU',
    },
    brandName: '',
    cardStatementName: '',
    smsSenderName: '',
  },
  billingDetails: {
    billingNotificationsEnabled: false,
  },
  status: 'Active',
});
