import { OrderStatus } from 'api/graphql/generated/graphql';
import { DateTime } from 'luxon';
import { TableStyleSettings, TableThemeSettings } from 'pages/platform/constants';
import { useMemo } from 'react';
import DataTable, { SortOrder, TableColumn, createTheme } from 'react-data-table-component';
import { toCurrency } from 'utils/currency';

import { Spinner } from '@limepayments/cosmic';

import * as s from '../../../../../../assets/styles/Platforms.styles';
import { toVirtualTerminalStatusObj } from '../../constants';
import { OrdersQueryEdges } from '../types';
import ActionsPopover from './ActionsPopover';
import EmptyUnpaidRequestComponent from './EmptyUnpaidRequestComponent';
import StatusTag from './StatusTagComponent';
import UnbaleToLoadComponent from './UnbaleToLoadComponent';
import UnpaidRequestComponent from './UnpaidRequestComponent';
import { OrderActionsProps } from './order-actions/context';

// createTheme creates a new theme named solarized that overrides the build in dark theme
createTheme('polarized', TableThemeSettings, 'dark');

interface Props {
  virtualTerminalList: OrdersQueryEdges;
  fetchListLoader: boolean;
  totalRows: number;
  handlePerRowsChange: (newPerPage: number, page: number) => void;
  handlePageChange: (page: number) => void;
  limit: number;
  activePage: number;
  isFilterRequest: boolean;
  handleSort: (selectedColumn: TableColumn<OrdersQueryEdges[number]>, sortDirection: SortOrder) => void;
  isErrorRequest?: boolean;
  setCreateNewOrderModalOpen: (isOpen: boolean) => void;
  toggleFilter: () => void;
  cancelOrder: (orderId: string) => Promise<void>;
  fetchOrderProcessUrl: (orderId: string) => Promise<string>;
  sendOrderComms: OrderActionsProps['sendOrderComms'];
}

function UnpaidRequestsDataTable({
  virtualTerminalList,
  totalRows,
  limit,
  fetchListLoader,
  handlePageChange,
  handlePerRowsChange,
  activePage,
  handleSort,
  isFilterRequest,
  isErrorRequest,
  setCreateNewOrderModalOpen,
  toggleFilter,
  cancelOrder,
  fetchOrderProcessUrl,
  sendOrderComms,
}: Props) {
  const columns: TableColumn<OrdersQueryEdges[number]>[] = useMemo(
    () => [
      {
        name: '',
        cell: (row: OrdersQueryEdges[number]) => {
          return row.node.status === OrderStatus.Created ? (
            <ActionsPopover
              orderId={row.node.orderId}
              amount={row.node.amount.minorCurrencyUnits}
              currency={row.node.amount.currency}
              customerEmail={row.node.customerEmailAddress ?? undefined}
              customerPhoneNumber={row.node.customerPhoneNumber ?? undefined}
              customerName={row.node.customerName ?? undefined}
              cancelOrder={cancelOrder}
              fetchOrderProcessUrl={fetchOrderProcessUrl}
              sendOrderComms={sendOrderComms}
            />
          ) : (
            ''
          );
        },
      },
      {
        name: 'Amount',
        selector: (row: OrdersQueryEdges[number]) =>
          toCurrency(row.node.amount.minorCurrencyUnits, row.node.amount.currency),
        width: '100px',
        right: true,
      },
      {
        name: '',
        selector: (row: OrdersQueryEdges[number]) => row.node.amount.currency,
        width: '70px',
        style: {
          color: 'rgb(var(--lp-colors-neutral-400))',
        },
      },
      {
        name: 'Order ID',
        selector: (row: OrdersQueryEdges[number]) => row.node.referenceOrderId || row.node.orderId,
        minWidth: '230px',
      },
      {
        name: 'Customer',
        selector: (row: OrdersQueryEdges[number]) => row.node.customerName ?? '',
      },
      {
        name: 'Description',
        selector: (row: OrdersQueryEdges[number]) => row.node.description ?? '',
      },
      {
        name: 'Requested on',
        selector: (row: OrdersQueryEdges[number]) =>
          row.node.createdAt ? `${DateTime.fromISO(row.node.createdAt).toFormat('dd/LL/yyyy, HH:mm')}` : '',
        minWidth: '150px',
      },
      {
        name: 'Status',
        cell: (row: OrdersQueryEdges[number]) => {
          const status = toVirtualTerminalStatusObj(row.node.status);
          return (
            <StatusTag
              label={status.label}
              variant={status.varient}
              tooltipDirection="left"
              tooltipText={status.tooltipText}
            />
          );
        },
        maxWidth: '100px',
      },
      {
        name: '',
        cell: (row: OrdersQueryEdges[number]) => {
          return row.node.status === OrderStatus.Created ? (
            <ActionsPopover
              orderId={row.node.orderId}
              amount={row.node.amount.minorCurrencyUnits}
              currency={row.node.amount.currency}
              customerEmail={row.node.customerEmailAddress ?? undefined}
              customerPhoneNumber={row.node.customerPhoneNumber ?? undefined}
              customerName={row.node.customerName ?? undefined}
              cancelOrder={cancelOrder}
              fetchOrderProcessUrl={fetchOrderProcessUrl}
              sendOrderComms={sendOrderComms}
            />
          ) : (
            ''
          );
        },
      },
    ],
    [cancelOrder, fetchOrderProcessUrl, sendOrderComms],
  );

  return (
    <div data-testid="virtual-terminal-orders">
      <s.VirtualTable className="">
        <DataTable
          pagination
          responsive
          columns={columns}
          data={virtualTerminalList}
          customStyles={TableStyleSettings}
          theme="polarized"
          className=""
          progressPending={fetchListLoader}
          progressComponent={
            <div className="transaction-empty">
              <div className="transaction-empty-inner">
                <Spinner variant="simple" isVisible label="Loading..." />
              </div>
            </div>
          }
          noDataComponent={
            isErrorRequest ? (
              <UnbaleToLoadComponent />
            ) : isFilterRequest && virtualTerminalList.length < 1 ? (
              <EmptyUnpaidRequestComponent toggleFilter={toggleFilter} />
            ) : (
              <UnpaidRequestComponent setCreateNewOrderModalOpen={setCreateNewOrderModalOpen} />
            )
          }
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationPerPage={limit}
          paginationDefaultPage={activePage}
          onSort={handleSort}
          sortServer
          persistTableHead
          defaultSortFieldId={7}
          defaultSortAsc={false}
          paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
        />
      </s.VirtualTable>
    </div>
  );
}

export default UnpaidRequestsDataTable;
